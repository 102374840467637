
import { AppState } from "../types";
import "./LibraryMenu.scss";
import GoogleDriveComponent from "./GoogleDriveComponent";
import { TabList } from './sidepanel/SidePanel'
import { Radio } from "antd";
import { useState } from "react";
import { GoogleImageSearch } from "./GoogleImageSearch";
import { t } from "../i18n";

export const GoogleDrivePanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;

}> = ({
  appState,
  setAppState,
  id,
}) => {
    const [switchAction, setSwitchAction] = useState<"drive" | "search">("drive");
    return <>
      <div className="action-switch">
        <Radio.Group
          defaultValue={switchAction}
          buttonStyle="outline"
          onChange={(e) => {
            setSwitchAction(e.target.value);
          }}
        >
          <Radio.Button value="drive">{t("labels.btn_google_drive")}</Radio.Button>
          <Radio.Button value="search">{t("labels.btn_google_image")}</Radio.Button>
        </Radio.Group>
      </div>
      <div className="action-content">
        {switchAction == 'drive' ? <GoogleDriveComponent /> : <GoogleImageSearch />}
      </div>
    </>;
  };
