import { AppState } from "../types";
import {
  ImagoElement,
  ImagoTextElement,
  ImagoLinearElement,
  ImagoBindableElement,
  ImagoGenericElement,
  ImagoFreeDrawElement,
  InitializedImagoImageElement,
  ImagoImageElement,
  ImagoTextElementWithContainer,
  ImagoTextContainer,
} from "./types";

export const isGenericElement = (
  element: ImagoElement | null,
): element is ImagoGenericElement => {
  return (
    element != null &&
    (element.type === "selection" ||
      element.type === "rectangle" ||
      element.type === "diamond" ||
      element.type === "ellipse")
  );
};

export const isInitializedImageElement = (
  element: ImagoElement | null,
): element is InitializedImagoImageElement => {
  return !!element && element.type === "image" && !!element.fileId;
};

export const isImageElement = (
  element: ImagoElement | null,
): element is ImagoImageElement => {
  return !!element && element.type === "image";
};

export const isTextElement = (
  element: ImagoElement | null,
): element is ImagoTextElement => {
  return element != null && element.type === "text";
};

export const isFreeDrawElement = (
  element?: ImagoElement | null,
): element is ImagoFreeDrawElement => {
  return element != null && isFreeDrawElementType(element.type);
};

export const isFreeDrawElementType = (
  elementType: ImagoElement["type"],
): boolean => {
  return elementType === "freedraw" || elementType === "eraserbig" || elementType === "marker" || elementType === "magicpen" || elementType === "magictext";
};

export const isLinearElement = (
  element?: ImagoElement | null,
): element is ImagoLinearElement => {
  return element != null && isLinearElementType(element.type);
};

export const isLinearElementType = (
  elementType: AppState["activeTool"]["type"],
): boolean => {
  return (
    elementType === "arrow" || elementType === "line" // || elementType === "freedraw"
  );
};

export const isBindingElement = (
  element?: ImagoElement | null,
  includeLocked = true,
): element is ImagoLinearElement => {
  return (
    element != null &&
    (!element.locked || includeLocked === true) &&
    isBindingElementType(element.type)
  );
};

export const isBindingElementType = (
  elementType: AppState["activeTool"]["type"],
): boolean => {
  return elementType === "arrow";
};

export const isBindableElement = (
  element: ImagoElement | null,
  includeLocked = true,
): element is ImagoBindableElement => {
  return (
    element != null &&
    (!element.locked || includeLocked === true) &&
    (element.type === "rectangle" ||
      element.type === "diamond" ||
      element.type === "ellipse" ||
      element.type === "image" ||
      (element.type === "text" && !element.containerId))
  );
};

export const isTextBindableContainer = (
  element: ImagoElement | null,
  includeLocked = true,
): element is ImagoTextContainer => {
  return (
    element != null &&
    (!element.locked || includeLocked === true) &&
    (element.type === "rectangle" ||
      element.type === "diamond" ||
      element.type === "ellipse" ||
      element.type === "image")
  );
};

export const isImagoElement = (element: any): boolean => {
  return (
    element?.type === "text" ||
    element?.type === "diamond" ||
    element?.type === "rectangle" ||
    element?.type === "ellipse" ||
    element?.type === "arrow" ||
    element?.type === "freedraw" ||
    element?.type === "marker" ||
    element?.type === "magicpen" ||
    element?.type === "magictext" ||
    element?.type === "eraserbig" ||
    element?.type === "line"
  );
};

export const hasBoundTextElement = (
  element: ImagoElement | null,
): element is ImagoBindableElement => {
  return (
    isBindableElement(element) &&
    !!element.boundElements?.some(({ type }) => type === "text")
  );
};

export const isBoundToContainer = (
  element: ImagoElement | null,
): element is ImagoTextElementWithContainer => {
  return (
    element !== null && isTextElement(element) && element.containerId !== null
  );
};
