import { ImagoElement } from "../../element/types";
import { AppState, Page } from "../../types";
import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../appState";
import { clearElementsForLocalStorage } from "../../element";
import { STORAGE_KEYS } from "../app_constants";
import { ImportedDataState } from "../../data/types";
import { updateBrowserStateVersion } from "./tabSync";
import { LocalData } from "./LocalData";
import { LIBRARY_LIST_LIMIT } from "../../constants";
import oc from "open-color";

export const saveServerConnectionToLocalStorage = (
  serverConnection: string,
) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_SERVER_CONNECTION,
      JSON.stringify({ serverConnection }),
    );
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importServerConnectionFromLocalStorage = (): string | null => {
  try {
    const data = localStorage.getItem(
      STORAGE_KEYS.LOCAL_STORAGE_SERVER_CONNECTION,
    );
    if (data) {
      return JSON.parse(data).serverConnection;
    }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

export const saveUsernameToLocalStorage = (username: string) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_COLLAB,
      JSON.stringify({ username }),
    );
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importUsernameFromLocalStorage = (): string | null => {
  try {
    const data = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    if (data) {
      return JSON.parse(data).username;
    }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

export const importFromLocalStorage = async () => {
  let savedElements = null;
  let savedState = null;

  try {
    savedElements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    //savedElements = localStorage.getItem(getCurrPageFromStorage());
    savedState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  let elements: ImagoElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(JSON.parse(savedElements));
    } catch (error: any) {
      console.error(error);
      // Do nothing because elements array is already empty
    }
  }

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...getDefaultAppState(),
        ...clearAppStateForLocalStorage(
          JSON.parse(savedState) as Partial<AppState>,
        ),
      };
    } catch (error: any) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }
  return { elements, appState };
};



export const savePersonalDataToLocalStorage = (
  elements: readonly ImagoElement[],
) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_PERSONAL_ELEMENTS,
      JSON.stringify(elements),
    );

    // updateBrowserStateVersion(STORAGE_KEYS.VERSION_PERSONAL_DATA_STATE);
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const getPersonalDataFromLocalStorage = async () => {
  let savedElements = null;


  try {
    savedElements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_PERSONAL_ELEMENTS);
  } catch (error: any) {
    console.error(error);
  }

  let elements: ImagoElement[] = [];
  if (savedElements) {
    try {
      elements = JSON.parse(savedElements);
    } catch (error: any) {
      console.error(error);
    }
  }
  return elements;
};

export const getPageListFromStorage = (): Page[] => {
  try {
    return JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_PAGE_LIST) ||
      `["${STORAGE_KEYS.LOCAL_STORAGE_DEFAULT_PAGE}"]`,
    );
  } catch (err) {
    console.error("localStorage getContainerList error", err);
    return [{ id: STORAGE_KEYS.LOCAL_STORAGE_DEFAULT_PAGE, name: "page1", backgroundColor: oc.white, gridColor: "" }];
  }
};

// export const clearPageData = async () => {
//   const pageList = getPageListFromStorage();
//   for (let index = 0; index < pageList.length; index++) {
//     const page = pageList[index];
//     localStorage.removeItem(page.id)
//   }
// }

export const clearAllStorageData = () => {
  //clearPageData();
  // var timestamp = new Date().getTime();
  // let newPage = "page_" + timestamp
  // setCurrPageToStorage(newPage)
  // setPageListToStorage([{id:newPage,name:'page1'}])
  //localStorage.clear();
  setCurrPageToStorage("");
  setPageListToStorage([]);
  //localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,JSON.stringify([]));
};

export const setPageListToStorage = (list: Page[] = []) => {
  // clearPageData()
  localStorage.setItem(
    STORAGE_KEYS.LOCAL_STORAGE_PAGE_LIST,
    JSON.stringify(list),
  );
};

export const setCurrPageToStorage = (page: string) => {
  if (page) {
    localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_CURR_PAGE, page);
  }
};

export const getCurrPageFromStorage = () => {
  return (
    localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_CURR_PAGE) ||
    STORAGE_KEYS.LOCAL_STORAGE_DEFAULT_PAGE
  );
};

export const getPageDataByKeyFromLocalStorage = (key: string) => {
  let savedElements = null;
  try {
    savedElements = localStorage.getItem(key);
  } catch (error: any) {
    console.error(error);
  }
  let elements: ImagoElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(JSON.parse(savedElements));
    } catch (error: any) {
      console.error(error);
    }
  }
  return elements;
};

export const delDataFromStorage = (key: string) => {
  if (key) {
    localStorage.removeItem(key);
  }
};

export const setItemWithExpiration = (
  key: string,
  value: any,
  expire: number,
) => {
  const expirationMS = expire * 1000;
  const item = {
    value,
    expiration: new Date().getTime() + expirationMS,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiration = (key: string) => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  const parsedItem = JSON.parse(item);
  const now = new Date().getTime();
  if (now > parsedItem.expiration) {
    localStorage.removeItem(key);
    return null;
  }
  return parsedItem.value;
};

export const updateStateToLocalStorage = (appState: AppState) => {
  localStorage.setItem(
    STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
    JSON.stringify(clearAppStateForLocalStorage(appState)),
  );
  updateBrowserStateVersion(STORAGE_KEYS.VERSION_DATA_STATE);
};

export const setCollabtionHost = () => {
  localStorage.setItem(STORAGE_KEYS.IS_COLLAB_HOST, "1");
};

export const reSetCollabtionHost = () => {
  localStorage.removeItem(STORAGE_KEYS.IS_COLLAB_HOST);
};
export const isCollabtionHost = () => {
  return localStorage.getItem(STORAGE_KEYS.IS_COLLAB_HOST) == "1";
};

export const setCurrBoardMode = (mode: any) => {
  localStorage.setItem(STORAGE_KEYS.CURR_BOARD_MODE, mode);
};

export const getCurrBoardMode = () => {
  let mode = localStorage.getItem(STORAGE_KEYS.CURR_BOARD_MODE);
  let boardMode = 0
  if (mode) {
    boardMode = parseInt(mode)
  }
  return boardMode
};


export const setDrawAuth = (status: any) => {
  localStorage.setItem(STORAGE_KEYS.HAS_DRAW_AUTH, status);
};

export const hasDrawAuth = () => {
  return localStorage.getItem(STORAGE_KEYS.HAS_DRAW_AUTH) == "1";
};

export const setShareIsModerator = () => {
  localStorage.setItem(STORAGE_KEYS.IS_SHARE_MODERATOR, "1");
};

export const clearShareIsModerator = () => {
  localStorage.removeItem(STORAGE_KEYS.IS_SHARE_MODERATOR);
};
export const checkIsShareModerator = () => {
  return localStorage.getItem(STORAGE_KEYS.IS_SHARE_MODERATOR) == "1";
};
