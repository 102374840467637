import "./Pagination.scss";
import { Button, Divider, List, Select, SelectProps, Space } from "antd";
import React, { useEffect, useState } from "react";
import { getCurrPageFromStorage, getPageListFromStorage, isCollabtionHost } from "../imago-app/data/localStorage";
import { PageManager } from "../imago-app/data/PageManager";
import { AppState, Page } from "../types";
import { ImagoElement } from "../element/types";
import { ActionManager } from "../actions/manager";
import { actionChangePage } from "../actions/actionChangePage";
import { collabAPIAtom, isCollaboratingAtom, syncActionNameFlagAtom, syncFlagAtom, toPageAtom } from "../imago-app/collab/Collab";
import { useAtom } from "jotai";
import { BaseOptionType } from "antd/es/select";
import { PencilIcon, PlusIcon, ResetIcon } from "./icons";
import { t } from "../i18n";
import { replace } from "lodash";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { withBatchedUpdatesThrottled } from "../utils";
import { IconCheck, IconPage, IconPlus } from "./newIcons";


export const Pagination: React.FC<{
  appState: AppState,
  actionManager: ActionManager,
  setAppState: React.Component<any, AppState>["setState"],
}> = ({ appState, actionManager, setAppState }) => {
  const pageList = getPageListFromStorage();
  const [toPage,] = useAtom(toPageAtom);
  const [syncFlag,] = useAtom(syncFlagAtom);
  const [syncActionNameFlag,] = useAtom(syncActionNameFlagAtom);
  const [isCollaborating,] = useAtom(isCollaboratingAtom);
  const [collabAPI] = useAtom(collabAPIAtom);
  const [options, setOptions] = useState(pageListToOption(pageList));
  let currentPage = getCurrPageFromStorage();
  const [seleted, setSelected] = useState(options.filter(o => o.value == currentPage)[0] || options[0]);
  const [showPop, setShowPop] = useState(false);
  // const setCurrentPage = (pageId:string)=>{
  //   setAppState({currentPage:pageId})
  // }

  const isHost = isCollabtionHost()

  useEffect(() => {
    const newsetOptions = pageListToOption(getPageListFromStorage());
    currentPage = getCurrPageFromStorage();
    setOptions(newsetOptions);
    setSelected(newsetOptions.filter(o => o.value == currentPage)[0] || options[0]);

    // const page = pageManager.getPage(currentPage);
    // if(!page)return;
    // setAppState({
    //   viewBackgroundColor:page.backgroundColor
    // })

  }, [isCollaborating, appState]);

  useEffect(() => {
    collabAPI && collabAPI.syncChangeFinished({ toPage })
    if (syncFlag) {

    }

  }, [syncFlag]);


  useEffect(() => {
    if (!toPage) return;
    switch (syncActionNameFlag) {
      case "add":
        onAdd(toPage);
        break;
      case "change":
        const p = options.filter(o => o.value == toPage)[0];
        p && onChange(p);
        break;
      case "remove":
        onRemove(toPage);
        break;
    }

  }, [toPage, syncActionNameFlag])

  // useEffect(() => {        
  //   if(isCollaborating){
  //       const currentPage = getCurrPageFromStorage();
  //       setCurrentPage(currentPage);
  //       setSelected(options.filter(o=>o.value==currentPage)[0]);
  //       setOptions(pageListToOption(getPageListFromStorage())); 
  //   } else{

  //   }
  // }, [isCollaborating]);

  function pageListToOption(pageList: Page[]) {
    return pageList?.map((item, i) => ({ label: item.name, value: item.id }))
  }
  const onAdd = (id?: string) => {
    const page = PageManager.addPage({ id })
    setOptions([...options, { label: page.name, value: page.id }]);
    //setCurrentPage(page.id);
    setSelected({ label: page.name, value: page.id });
    actionManager.executeAction(actionChangePage);
    return page;
  };

  const onChange = async (item: { label: string, value: string }) => {
    // const page = await PageManager.getPage(item.value);
    // if(!page)return;
    //setShowPop(false);
    PageManager.changePage({ page: item.value });

    // setAppState({
    //   viewBackgroundColor:page.backgroundColor
    // })

    //setCurrentPage(item.value);
    setSelected(item);
    actionManager.executeAction(actionChangePage);

  }
  const handlePageChange = (item: { label: string, value: string }) => {
    onChange(item);
    if (collabAPI?.isCollaborating()) {
      collabAPI.syncChangePage({ toPage: item.value, actionNameFlag: "change", pageName: item.label })
    }
  }
  const handleAddPage = (e: any) => {
    e.preventDefault();
    if ((pageList?.length ?? 0) >= (appState.userLicence?.numberOfEditedPages ?? 5)) {
      alert(t("alerts.shouldGrade"));
      return "";
    }
    const newPage = onAdd();
    if (collabAPI?.isCollaborating()) {
      collabAPI.syncChangePage({ toPage: newPage.id, actionNameFlag: "add", pageName: newPage.name })
    }
  }
  const onRemove = (value: string) => {
    if (options.length == 1) return;
    setOptions(options.filter(s => s.value != value));
    PageManager.removePage({ page: value }).then(() => {
      onChange(options.filter(s => s.value != value)[0]);
    })

  }
  const handleRemove = (item: { label: string, value: string }) => {
    onRemove(item.value);
    if (collabAPI?.isCollaborating()) {
      collabAPI.syncChangePage({ toPage: item.value, actionNameFlag: "remove", pageName: item.label })
    }
  }

  const ChangePageWidget = ({ item }: { item: any }) => {
    return (<a href={undefined} onClick={() => { handlePageChange(item) }}>{item.label}</a>)
  };

  const RemovePageWidget = ({ item }: { item: any }) => {
    return (<a href={undefined} onClick={() => { handleRemove(item) }}>{ResetIcon}</a>)
  };

  const AddPageWidget = () => {
    return (<button className="add-page" onClick={handleAddPage}>{IconPlus}</button>)
  };

  return <div className="pagination">
    {/* <Select
        style={{ width: 110,marginTop:"7px" }}
        placeholder="pagination"
        defaultValue={selected}
        onChange={onChange}
        value={selected}
        labelRender={labelRender}
        optionRender={(option: any, info: { index: number })=>{
          //&nbsp;<a onClick={()=>setOptions(options.filter((i)=>i.value!=option.value))}>x</a>
            return <>{option.label}</>
        }}
        dropdownRender={(menu) => (
        <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '4px'}}>
            <Button type="text" onClick={onAdd}>
               Add page
            </Button>
            </Space>
        </>
        )}
        options={options}
    /> */}

    <a className="page-title" onClick={() => { setShowPop(!showPop) }}>{IconPage}</a>
    {showPop && <Popover bottom={40} left={0} onCloseRequest={() => setShowPop(false)}>
      <Island style={{ padding: 0, "overflow": "hidden" }}>

        <div className="pagination-header">
          Pages

          {isCollaborating ? (<>{isHost && ((<AddPageWidget />))}</>) : (<><AddPageWidget /></>)}
        </div>
        <div className="pagination-list">
          <List dataSource={options}
            size="small"
            split={false}
            renderItem={(item) => (
              <List.Item >
                <div className="list-item">
                  <div className="list-item-title">
                    <span>{currentPage == item.value && IconCheck}</span>


                    {isCollaborating ? (<>{isHost ? ((<ChangePageWidget item={item} />)) : (<>{item.label}11</>)}</>) : (<ChangePageWidget item={item} />)}
                  </div>
                  <div className="list-item-actions">
                    {/* <a href={undefined} >{ PencilIcon}</a> */}

                    {isCollaborating ? (<>{isHost && ((<RemovePageWidget item={item} />))}</>) : (<><ChangePageWidget item={item} /></>)}


                  </div>
                </div>
              </List.Item>
            )}
          >

          </List>


        </div>
      </Island>
    </Popover>}
  </div>;
}