import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import "./TemplateSelector.scss";
import { GetLoginedUser } from "../utils";
import { Card, List } from "antd";
import Meta from "antd/es/card/Meta";
import { checkLangKey, t } from "../i18n";

export const getTemplateName = (item: any): string => {
  const arr = item.nameKey.split("_");
  if (arr.length == 2) {
    const key = arr[0].concat("_");
    const temp_key = `data_record.${key}`;
    if (checkLangKey(temp_key)) {
      const v = t(temp_key);
      if (v != "") {
        return `${v} ${arr[1]}`;
      }
      return item.name;
    }
    return item.name;
  }
  return item.name;
};

export const TemplatesImage: React.FC<{
  keywords: string;
  listColumn?: number;
  listLimit?: number;
  handleCardClick: (item: any) => void;
  showMenu?: boolean;
}> = ({
  keywords,
  listColumn = 4,
  listLimit = 8,
  handleCardClick,
  showMenu = true,
}) => {
  const [title, setTitle] = useState("");
  const [templatesTotal, setTemplateTotal] = useState(0);
  const [templates, setTemplates] = useState<any[]>();
  const [page, setPage] = useState(1);
  const [loop, setLoop] = useState(0);

  const [categories, setCategories] = useState<any[]>();
  const host = "";
  const user = GetLoginedUser();

  const { state } = useLocation();
  const { categoryIdData, categoryNameData } = state;
  const [categoryId, setCategoryId] = useState(categoryIdData);

  const getTempList = async () => {
    try {
      const response = fetch(
        `${host}/api/v1/categories/${categoryId}/templates?page=${page}&limit=${listLimit}&key=${keywords}`,
        {
          headers: {
            Authorization: `Bearer ${user?.authorization}`,
          },
        },
      ).then(async (response) => {
        const templates = (await response.json()).data.templates;
        setTemplates(templates);
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    setCategoryId(categoryIdData);
    getTempList();
  }, [page, categoryId, keywords, listLimit]);

  useEffect(() => {
    getTempList();
  }, [page, categoryId, keywords, listLimit]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${host}/api/v1/stats/categories/${categoryId}/templates`,
        {
          headers: {
            Authorization: `Bearer ${user?.authorization}`,
          },
        },
      );
      setTemplateTotal((await response.json()).data);
    })();
  }, [categoryId, keywords]);

  return (
    <>
      {showMenu && (
        <div className="menu">
          <ul>
            <li>
              <NavLink to="/dashboard">{t("home_welcome.dashboard")}</NavLink>
            </li>

            <li>
              <NavLink to="/templates-categories">
                {t("home_welcome.categories")}
              </NavLink>
            </li>
          </ul>
        </div>
      )}

      <div className="content">
        <h2>{categoryNameData}</h2>

        <div className="list">
          <List
            grid={{ xs: 1, sm: 2, md: 2, column: listColumn }}
            pagination={
              templatesTotal > listLimit
                ? {
                    onChange: (page) => {
                      setPage(page);
                    },
                    pageSize: listLimit,
                    total: templatesTotal,
                    current: page,
                  }
                : false
            }
            dataSource={templates}
            renderItem={(item) => (
              <Card
                hoverable
                key={item.id}
                style={{ margin: "10px" }}
                cover={
                  <div className="card-cover">
                    <img
                      alt={item.name}
                      src={`/api/v1/download/template-images?path=${item.snapshortUrl}`}
                    />
                  </div>
                }
                onClick={() => handleCardClick(item)}
              >
                <Meta title={getTemplateName(item)} />
              </Card>
            )}
          />
        </div>
      </div>
    </>
  );
};
