import "./MarketPlace.scss"
import { Button, Card, Flex } from "antd"
import Meta from "antd/es/card/Meta"
import { IconFire, IconMermaid, IconStem, IconImagoSchool } from "./newIcons"
import { AppState } from "../types";
import { t } from "../i18n";

export const MarketPlace: React.FC<{
    appState: AppState;
    setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {

    return <div className="market-place">
        <Flex justify="center">
            <Button style={{ background: "#36B37E" }}>{IconFire}&nbsp;&nbsp;{t("labels.featured")}</Button>
        </Flex>
        <Flex justify="center" gap="20px">
            <Card
                hoverable
                classNames={{ body: "card" }}
                onClick={() => {
                    setAppState({ openDialog: "mermaid", openSidebar: null, lastOpenSidebar: "marketPlace" })  
                }}
            >
                {IconMermaid}
                <Meta title={t("labels.mermaid_chart")} />
            </Card>

            <Card
                hoverable
                classNames={{ body: "card" }}
                onClick={() => {
                    setAppState({ openDialog: "stem", openSidebar: null, lastOpenSidebar: "marketPlace" })
                }}
            >
                {IconStem}
                <Meta title={t("labels.stem")} />
                {/* <Meta
                    title={<div style={{ textAlign: 'center', whiteSpace: `normal`, overflow: 'visible', textOverflow: 'clip' }}>{t("labels.stem")}</div>}
                /> */}
            </Card>

            <Card
                hoverable
                classNames={{ body: "card" }}
                onClick={() => {
                    setAppState({ openDialog: "school", openSidebar: null, lastOpenSidebar: "marketPlace" })
                }}
            >
                {IconImagoSchool}
                <Meta title={t("labels.imago_school")} />
            </Card>
        </Flex>
    </div>
}