import { AppState } from "../types";

export const showBrushIcon = (x: any, y: any) => {
    const brushIcon = document.getElementById("brushIcon");
    if (brushIcon) {
        brushIcon.style.display = "block";
        brushIcon.style.left = x - brushIcon.clientWidth + 5 + "px";
        brushIcon.style.top = y - brushIcon.clientHeight + "px";
    }

};


export const hideBrushIcon = () => {
    const brushIcon = document.getElementById("brushIcon");
    if (brushIcon) {
        brushIcon.style.display = "none";
    }

}

export const moveBrushIcon = (x: any, y: any) => {
    const brushIcon = document.getElementById("brushIcon");
    if (brushIcon) {
        brushIcon.style.display = "block";
        brushIcon.style.left = x - brushIcon.clientWidth + 5 + "px";
        brushIcon.style.top = y - brushIcon.clientHeight + "px";
    }

}

export const startBrushing = (canvas: any, event: any, appState: AppState) => {
    const brushIcon = document.getElementById("brushIcon");
    if (brushIcon) {

        const x = event.clientX;
        const y = event.clientY;
        showBrushIcon(x, y);
    }
}



export const stopBrushing = (canvas: any, setAppState: React.Component<any, AppState>["setState"],
    appState: AppState) => {
    const brushIcon = document.getElementById("brushIcon");
    if (brushIcon) {
        hideBrushIcon();
    }
}

