import React from "react";
import { ActionManager } from "../actions/manager";
import { ImagoElement } from "../element/types";
import { AppState } from "../types";
import { Popover } from "./Popover";
import { MutiObjectsIcon } from "./icons";
import { getLanguage } from "../i18n";
import { Popover as AntdPopover} from "antd";

export const MultiObjectsAction = ({
    elements,
    appState,
    renderAction,
    setAppState
  }: {
    elements: readonly ImagoElement[];
    appState: AppState;
    renderAction: ActionManager["renderAction"];
    setAppState: React.Component<any, AppState>["setState"];
  }) => {
    const label = ""
    const pickerButton = React.useRef<HTMLButtonElement>(null);
    const coords = pickerButton.current?.getBoundingClientRect(); 
    const [isActive, setActive] = React.useState(false);
    
    const isRTL = getLanguage().rtl;
    const content =  <div
    className="picker"
    role="dialog"
    aria-modal="true"
    aria-label={label} 
    >
    <div className="picker-content" >
        {renderAction("group")}
        {renderAction("ungroup")}
        {renderAction("alignLeft")}
        {renderAction("alignRight")}
        {renderAction("alignTop")}
        {renderAction("alignBottom")}
        {renderAction("alignVerticallyCentered")}
        {renderAction("alignHorizontallyCentered")}
        
    </div>
  </div>;
    return (
      <div className="iconSelectList buttonList">
        <AntdPopover content={content} trigger={"click"} placement="bottomLeft" arrow={false}  onOpenChange={setActive} getPopupContainer={(triggerNode)=>triggerNode}>        
            <button
              className={isActive ? "active" : ""}
              aria-label={label}            
              onClick={() => setActive(!isActive)}
              ref={pickerButton}
            >
            {MutiObjectsIcon}
            </button>
        </AntdPopover>
        {/* <React.Suspense fallback="">
          {isActive ? (<>
              <Popover
                onCloseRequest={(event) =>
                  event.target !== pickerButton.current && setActive(false)
                }
              > 
              <div
                className={`picker`}
                role="dialog"
                aria-modal="true"
                aria-label={label} 
                >
                <div className="picker-content" >
                    {renderAction("group")}
                    {renderAction("ungroup")}
                    {renderAction("alignLeft")}
                    {renderAction("alignRight")}
                    {renderAction("alignTop")}
                    {renderAction("alignBottom")}
                    {renderAction("alignVerticallyCentered")}
                    {renderAction("alignHorizontallyCentered")}
                    
                </div>
              </div>
              </Popover>
              <div className="picker-triangle" />
            </>
          ) : null}
        </React.Suspense> */}
      </div>
    );
  };