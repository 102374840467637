import { t } from "../i18n";
import { DriveIcon } from "./icons";

import "./CollabButton.scss";
import MenuItem from "./MenuItem";
import clsx from "clsx";
import { AppState } from "../types";


const GoogleDriveButton = ({
  onClick,
  appState,
  setAppState
}: {
  onClick: () => void;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  return (
    <>
      <MenuItem
        label={t("labels.googleDrive")}
        dataTestId="collab-button"
        icon={DriveIcon}
        onClick={() => {
          setAppState({ openSidebar: "googleDrive" });
        }}
      />
    </>
  );
};

export default GoogleDriveButton;
