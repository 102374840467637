import React, { useEffect, useState } from "react"
import "./Mermaid.scss"
import mermaid from "mermaid";
import { Button, Flex, Input } from 'antd';
import { TabList } from "./sidepanel/SidePanel";
import { AppState } from "../types";
import { SVGStringToFile } from "../data/blob";
import { IconCopy, IconPlay } from "./newIcons";
import { useDevice } from "./App";

const { TextArea } = Input;

export const Mermaid : React.FC<{ 
    appState: AppState;
    setAppState: React.Component<any, AppState>["setState"];
    onInsert:(file:File)=>void;
    }>=({  
        appState,
        setAppState,
        onInsert
    })=>{
    const [code, setCode] = useState('');
    const [svgCode,setSvgCode] = useState('');
    const device = useDevice();
    useEffect(()=>{
        mermaid.initialize({ startOnLoad: true });
    })
    return <React.Fragment>
           
            <div className="mermain-content">
           
            <div className="render">
                <div>Code
                    <TextArea rows={device.isMobile?6:17} onChange={(e)=>setCode(e.target.value)} value={code} />
                </div>

                <Flex justify="end" gap={10}>
                    
                    <Flex justify="end" gap={10}>
                        {/* <Button  type="text" id="clear" onClick={async ()=>{
                            setCode("");
                            setSvgCode("");
                            document.querySelector("#graphDiv")!.innerHTML="";
                            document.querySelector("#dgetGrap")!.innerHTML="";
                        }}>Clear</Button> */}
                        <Button  id="render"
                        icon={IconPlay}
                        onClick={async ()=>{
                            if(!code)return;

                            const grapDiv = document.querySelector("#graphDiv");
                            const dgetGrap = document.querySelector("#dgetGrap");
                            
                            if(grapDiv && dgetGrap){
                                dgetGrap.innerHTML="";
                                grapDiv.innerHTML = "";

                                const {svg,bindFunctions} =await mermaid.render('graphDiv', code, dgetGrap);
                                grapDiv.innerHTML = svg;
                                bindFunctions?.(grapDiv);
                                setSvgCode(svg); 
                            }
                           
                        }}>Run</Button>
                    </Flex>
                    
                </Flex>
            </div>
            <div className="graph">Diagram
                <div id="dgetGrap"></div>
                <div id="graphDiv" className="graphDiv"></div>
                <Flex justify="end">
                    <Button  
                    type="text" 
                    icon={IconCopy}
                    style={{background:"#FF5733",color:"#fff"}}
                    onClick={()=>{
                        const svg = document.getElementById("graphDiv")?.querySelector('svg');
                        const w = svg?.width.baseVal.value;
                        const h = svg?.height.baseVal.value;
                        svg?.setAttribute("width",`${w}px`);
                        svg?.setAttribute("height",`${h}px`);
                        if(svg){
                            onInsert(SVGStringToFile(svg?.outerHTML?.replaceAll("<br>","<br />")));
                            setAppState({openSidebar:null});
                        }
                    }}>Copy to Board</Button>
                </Flex>
            </div>
            </div>
  </React.Fragment>
}