import { SetStateAction } from "react";
import { VERSIONS } from "../constants";
import { t } from "../i18n";
import { AppState, ImagoProps } from "../types";
import { LibraryMenuHeader } from "./LibraryMenuHeaderContent";
import Library from "../data/library";
import { LibMenuIcon } from "./icons";

const LibraryMenuBrowseButton = ({
  theme,
  id,
  libraryReturnUrl,
  setAppState,
  appState,
  selectedItems,
  setSelectedItems,
  library,
  resetLibrary,
  onRemoveFromLibrary
}: {
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  theme: AppState["theme"];
  id: string;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  selectedItems?: string[];
  setSelectedItems?: React.Dispatch<SetStateAction<string[]>>
  library?: Library;
  onRemoveFromLibrary?: () => void
  resetLibrary?: () => void
}) => {

  const openLibrary = () => {
    setAppState({ openLibraryPanel: "library" });
  }
  const referrer =
    libraryReturnUrl || window.location.origin + window.location.pathname;
  return (
    <div className="side-action">
      <a
        className="action-button-select-lib action-button-left"
        onClick={openLibrary}
      >
        {t("labels.libraries")}
      </a>
      {(selectedItems && library && resetLibrary && setSelectedItems && onRemoveFromLibrary) ? (
        <div className="action-button-select-lib action-button-right">
          <LibraryMenuHeader
            appState={appState}
            setAppState={setAppState}
            selectedItems={selectedItems}
            onSelectItems={setSelectedItems}
            library={library}
            onRemoveFromLibrary={onRemoveFromLibrary}
            resetLibrary={resetLibrary}
          />
        </div>) : (
        <div className="action-button action-button-right action-button-disable"> {LibMenuIcon}</div>
      )}
    </div>
  );
};

export default LibraryMenuBrowseButton;
