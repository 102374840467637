import React, { useRef, useState } from "react";
import { AppState, StickyNote } from "../types";
import { DraggablePanel } from "./DraggablePanel";
import "./StickyNotePanel.scss";
import { IconColors, IconTrash, IconZoomIn, IconZoomOut } from "./newIcons";
import { nanoid } from "nanoid";

export const StickyNoteBackground = [
  "#FFCCE5",
  "#CCFFFF",
  "#CCFFCC",
  "#E6E6FA",
  "#FFFBCC",
  "#FFDAB9",
  "#D3D3D3",
];

 
export const StickyNotePanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  stickyNote: StickyNote;
  defaultPosition: [number, number];
  getStickyNotes: () => StickyNote[];
}> = ({
  appState,
  setAppState,
  id,
  stickyNote,
  defaultPosition,
  getStickyNotes,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const colorSelectRef = useRef<HTMLDivElement | null>(null);

    
  const getNewsStickyNote = ()=>{
    return getStickyNotes().filter(s=>s.id == stickyNote.id)?.[0]
  }

  const getPosition = () => {
    const rect = ref.current?.getBoundingClientRect();
    return [rect?.x, rect?.y];
  };

  const setShowColorPicker = () => {
    if (colorSelectRef.current) {
      if (colorSelectRef.current.style.display != "flex") {
        colorSelectRef.current.style.display = "flex";
      } else {
        colorSelectRef.current.style.display = "none";
      }
    }
  };

  const toggleExpand = (e: any) => {
    e.stopPropagation();
    const status = stickyNote.status == "expand" ? "fold" : "expand";

    setAppState({
      stickyNotes: [
        ...getStickyNotes().filter((sn) => sn.id != stickyNote.id),
        {
          ...getNewsStickyNote(),
          status,
          position: getPosition() as [number, number],
          key: nanoid(),
        },
      ],
    });
  };

  const remove = (e: any) => {
    e.stopPropagation();

    setAppState({
      stickyNotes: [...getStickyNotes().filter((sn) => sn.id != stickyNote.id)],
    });
  };

  const setStickyNote = (data: Partial<StickyNote>) => {
    const newNotes = [
      ...getStickyNotes().filter((sn) => sn.id != stickyNote.id),
      {
        ...getNewsStickyNote(),
        ...data,
        key: nanoid(),
        position: getPosition() as [number, number],
      },
    ];

     
    setAppState({
      stickyNotes: newNotes,
    });
  };

  const setStickyNoteContent = (
    content:string
  ) => {
    const newNotes = [
      ...getStickyNotes().filter((sn) => sn.id != stickyNote.id),
      {
        ...getNewsStickyNote(),
        content,
        position: getPosition() as [number, number],
      },
    ];

    setAppState({
      stickyNotes: newNotes,
    });
  };

  return (
    <DraggablePanel
      id={id}
      title=""
      width={320}
      height={stickyNote.status == "fold" ? 32 : 320}
      appState={appState}
      setAppState={setAppState}
      buttons={[]}
      resizeable={false}
      useActiveTools={false}
      radius={5}
      position={stickyNote.position || defaultPosition}
      onMove={(position) => {
        setAppState({
          stickyNotes: [
            ...appState.stickyNotes.filter((sn) => sn.id != stickyNote.id),
            { ...stickyNote, position },
          ],
        });
      }}
    >
      <div
        ref={ref}
        id={id}
        className="sticky-note imago"
        style={{ background: stickyNote.background }}
      >
        <div className="header">
          <div className="title">
            {stickyNote.status == "fold" ? (
                  <div dangerouslySetInnerHTML={{__html:stickyNote.content}}></div>
                
            ) : (
              <></>
            )}
          </div>
          <div>
            <a href={undefined} onClick={(e) => toggleExpand(e)}>
              {stickyNote.status == "fold" ? IconZoomIn : IconZoomOut}
            </a>
            <a href={undefined} onClick={(e) => remove(e)}>
              {IconTrash}
            </a>
          </div>
        </div>
        <div
          className="content"
          style={{
            fontWeight: stickyNote.fontWeigth,
            fontStyle: stickyNote.fontStyle,
            textDecoration: stickyNote.textDecoration,
          }}
        >
         
            <div
              onPointerDown={(e) => e.stopPropagation()}
              contentEditable="true"
              //suppressContentEditableWarning={true}
              onKeyUp={(e) => {
                e.stopPropagation();
                let content =e.target.innerHTML;
                setStickyNoteContent(content);
              }}
              dangerouslySetInnerHTML={{
                __html: stickyNote.content,
              }}
            >
            </div>
          
        </div>

        <div
          className="colorSelect"
          ref={colorSelectRef}
          style={{ display: "none" }}
        >
          {StickyNoteBackground.map((b, i) => {
            return (
              <a
                key={i}
                href={undefined}
                style={{ background: b }}
                onClick={() => {
                  setStickyNote({
                    background:
                      stickyNote.background != b ? b : stickyNote.background,
                  });
                }}
              ></a>
            );
          })}
        </div>
        <div className="footer">
          <a
            href={undefined}
            onClick={() => {
              setStickyNote({
                fontWeigth:
                  stickyNote.fontWeigth != "bolder" ? "bolder" : "normal",
              });
            }}
            style={{ fontWeight: "bolder" }}
          >
            B
          </a>
          <a
            href={undefined}
            onClick={() => {
              setStickyNote({
                fontStyle:
                  stickyNote.fontStyle != "italic" ? "italic" : "normal",
              });
            }}
            style={{ fontStyle: "italic" }}
          >
            I
          </a>
          <a
            href={undefined}
            onClick={() => {
              setStickyNote({
                textDecoration:
                  stickyNote.textDecoration != "underline"
                    ? "underline"
                    : "none",
              });
            }}
            style={{ textDecoration: "underline" }}
          >
            U
          </a>
          <a
            href={undefined}
            onClick={() => {
              setStickyNote({
                textDecoration:
                  stickyNote.textDecoration != "line-through"
                    ? "line-through"
                    : "none",
              });
            }}
            style={{ textDecoration: "line-through" }}
          >
            ab
          </a>
          <a
            href={undefined}
            onClick={() => {
              setShowColorPicker();
            }}
          >
            {IconColors}
          </a>
        </div>
      </div>
    </DraggablePanel>
  );
};
