import { useRef, useState } from "react";
import { AppState } from "../types";
import "./LibraryMenu.scss";
import { useAtom } from "jotai";
import { collabAPIAtom, isCollaboratingAtom } from "../imago-app/collab/Collab";
import RoomDialog from "../imago-app/collab/RoomDialog";
import {
  getCurrPageFromStorage,
  importUsernameFromLocalStorage,
  saveUsernameToLocalStorage,
  setCollabtionHost,
} from "../imago-app/data/localStorage";
import { isFlashCollabingAtom, useDevice } from "./App";
import { TabList } from "./sidepanel/SidePanel";
import { ImportedDataState } from "../data/types";
import { GetLoginedUser } from "../utils";
import { LocalData } from "../imago-app/data/LocalData";

export const CollaborationPanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  onRoomCreated: () => void;
}> = ({ appState, setAppState, id, onRoomCreated }) => {
  const [isFlashCollabing, setIsFlashCollabing] = useAtom(isFlashCollabingAtom);
  const [_, setIsCollaborating] = useAtom(isCollaboratingAtom);
  const [collabAPI] = useAtom(collabAPIAtom);
  const [username, setUsername] = useState(
    importUsernameFromLocalStorage() || "",
  );

  const device = useDevice();
  const handleUsernameChange = (newUsername: string) => {
    setUsername(newUsername);
    saveUsernameToLocalStorage(newUsername);
  };

  let activeRoomLink = "";
  if (collabAPI) {
    activeRoomLink = collabAPI.getActiveRoomLink();
  }

  return (
    <RoomDialog
      handleClose={() => { }}
      activeRoomLink={activeRoomLink}
      username={username}
      roomId={collabAPI?.getActiveRoomId()}
      onUsernameChange={handleUsernameChange}
      onRoomCreate={() => {
        collabAPI && collabAPI.startCollaboration(null);
        setCollabtionHost();
        setAppState({ showFlashCollabration: true });
        onRoomCreated && onRoomCreated();
      }}
      onRoomDestroy={() => {
        collabAPI && collabAPI.stopCollaboration();
        // setIsFlashCollabing(false);
      }}
      onTerminateSession={() => {
        collabAPI && collabAPI.terminateCollaboration();
      }}
      setErrorMessage={(errorMessage) => {
        // collabAPI && collabAPI.setState({ errorMessage });
      }}
      theme=""
      appState={appState}
      setAppState={setAppState}
      onEscalate={(code: string) => {
        collabAPI && collabAPI.syncGoogleMeet(code);
        setIsFlashCollabing(false);
      }}
      isMobile={device.isMobile}
    />
  );
};
