import React, { useState } from "react";
import "./sidepanel/SidePanel.scss";
import "./HandWriteSuggestModal.scss";
import { Modal } from "./Modal";
import { List, MenuProps } from "antd";
import { Button, Menu, Radio } from "antd";
import { AppState, BinaryFileData, BinaryFiles, ImagoProps } from "../types";
import { GoogleDrivePanel } from "./GoogleDrivePanel";
import { CollaborationPanel } from "./CollaborationPanel";
import Library from "../data/library";
import { NonDeletedImagoElement, PointerType } from "../element/types";
import { LibraryMenu } from "./LibraryMenu";
import { ImageSearch } from "./ImageSearch";
import { ImageGallery } from "./ImageGallery";
import { ScreenSharing } from "./ScreenSharing";
import { Templates } from "./TemplateSelector";
import { CloseIcon, IconCloseWin, SearchIcon } from "./icons";
import { createFile, getMimeType } from "../data/blob";
import { Mermaid } from "./Mermaid";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { IconChevronLeftCircle, IconChevronRightCircle } from "./newIcons";
import { useDevice } from "./App";
import { MarketPlace } from "./MarketPlace";
import { InsertEmbed } from "./InsertEmbed";
import { Suggestion } from "./Suggestion";
import { t } from "../i18n";


export const HandWriteSuggestModal: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  onHandwritingInsertImage: (url: string) => void;
  onHandwritingInsertText: (text: string) => void;
}> = ({
  appState,
  onHandwritingInsertImage,
  onHandwritingInsertText,
  setAppState,
}) => {


    const devicePixelRatio = window.devicePixelRatio;

    document.documentElement.style.setProperty('--device-pixel-ratio', devicePixelRatio.toString());

    const device = useDevice();
    const width = device.isMobile ? window.innerWidth / 2 : 400;


    let left = 10 / devicePixelRatio

    let top = 200 / devicePixelRatio

    const contentWidth = devicePixelRatio == 4 ? 200 : 320;
    const contentHeight = devicePixelRatio == 4 ? 260 : 390;



    return (
      <Popover left={10} top={200} onCloseRequest={() => setAppState({ openPopup: null })}>


        <div className="participants-suggestion" style={{ width: `320px`, height: `${contentHeight}px` }}>
          <div className="participants-header">
            <h4>{t("home_welcome.suggestion")}</h4>
            <a href={undefined} onClick={() => setAppState({ openPopup: null })}>{CloseIcon}</a>
          </div>
          <div className="participants-content">
            <div className="autodraw-suggerstions">
              {appState.suggestions?.type == "draw" && appState.suggestions?.data.map((s: any) => {

                return s.icons.map((ic: string) => {
                  return <div className="fingure-img" key={ic} onClick={() => {
                    onHandwritingInsertImage(ic);
                  }}>
                    <img src={ic} alt={s.name} data-isicon={true} title={s.name} />
                  </div>
                })
              })
              }

            </div>
          </div>

        </div>
      </Popover >
    );
  };
