import "./UserModalApp.scss";
import React, { useState, useLayoutEffect, useRef } from "react";
import { Modal } from "./Modal";
import {
  clearAllStorageData,
  setItemWithExpiration,
} from "../imago-app/data/localStorage";
import { BlindIcon, CloseIcon, CrossIcon, EyeIcon, GoogleIcon } from "./icons";
import "./JoinCollabRequestComfirm.scss"
import { t } from "../i18n";
import { useDevice } from "./App";

export const JoinCollabRequestComfirm: React.FC<{
  cancelWaitJoinCollab: () => void;
  allowJoinCollab: () => void;
}> = ({ cancelWaitJoinCollab, allowJoinCollab }) => {

  const device = useDevice();

  return (
    <>
      <Modal
        labelledBy=""
        maxWidth={device.isMobile ? 244 : 400}
        onCloseRequest={() => {

        }}
      >

        <div className="join-collab-confirm">
          <div className="close-win"></div>
          <div className="win-content">
            <div className="info">
              <div className="desc">{t("labels.request_join_confirm.waiting_desc")}</div>
            </div>
            <div className="button-group">
              <div onClick={() => {
                cancelWaitJoinCollab()
              }} className="button button-reject">cancel</div>
            </div>
          </div>

        </div>

      </Modal>
    </>
  );
};
