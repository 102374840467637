import { method } from "lodash";
import { GetLoginedUser } from "../utils";

const API_ENDPOINT = '/api/v1/handwriting';
const STENCILS_ENDPOINT = '/stencils.json';
export class Handwriting {
    stencils: any;

    constructor(
       
    ) { }
  
    async loadStencils () {
     await fetch(STENCILS_ENDPOINT).then(async response=>{
        this.stencils =await response.json()
      })
    }
  
    async drawSuggestions (
      shapes: Array<Array<number[]>>, drawOptions: {
      canvasWidth: number,
      canvasHeight: number
    }) {
      const userInfo = GetLoginedUser();
      let headers = new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${userInfo?.authorization}`,
      });
       
  
      return await fetch(
        API_ENDPOINT+"?app=autodraw",        
        {
          method:"POST",
            body: JSON.stringify({
                input_type: 0,
                requests: [{
                  language: 'autodraw',
                  writing_guide: {
                    "width": drawOptions.canvasWidth,
                    "height": drawOptions.canvasHeight
                  },
                  ink: shapes
                }]
              }),
            headers
        }
       
         
      ).then(async response => {
        let data =await response.json();
        let results = JSON.parse(data[1][0][3].debug_info.match(/SCORESINKS: (.*) Service_Recognize:/)[1])
          .map((result:any) => {
            return {
              name: result[0],
              icons: (this.stencils[result[0]] || []).map((collection:any) => collection.src)
            }
          });
        return results;
      });
    }
  
    async writSuggestions (
      shapes: Array<Array<number[]>>, 
      language:string,
      drawOptions: {
      canvasWidth: number,
      canvasHeight: number
    }) {
      const userInfo = GetLoginedUser();
      let headers = new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${userInfo?.authorization}`,
      });
       
  
      return await fetch(
        API_ENDPOINT+"?app=mobilesearch",        
        {
          method:"POST",
            body: JSON.stringify({
                input_type: 0,
                requests: [{
                  language,
                  writing_guide: {
                    "width": drawOptions.canvasWidth,
                    "height": drawOptions.canvasHeight
                  },
                  ink: shapes
                }]
              }),
            headers
        }
       
         
      ).then(async response => {
        let data =await response.json();
        let results = data[1][0][1]
          // .map((result:any) => {
          //   return {
          //     name: result[0],
          //     icons: (this.stencils[result[0]] || []).map((collection:any) => collection.src)
          //   }
          // });
        return results;
      });
    }
     
  }