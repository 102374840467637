import React, { useState } from "react"
import "./ChangePassword.scss"
import { GetLoginedUser } from "../utils";
import { t } from "../i18n";

export const ChangePassword: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [password, setPassword] = useState<string>();
  const [confirmpassword, setConfirmpassword] = useState<string>();
  const [errMssage, setErrMessage] = useState<string>();
  const [passwordErr, setPasswordErr] = useState<string>();
  const [confirmpasswordErr, setConfirmpasswordErr] = useState<string>();
  const userInfo = GetLoginedUser();
  async function handleSubmit(event: any): Promise<void> {

    setPasswordErr("");
    setConfirmpasswordErr("");
    setErrMessage("");


    if (!password || password && !(/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(password))) {
      setPasswordErr("At least 6 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character");
      return;
    }

    if (password != confirmpassword) {
      setConfirmpasswordErr("Confirm Password");
      return;
    }

    await fetch(`/api/v1/changepassword`, {
      method: "POST",
      body: JSON.stringify({ password }),
      headers: {
        Authorization: "Bearer " + userInfo?.authorization
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('error');
      }
    }).then((data: any) => {
      if (data && data.code == 200) {
        alert(data.msg);
        onClose();
      } else {
        setErrMessage(t("labels.change_password_failed"));
      }
    })
  }

  return <div className="change-password">
    <h2>{t("buttons.changepassword")}</h2>
    <div className="change-password-field">
      <input maxLength={20} type="password" name="password" placeholder={t("labels.login_win.password")} value={password} onChange={(e) => setPassword(e.target.value)} />
      <span>{passwordErr}</span>
    </div>
    <div className="change-password-field">
      <input maxLength={20} type="password" name="confirmpassword" placeholder={t("labels.login_win.confirm_password")} value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)} />
      <span>{confirmpasswordErr}</span>
    </div>
    {errMssage ? <div className="change-password-err">
      <span>
        {errMssage}
      </span>
    </div> : null}
    <div className="change-password-action"><button onClick={handleSubmit}>{t("buttons.submit")}</button></div>
  </div>

}