import React, { useState } from "react";
import "./sidepanel/SidePanel.scss";
import "./HandWriteLangModal.scss";
import { Modal } from "./Modal";
import { List, MenuProps } from "antd";
import { Button, Menu, Radio } from "antd";
import { AppState, BinaryFileData, BinaryFiles, ImagoProps } from "../types";
import { GoogleDrivePanel } from "./GoogleDrivePanel";
import { CollaborationPanel } from "./CollaborationPanel";
import Library from "../data/library";
import { NonDeletedImagoElement, PointerType } from "../element/types";
import { LibraryMenu } from "./LibraryMenu";
import { ImageSearch } from "./ImageSearch";
import { ImageGallery } from "./ImageGallery";
import { ScreenSharing } from "./ScreenSharing";
import { Templates } from "./TemplateSelector";
import { CloseIcon, IconCloseWin, SearchIcon } from "./icons";
import { createFile, getMimeType } from "../data/blob";
import { Mermaid } from "./Mermaid";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { IconChevronLeftCircle, IconChevronRightCircle } from "./newIcons";
import { useDevice } from "./App";
import { MarketPlace } from "./MarketPlace";
import { InsertEmbed } from "./InsertEmbed";
import { t } from "../i18n";


export const HandWriteLangeModal: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({
  appState,
  setAppState,
}) => {


    const devicePixelRatio = window.devicePixelRatio;

    document.documentElement.style.setProperty('--device-pixel-ratio', devicePixelRatio.toString());

    const device = useDevice();
    const width = device.isMobile ? window.innerWidth / 2 : 200;
    const height = device.isMobile ? window.innerHeight * 1 / 2 : window.innerHeight * 2 / 5;
    const bottom = device.isMobile ? 120 : 50


    let left = 10 / devicePixelRatio

    let top = 200 / devicePixelRatio

    const contentWidth = devicePixelRatio == 4 ? 200 : 320;
    const contentHeight = devicePixelRatio == 4 ? 260 : 390;



    const options = [
      { name: "Afrikaans", code: "af" },
      { name: "Albanian", code: "sq" },
      { name: "Basque", code: "eu" },
      { name: "Belarusian", code: "be" },
      { name: "Bulgarian", code: "bg" },
      { name: "Catalan", code: "ca" },
      { name: "Chinese (Simplified)", code: "zh_CN" },
      { name: "Chinese (Traditional)", code: "zh_TW" },
      { name: "Croatian", code: "hr" },
      { name: "Czech", code: "cs" },
      { name: "Danish", code: "da" },
      { name: "Dutch", code: "nl" },
      { name: "English", code: "en" },
      { name: "Estonian", code: "et" },
      { name: "Filipino", code: "fil" },
      { name: "Finnish", code: "fi" },
      { name: "French", code: "fr" },
      { name: "Galician", code: "gl" },
      { name: "German", code: "de" },
      { name: "Greek", code: "el" },
      { name: "Haitian", code: "ht" },
      { name: "Hindi", code: "hi" },
      { name: "Hungarian", code: "hu" },
      { name: "Icelandic", code: "is" },
      { name: "Indonesian", code: "id" },
      { name: "Irish", code: "ga" },
      { name: "Italian", code: "it" },
      { name: "Japanese", code: "ja" },
      { name: "Korean", code: "ko" },
      { name: "Latin", code: "la" },
      { name: "Latvian", code: "lv" },
      { name: "Lithuanian", code: "lt" },
      { name: "Macedonian", code: "mk" },
      { name: "Malay", code: "ms" },
      { name: "Norwegian", code: "no" },
      { name: "Polish", code: "pl" },
      { name: "Portuguese (Brazil)", code: "pt_BR" },
      { name: "Portuguese (Portugal)", code: "pt_PT" },
      { name: "Romanian", code: "ro" },
      { name: "Russian", code: "ru" },
      { name: "Serbian", code: "sr" },
      { name: "Slovak", code: "sk" },
      { name: "Slovenian", code: "sl" },
      { name: "Spanish", code: "es" },
      { name: "Swahili", code: "sw" },
      { name: "Swedish", code: "sv" },
      { name: "Thai", code: "th" },
      { name: "Turkish", code: "tr" },
      { name: "Ukranian", code: "yk" },
      { name: "Vietnamese", code: "vi" },
      { name: "Welsh", code: "cy" }
    ]



    return (
      <Popover left={10} top={200} onCloseRequest={() => setAppState({ isShowSetHandWriteLangPanel: false })}>


        <div className="participants-language" style={{ width: `320px`, height: `${contentHeight}px` }}>
          <div className="participants-header">
            <h4>{t("leftMenu.language")}</h4>
            <a href={undefined} onClick={() => setAppState({ isShowSetHandWriteLangPanel: false })}>{CloseIcon}</a>
          </div>
          <div className="participants-content">
            <List dataSource={options}
              size="small"
              split={true}
              renderItem={(item, i) => (
                <List.Item onClick={() => setAppState({ defaultHandWriteLang: item.code })} className={`list-item ${appState.defaultHandWriteLang === item.code ? "item-select" : ''}`} >
                  {i + 1}. {item.name}
                </List.Item>
              )}
            >

            </List>
          </div>


        </div>
      </Popover >
    );
  };
