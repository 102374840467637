import { throttleRAF } from "../utils";


export const showRubber = (x: any, y: any) => {
    const rubber = document.getElementById("rubber");
    if (rubber) {
        rubber.style.display = "block";
        rubber.style.left = x - rubber.clientWidth + 50 + "px";
        rubber.style.top = y - rubber.clientHeight + 40 + "px";
    }

};


export const hideRubber = () => {
    const rubber = document.getElementById("rubber");
    if (rubber) {
        rubber.style.display = "none";
    }

}

export const moveRubber = throttleRAF((x: any, y: any) => {
    const rubber = document.getElementById("rubber");
    if (rubber) {
        rubber.style.display = "block";
        rubber.style.left = x - rubber.clientWidth + 50 + "px";
        rubber.style.top = y - rubber.clientHeight + 40 + "px";
    }

})

export const startBrushing = (event: any) => {
    const rubber = document.getElementById("rubber");
    if (rubber) {

        const x = event.clientX;
        const y = event.clientY;
        showRubber(x, y);
    }
}



export const stopBrushing = () => {
    const rubber = document.getElementById("rubber");
    if (rubber) {
        hideRubber();
    }
}

