import { t } from "../../i18n";
import { AppState, Page } from "../../types";
import { delDataFromStorage, getPageListFromStorage,  setCurrPageToStorage, setPageListToStorage } from "./localStorage";
import { LocalData } from "./LocalData";
import { STORAGE_KEYS } from "../app_constants";
import Scene from "../../scene/Scene";
import { restore } from "../../data/restore";
import { clearElementsForExport } from "../../element";
import { nanoid } from "nanoid";
import oc from "open-color";

export class PageManager  
{
  static newPage({id,name,backgroundColor,gridColor}:{id?:string,name?:string,backgroundColor?:string,gridColor?:string}):Page{
    const newPage =id?id: "page_" + nanoid();
    const pageList = getPageListFromStorage();
    return {
      id:newPage,
      name: name?name:`page${pageList.length+1}`,
      backgroundColor: backgroundColor || oc.white,
      gridColor: gridColor || ""
    }

  };

    

  static addPage = ({id,backgroundColor,gridColor}:{id?:string,backgroundColor?:string,gridColor?:string})=>{
      const pageList = getPageListFromStorage();
      
      const newPage = this.newPage({id,backgroundColor,gridColor});
      setCurrPageToStorage(newPage.id);
      setPageListToStorage([...pageList,newPage]);
      return newPage;
     
  };


  static editPage = (data:Page)=>{
    const pageList = getPageListFromStorage();
    setPageListToStorage([...pageList.map(p=>{
      if(p.id ==data.id){
        return {
          id:p.id,
          name:data.name?data.name:p.name,
          backgroundColor:data.backgroundColor?data.backgroundColor:p.backgroundColor,
          gridColor:data.gridColor?data.gridColor:p.gridColor
        }
      }
      return p;
    })]);
    return data;   
};


  static changePage = async ({page}:{
    page:string})=>{ 
      setCurrPageToStorage(page);
      // const elements = await LocalData.pagesStorage.get(page);
      // savePageDataStateToLocalStorage(page, elements??[], null);

  };

  static removePage = async ({page}:{
    page:string})=>{ 
      const pageList = getPageListFromStorage();
      const updatedPageList = pageList.filter(pageItem => pageItem.id !== page); 
      setPageListToStorage(updatedPageList);
      await LocalData.pagesStorage.remove(page);
  };

  static getPage = (pageId:string)=>{
    const pageList  = getPageListFromStorage();
    return pageList.filter(p=>p.id == pageId)[0];
  }
}