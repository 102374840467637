import { alignElements, Alignment } from "../align";
import {
  AlignBottomIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignTopIcon,
  CenterHorizontallyIcon,
  CenterVerticallyIcon,
} from "../components/icons";
import { ToolButton } from "../components/ToolButton";
import { getNonDeletedElements } from "../element";
import { ImagoElement } from "../element/types";
import { t } from "../i18n";
import { KEYS } from "../keys";
import { getSelectedElements, isSomeElementSelected } from "../scene";
import { AppState } from "../types";
import { arrayToMap, getShortcutKey } from "../utils";
import { register } from "./register";
import {ColumnHeightOutlined} from "@ant-design/icons";
export const actionSetSize = register({
    name: "setSize",
    trackEvent: { category: "element" },
    perform: (elements, appState,formValue) => {
      return {
        appState:{
            ...appState,
            ...formValue
        },
        elements,
        commitToHistory: true,
      };
    },
    keyTest: (event) =>
      event[KEYS.CTRL_OR_CMD] && event.shiftKey && event.key === KEYS.ARROW_UP,
    PanelComponent: ({ elements, appState, updateData }) => (
      <ToolButton
        type="button"
        icon={<ColumnHeightOutlined />}
        onClick={() => updateData({
                openPopup:"setSize"
        })}
        title={`set size`}
        aria-label={t("labels.alignTop")}
        visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
      />
    ),
  });
  