import { debounce } from "lodash";
import { LocalData } from "../imago-app/data/LocalData";
import { getCurrPageFromStorage } from "../imago-app/data/localStorage";
import { register } from "./register";
import { PageManager } from "../imago-app/data/PageManager";

export const actionChangePage = register({
    name: "changePage",
    trackEvent: { category: "element" },
    perform: async (_elements, appState) => {
      const pageId = getCurrPageFromStorage();
      const newElements = await LocalData.pagesStorage.get(pageId) || []; 
      const page = await PageManager.getPage(pageId);
   


      return {
        appState:{
          ...appState,
          viewBackgroundColor:page.backgroundColor
        },
        elements:newElements,
        commitToHistory: false,
      };
    },
   
  });