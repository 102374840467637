import socketIoClient from "socket.io-client";
import { ImagoImperativeAPI } from "../types";
import { PureComponent } from "react";
import { customAlphabet } from "nanoid";
import { jotaiStore } from "../jotai"; 
import { t } from "../i18n";
import { atom } from "jotai";
import { SVGStringToFile, createFile, generateIdFromFile, getDataURL, getMimeType, isSupportedImageFile } from "../data/blob";
import { API_URL, MIME_TYPES } from "../constants";
import { normalizeSVG } from "../element/image";
import { message } from "antd";
import { GetLoginedUser } from "../utils";
export const isScreenSharingAtom = atom(false);
export const plazaAPIAtom = atom<PlazaAPI | null>(null);

const newNanoid = customAlphabet("1234567890", 6);

interface PlazaState {
    errorMessage: string; 

  }
interface PlazaProps {
    imagoAPI: ImagoImperativeAPI
}
type Props = PlazaProps;

type PlazaInstance = InstanceType<typeof Plaza>;
export interface PlazaAPI {  
  stopScreenSharing: PlazaInstance["stopScreenSharing"];
  startScreenSharing: PlazaInstance["startScreenSharing"];
  screenShareRespond: PlazaInstance["screenShareRespond"];   
  pinCode: string;   
  socketId: ()=>string;
}

class Plaza extends PureComponent<Props,PlazaState>{
    imagoAPI:PlazaProps["imagoAPI"];
    pinCode:string;
    socket: SocketIOClient.Socket | null = null;

    socketId:string = "";
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: "",
            

          }
        this.imagoAPI=props.imagoAPI;
        this.pinCode = newNanoid();
        
       this.init();
    }
    init(){
        this.socket = socketIoClient(process.env.REACT_APP_WS_SERVER_URL!, {
            transports: ["websocket", "polling"],
            path: "/plaza.io",
          });
          
         
          
          this.socket.on("init", () => {
            if (this.socket) {
              const {userInfo} = this.imagoAPI.getAppState();
              this.socket.emit("init", {
                userId:userInfo?.id,
                pincode: this.pinCode,
                userName: userInfo?.username,
              });
              this.socketId = this.socket.id;
            }
          });
          this.socket.on("server-send", async (msgType: string, data: any) => {
            if (this.socket) {
              switch (msgType) {
                case "share-screen":
                  const { screenSharing,userInfo } =  this.imagoAPI.getAppState();
                  this.imagoAPI.updateScene({appState:{
                    screenSharing: {
                      pinCode: data.sender,
                      isHost: false,
                      status: "confirm",
                      host: { userName: data.senderUserName, pinCode: data.sender },
                      client: {
                        userName: userInfo?.username!,
                        pinCode: this.pinCode!,
                      },
                    },
                  }});
                  // await fetch(`https://flashcollab.imago.us/${data.sender}#config.toolbarButtons=["microphone"]&userInfo.displayName="${this.state.userInfo?.username}"&config.prejoinConfig.enabled=false&config.startAudioOnly=true&config.startWithAudioMuted=true&config.startWithVideoMuted=true`);
      
                  break;
                case "share-screen-error":
                  alert(data.msg);
                  this.clearSharingState();
                  break;
                case "stop-sharing":
                   
                  this.clearSharingState();
                  break;
                case "share-screen-respond":
                  if (data.accept) {

                    jotaiStore.set(isScreenSharingAtom, true);
                    const { screenSharing } =  this.imagoAPI.getAppState();
                    
                    this.imagoAPI.updateScene({appState:{
                      screenSharing: {
                        ...screenSharing,
                        status: "sharing",
                        client: { userName: data.userName, pinCode: data.sender },
                      },
                    }});
                  } else {
                    alert(t("screenSharing.reject_alert"));
                    this.clearSharingState();
                  }
      
                  break;
                case "image-scan-uploaded":
                  message.info("new image coming")
                  this.handleScanUploadImageRecevied(data);   
                  
                  break;
              }
            }
          });
    }
    componentDidMount(){
      const api: PlazaAPI = {
        stopScreenSharing:this.stopScreenSharing,
        startScreenSharing:this.startScreenSharing,
        screenShareRespond:this.screenShareRespond,
        pinCode:this.pinCode,
        socketId:()=>this.socketId
      };
      
      jotaiStore.set(plazaAPIAtom, api);
  

    }
    componentWillUnmount(){}
    render(){
        const { imagoAPI } = this.props;
        return <></>;
    }
    private clearSharingState() {
      jotaiStore.set(isScreenSharingAtom, false);
      this.imagoAPI.updateScene({appState:{ screenSharing: null }});
    }
    stopScreenSharing = () => {
      const appState = this.imagoAPI.getAppState();
      if (jotaiStore.get(isScreenSharingAtom)) {
         
        if (appState.screenSharing?.isHost) {
          this.socket?.emit("client-send", "stop-sharing", {
            sender: this.pinCode!,
            receiver: appState.screenSharing?.client?.pinCode,
          });
        } else {
          this.socket?.emit("client-send", "stop-sharing", {
            sender: this.pinCode!,
            receiver: appState.screenSharing?.pinCode,
          });
        }
      }
      this.clearSharingState();
    }
    startScreenSharing = (sentPinCode: string)=> {
      this.socket?.emit("client-send", "share-screen", {
        sender: this.pinCode,
        receiver: sentPinCode,
      });
      //await fetch(`https://flashcollab.imago.us/${pinCode}#config.toolbarButtons=["microphone","desktop"]&userInfo.displayName="${userInfo?.username}"&config.prejoinConfig.enabled=false&config.startAudioOnly=true&config.startWithAudioMuted=true&config.startWithVideoMuted=true`);
      //setIsScreenSharing(true);

      
      const appState = this.imagoAPI.getAppState();
      
      this.imagoAPI.updateScene({appState:{
        showScreenSharing:true,
        screenSharing: {
          pinCode: this.pinCode!,
          isHost: true,
          status: "confirm",
          host: {
            userName: appState.userInfo?.username!,
            pinCode: this.pinCode!,
          },
        },
      }});
    }
  
    screenShareRespond = (accept: boolean)=> {
      const appState = this.imagoAPI.getAppState();
      this.socket?.emit("client-send", "share-screen-respond", {
        sender: this.pinCode,
        receiver: appState.screenSharing?.pinCode,
        accept,
        userName: appState.userInfo?.username,
      });
      if (!accept) {
        this.clearSharingState();
      } else {
        jotaiStore.set(isScreenSharingAtom, true);
        const { screenSharing } = appState;
        this.imagoAPI.updateScene({appState:{
          screenSharing: { ...screenSharing, status: "sharing" },
        }});
      }
    }
    private handleScanUploadImageRecevied=async (data:string)=>{
        const userInfo = GetLoginedUser();
        const blob = await fetch(`${API_URL.uploadImageDownload}?path=${data}`,{
           headers:{
            Authorization: `Bearer ${userInfo?.authorization}`,
          }
        })
        .then(async res => {       
          return await res.blob();
        });
        let imageFile = createFile(blob,getMimeType(blob),"a");
        
        if (!isSupportedImageFile(imageFile)) {
          throw new Error(t("errors.unsupportedFileType"));
        }
        const mimeType = imageFile.type;
        if (mimeType === MIME_TYPES.svg) {
          try {
            imageFile = SVGStringToFile(
              await normalizeSVG(await imageFile.text()),
              imageFile.name,
            );
          } catch (error: any) {
            console.warn(error);
            throw new Error(t("errors.svgImageInsertError"));
          }
        }
        
        const fileId = await generateIdFromFile(imageFile);
        if (!fileId) {
          console.warn(
            "Couldn't generate file id or the supplied `generateIdForFile` didn't resolve to one.",
          );
          throw new Error(t("errors.imageInsertError"));
        }
    
        const dataURL =this.imagoAPI.getFiles()[fileId]?.dataURL || (await getDataURL(imageFile));
        this.imagoAPI.addFiles([{
          mimeType,
          id: fileId,
          dataURL,
          created: Date.now(),
          lastRetrieved: Date.now(),
        }])
    };


  
}
const _Plaza: React.FC<Props> = (props) => {
     
    return <Plaza {...props}  />;
  };
  
export default _Plaza;

export type TPlazaClass = Plaza;
