import { ALLOWED_IMAGE_MIME_TYPES, MIME_TYPES } from "../constants";

const getItemWithExpiration = (key:string) => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    const parsedItem = JSON.parse(item);
    const now = new Date().getTime();
    if (now > parsedItem.expiration) {
      localStorage.removeItem(key);
      return null;
    }
    return parsedItem.value;
  }

export const downloadImage = async (fileId: string): Promise<Blob | null> =>{
    const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
    
    const token = getItemWithExpiration("google_access_token");
    
    const blob = await fetch(url,{headers:new Headers({ 'Authorization': 'Bearer ' + token })}).then(async res => {           
        return await res.blob();
    }).catch((error) => {
      console.error(error);
      return null;
    });
    return blob;
}