import React, { useState } from "react";
import { ToolButton, ToolButtonSize } from "./ToolButton";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { AppState } from "../types";
import { ViewBackgroundIcon, createStringIcon } from "./icons";
import { BACKGROUND_ICONS, THEME } from "../constants";
import { useSetAtom } from "jotai";
import { BottomPicker } from "./ColorPicker";

const DEFAULT_SIZE: ToolButtonSize = "medium";
// TODO: It might be "clever" to add option.icon to the existing component <ButtonSelect />
export const ViewBackgroundSelect = <T extends Object>({
  options,
  value,
  onChange,
  group,
  appState,
  isActive,
  setActive,
  onColorChange,
}: {
  options: {
    value: T;
    text:
    | "background"
    | "dark-mode"
    | "dot-grid"
    | "line-grid"
    | "line-dash-grid";
    icon: string;
    testId?: string;
    default: boolean;
    loadLocalLibToCanvas?: (type: any) => void;
  }[];
  value: T | null;
  onChange: (value: any) => void;
  group: string;
  appState: AppState;
  isActive: boolean;
  setActive: (active: boolean) => void;
  onColorChange: (value: any) => void;
}) => {
  const [showSolorPicker, setShowColorPicker] = useState<boolean>(false);
  const selected =
    options.find((o) => o.value === appState.viewBackgroupStyle) ||
    options.find((o) => o.default) ||
    options[0];
  const pickerButton = React.useRef<HTMLButtonElement>(null);
  const colors = [
    "#000000",
    "#FFFFFF",
    "#223A5E",
    "#EDF1FF",
    "#6B5876",
    "#EFE1CE",
    "#FFD3F8",
    "#EBF7E3",
  ];

  return (
    <div className="toolbar-button-contanier">
      <React.Suspense fallback="">
        {isActive && (
          <Popover
            onCloseRequest={(event) => {
              event.target !== pickerButton.current && setActive(false);
              setShowColorPicker(false);
            }}
            left={-4}
            bottom="2.7rem"
          >
            <Island>
              {showSolorPicker ? (
                <BottomPicker
                  colors={colors}
                  color={null}
                  onChange={(changedColor) => {
                    onColorChange(changedColor);
                  }}
                  onClose={() => {
                    setActive(false);
                    setShowColorPicker(false);
                    pickerButton.current?.focus();
                  }}
                  type={"canvasBackground"}
                />
              ) : (
                <div className="toolbar-pop-contanier">
                  {options.map((option) => (
                    <label
                      key={option.text}
                      className="ToolIcon"
                      title={option.text}
                    >
                      <input
                        type="radio"
                        name={group}
                        onClick={() => {
                          // option.loadLocalLibToCanvas && option.loadLocalLibToCanvas(option.value);
                        }}
                        onChange={() => {
                          setShowColorPicker(
                            option.value.toString() === "background",
                          );
                          onChange(option.value);
                        }}
                        checked={value === option.value}
                        data-testid={option.testId}
                        className="ToolIcon_type_radio ToolIcon_size_medium"
                        title={option.text}
                      />
                      <div className="ToolIcon__icon">
                        {BACKGROUND_ICONS[option.text]}
                      </div>
                    </label>
                  ))}
                </div>
              )}
            </Island>
          </Popover>
        )}
      </React.Suspense>
      {selected && (
        <ToolButton
          className={""}
          key=""
          type="icon"
          icon={ViewBackgroundIcon}
          name="ViewBackgroundSelect"
          id="ViewBackgroundSelect"
          //checked={appState.viewBackgroupStyle === selected.value}
          aria-label={"ViewBackgroundSelect"}
          //onChange={() => setActive(!isActive)}
          title={"ViewBackgroundSelect"}
          ref={pickerButton}
          onClick={() => {
            setActive(!isActive);
          }}
        />
      )}
    </div>
  );
};
