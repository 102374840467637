import { fileOpen, fileSave } from "./filesystem";
import { cleanAppStateForExport, clearAppStateForDatabase } from "../appState";
import {
  EXPORT_DATA_TYPES,
  EXPORT_SOURCE,
  MIME_TYPES,
  VERSIONS,
} from "../constants";
import { clearElementsForDatabase, clearElementsForExport } from "../element";
import { ImagoElement } from "../element/types";
import { AppState, BinaryFiles, LibraryItems } from "../types";
import { isImageFileHandle, loadFromBlob, loadFromBlobPage, normalizeFile } from "./blob";

import {
  ExportedDataState,
  ImportedDataState,
  ExportedLibraryData,
  ImportedLibraryData,
} from "./types";

/**
 * Strips out files which are only referenced by deleted elements
 */
const filterOutDeletedFiles = (
  elements: readonly ImagoElement[],
  files: BinaryFiles,
) => {
  const nextFiles: BinaryFiles = {};
  for (const element of elements) {
    if (
      !element.isDeleted &&
      "fileId" in element &&
      element.fileId &&
      files[element.fileId]
    ) {
      nextFiles[element.fileId] = files[element.fileId];
    }
  }
  return nextFiles;
};


export const serializeAsJSONPage = (data: any) => {
  return JSON.stringify(data, null, 2);
}

export const getExportedDataState = (
  elements: readonly ImagoElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
  type: "local" | "database",
): ExportedDataState => {
  const data: ExportedDataState = {
    type: EXPORT_DATA_TYPES.clb,
    version: VERSIONS.imago,
    source: EXPORT_SOURCE,
    elements:
      type === "local"
        ? clearElementsForExport(elements)
        : clearElementsForDatabase(elements),
    appState:
      type === "local"
        ? cleanAppStateForExport(appState)
        : clearAppStateForDatabase(appState),
    files:
      type === "local"
        ? filterOutDeletedFiles(elements, files)
        : // will be stripped from JSON
        undefined,
  };
  return data;
};


export const serializeAsJSON = (
  elements: readonly ImagoElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
  type: "local" | "database",
): string => {
  const data: ExportedDataState = {
    type: EXPORT_DATA_TYPES.clb,
    version: VERSIONS.imago,
    source: EXPORT_SOURCE,
    elements:
      type === "local"
        ? clearElementsForExport(elements)
        : clearElementsForDatabase(elements),
    appState:
      type === "local"
        ? cleanAppStateForExport(appState)
        : clearAppStateForDatabase(appState),
    files:
      type === "local"
        ? filterOutDeletedFiles(elements, files)
        : // will be stripped from JSON
        undefined,
  };

  return JSON.stringify(data, null, 2);
};


const sendFileToReactNative = (
  blob: Blob,
  options: Object,
) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    if (window.ReactNativeWebView) {
      const base64data = reader.result;
      const message = JSON.stringify({ options, fileContent: base64data });
      window.ReactNativeWebView.postMessage(message);
    }
  };
};

export const saveAsJSON = async (
  elements: readonly ImagoElement[],
  appState: AppState,
  files: BinaryFiles,
) => {
  const serialized = serializeAsJSON(elements, appState, files, "local");
  const blob = new Blob([serialized], {
    type: MIME_TYPES.clb,
  });

  const options = {
    name: appState.name,
    extension: "clb",
    description: "clb file",
    fileHandle: isImageFileHandle(appState.fileHandle)
      ? null
      : appState.fileHandle,
  }

  let fileHandle = null

  if (window.electron) {
    window.electron.saveFile(serialized, options);
  } else {
    fileHandle = await fileSave(blob, {
      name: appState.name,
      extension: "clb",
      description: "clb file",
      fileHandle: isImageFileHandle(appState.fileHandle)
        ? null
        : appState.fileHandle,
    });
    if (window.ReactNativeWebView) {
      sendFileToReactNative(blob, options);
    }
  }
  return { fileHandle };

};


export const loadFromJSON = async (
  localAppState: AppState,
  localElements: readonly ImagoElement[] | null,
) => {
  const file = await fileOpen({
    description: "clb files",
    // ToDo: Be over-permissive until https://bugs.webkit.org/show_bug.cgi?id=34442
    // gets resolved. Else, iOS users cannot open `.imago` files.
    // extensions: ["json", "imago", "png", "svg"],
  });
  return loadFromBlob(
    await normalizeFile(file),
    localAppState,
    localElements,
    file.handle,
  );
};


export const loadFromJSONPage = async (
  localAppState: AppState,
  operaPage?: ({ page, actionName }: { actionName?: string, page: string }) => void
) => {

  const file = await fileOpen({
    description: "clb files",
    // ToDo: Be over-permissive until https://bugs.webkit.org/show_bug.cgi?id=34442
    // gets resolved. Else, iOS users cannot open `.imago` files.
    // extensions: ["json", "imago", "png", "svg"],
  });

  return loadFromBlobPage(
    await normalizeFile(file),
    localAppState,
  );
};


export const isValidImagoDataArr = (data?: {
  type?: any;
  elements?: any;
  appState?: any;
}): data is ImportedDataState => {
  return (
    data?.type === EXPORT_DATA_TYPES.clb &&
    (!data.elements ||
      (Array.isArray(data.elements) &&
        (!data.appState || typeof data.appState === "object")))
  );
};

export const isValidImagoData = (data?: {
  type?: any;
  elements?: any;
  appState?: any;
}): data is ImportedDataState => {
   
  return (
    
    data?.type === EXPORT_DATA_TYPES.clb &&
    (!data.elements ||
      (Array.isArray(data.elements) &&
        (!data.appState || typeof data.appState === "object")))
  );
};

export const isValidLibrary = (json: any): json is ImportedLibraryData => {
  return (
    typeof json === "object" &&
    json &&
    json.type === EXPORT_DATA_TYPES.imagoLibrary &&
    (json.version === 1 || json.version === 2)
  );
};

export const serializeLibraryAsJSON = (libraryItems: LibraryItems) => {
  const data: ExportedLibraryData = {
    type: EXPORT_DATA_TYPES.imagoLibrary,
    version: VERSIONS.imagoLibrary,
    source: EXPORT_SOURCE,
    libraryItems,
  };
  return JSON.stringify(data, null, 2);
};

export const saveLibraryAsJSON = async (libraryItems: LibraryItems) => {
  const serialized = serializeLibraryAsJSON(libraryItems);
  await fileSave(
    new Blob([serialized], {
      type: MIME_TYPES.imagolib,
    }),
    {
      name: "library",
      extension: "imagolib",
      description: "Imago library file",
    },
  );
};
