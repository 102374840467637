import { AppState } from "../types";
import { NonDeletedImagoElement } from "./types";
import { getSelectedElements } from "../scene";

export const showSelectedShapeActions = (
  appState: AppState,
  elements: readonly NonDeletedImagoElement[],
) =>
  Boolean(
    // (!appState.viewModeEnabled &&
    //   appState.activeTool.type !== "custom" &&
    //   (appState.editingElement ||
    //     (appState.activeTool.type !== "selection" &&
    //     appState.activeTool.type !== "eraser" &&
    //     appState.activeTool.type !== "rubber"))) ||
    getSelectedElements(elements, appState).length,
  );
