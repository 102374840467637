
import React, { useState, useEffect } from 'react';
import GoogleDriveImage from '../assets/images/google-drive.png';
import GoogleDriveFileList from './ListDocuments/GoogleDriveFileList';
import {
  setItemWithExpiration,
  getItemWithExpiration
} from "../imago-app/data/localStorage";

import { handleGoogleLogin, CLIENT_ID, API_KEY, SCOPES, DISCOVERY_DOCS, getRootFolder } from './GoogleDriveApi'
import "./GoogleDrivePanel.scss";
import { googleSignRedirect } from "../utils";
import { languages, t } from "../i18n";
import { isGoogleMeetAddonAtom } from "./App";
import { useAtom } from "jotai";

const GoogleDriveComponent = ({ callSource = '' }) => {
  const [listDocumentsVisible, setListDocumentsVisibility] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
  const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState(false);
  const [signedInUser, setSignedInUser] = useState();
  const [googleEmail, setGoogleEmail] = useState("")
  const [isGoogleMeetAddon, _] = useAtom(isGoogleMeetAddonAtom);

  //const [nextPageToken,setNextPageToken] = useState("");
  let prevPageToken = "", nextPageToken = "";


  const getDocumens = async (keywords, next) => {
    const token = getItemWithExpiration("google_access_token")
    if (token) {
      try {
        // const rootFolderId = await getRootFolder("imago-collab")
        let url = `https://www.googleapis.com/drive/v3/files?fields=nextPageToken,files(*)&key=${API_KEY}&pageSize=10`

        if (next != undefined) {
          url += `&pageToken=${next ? nextPageToken : prevPageToken}`;
        }
        if (keywords) {
          //'${rootFolderId}' in parents and trashed=false and mimeType!='application/vnd.google-apps.folder' and  
          //or fullText contains '${keywords}'
          url += `&q=trashed=false and mimeType!='application/vnd.google-apps.folder' and name contains '${keywords}' `;
        } else {
          url += `&q=trashed=false+and+mimeType!='application/vnd.google-apps.folder'`;
        }



        //`?pageToken=${next?nextPageToken:prevPageToken}&pageSize=10&q=${keywords || "%20trashed%3Dfalse"}`
        let res = await fetch(url, {
          method: 'GET',
          headers: new Headers({ 'Authorization': 'Bearer ' + token }),
        });
        let val = await res.json();
        // setIsFetchingGoogleDriveFiles(false);
        setListDocumentsVisibility(true);
        setDocuments(val.files);
        prevPageToken = nextPageToken;
        nextPageToken = val.nextPageToken || "";
      } catch (error) {
        console.error(error);
      }


    }

  }


  // useEffect(() => {
  //   const interval =  setInterval(() => {
  //     const token = getItemWithExpiration("google_access_token");
  //     if(!token){
  //       setListDocumentsVisibility(false);
  //       setGoogleEmail("")
  //     }
  //   }, 60000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []); 

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.slice(1));
    // const urlParams = new URLSearchParams(window.location.hash);
    const accessToken = urlParams.get('access_token');
    const expiresIn = urlParams.get('expires_in');

    if (accessToken && expiresIn) {
      setItemWithExpiration("google_access_token", accessToken, expiresIn)
      window.location.href = window.location.origin + '/board'
    }

  }, [window.location]);


  useEffect(() => {

    setListDocumentsVisibility(true)
    const token = getItemWithExpiration("google_access_token")
    if (token) {
      fetch(`https://www.googleapis.com/oauth2/v3/userinfo`, {
        method: 'GET',
        headers: new Headers({ 'Authorization': 'Bearer ' + token }),
      }).then(async res => {
        let val = await res.json();
        setGoogleEmail(val.email)
      });

      if (!getItemWithExpiration("load-drive-file")) {
        getDocumens()
        setItemWithExpiration("load-drive-file", 1, 1)
      }


    } else {
      setListDocumentsVisibility(false)
    }

  }, []);


  const handleClientLoad = () => {
    const token = getItemWithExpiration("google_access_token")
    if (token) {
      getDocumens()
    } else {
      if (callSource) {
        setItemWithExpiration("requestGoogleAuth", callSource, 300);
      }
      handleGoogleLogin()
      // googleSignRedirect(false,isGoogleMeetAddon)
    }
  };



  const [fileDataBody, setFileDataBody] = useState(null);
  useEffect(() => {
    if (fileDataBody) {
      _click();
    }
  }, [fileDataBody]);

  const _change = (event) => {
    let file = event.target.files[0]
    let name = file.name
    let type = file.type
    let fileData = {}
    fileData.file = file
    fileData.name = name
    fileData.type = type
    setFileDataBody(fileData)
  }

  const _click = async () => {
    const rootFolderId = await getRootFolder("imago-collab")
    let file = new Blob([fileDataBody.file]);
    let metadata = {
      "name": fileDataBody.name,
      "mimeType": fileDataBody.type,
      "parents": [rootFolderId]
    };

    const token = getItemWithExpiration("google_access_token")
    if (!token) {
      alert("Please log in first")
      return
    }
    let form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);



    try {
      let res = await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
        method: 'POST',
        headers: new Headers({ 'Authorization': 'Bearer ' + token }),
        body: form,
      });
      let val = await res.json();
      getDocumens();
      alert('Upload success');
    } catch (error) {
      console.error(error);
    }
  }


  const deleteFile = async (fileId) => {
    if (confirm("Sure to delete?")) {
      const token = getItemWithExpiration("google_access_token")
      if (!token) {
        alert("Please login first")
        return
      }
      let url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
      let res = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({ 'Authorization': 'Bearer ' + token }),
      });

      getDocumens();
    }

  }



  const handleSignOutClick = () => {

  }

  return (

    <div style={{ height: "100%", display: "flex", flexDirection: "column", width: "100%" }}>

      <div style={{ flex: 1, overflow: "auto" }}>

        {listDocumentsVisible ? (

          <GoogleDriveFileList
            documents={documents}
            onSearch={getDocumens}
            signedInUser={signedInUser}
            onSignOut={handleSignOutClick}
            onChange={_change}
            deleteFile={deleteFile}
            isLoading={isFetchingGoogleDriveFiles} />

        ) : (


          <div onClick={() => handleClientLoad()} className="source-container">
            <img id='driver-icon' src={GoogleDriveImage} />
            <div style={{ margin: "0.5em" }}>{t("labels.google_drive_desc")}</div>
          </div>

        )}
      </div>


      {!!listDocumentsVisible && (<div className="side-action">
        <label htmlFor="fileInput" className="fileInputLabel">
          {t("labels.btn_upload_file")}
        </label>
        <input
          type="file"
          id="fileInput"
          className="fileInput"
          onChange={_change}
        />

      </div>)}

    </div >





  );
};

export default GoogleDriveComponent;
