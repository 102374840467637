import {
  useRef,
  useState,
  useEffect,
  useCallback,
  RefObject,
  forwardRef,
  SetStateAction,
} from "react";
import Library, {
  distributeLibraryItemsOnSquareGrid,
  libraryItemsAtom,
} from "../data/library";
import { t } from "../i18n";
import { randomId } from "../random";
import {
  LibraryItems,
  LibraryItem,
  AppState,
  ImagoProps,
  ImagoImperativeAPI,
  Device,
} from "../types";

import "./LibraryMenu.scss";
import LibraryMenuItems from "./LibraryMenuItems";
import {
  APP_NAME,
  EVENT,
  URL_HASH_KEYS,
  URL_QUERY_KEYS,
  VERSIONS,
} from "../constants";
import { KEYS } from "../keys";
import { trackEvent } from "../analytics";
import { useAtom } from "jotai";
import { jotaiScope } from "../jotai";
import Spinner from "./Spinner";
import { useDevice, useImagoElements, useImagoSetAppState } from "./App";
import { Sidebar } from "./Sidebar/Sidebar";
import { getSelectedElements } from "../scene";
import { NonDeletedImagoElement } from "../element/types";
import { LibraryMenuHeader } from "./LibraryMenuHeaderContent";
import LibraryMenuBrowseButton from "./LibraryMenuBrowseButton";

import { TabList } from "./sidepanel/SidePanel";
import { GetLoginedUserLicence } from "../utils";
import { LocalData } from "../imago-app/data/LocalData";

export { parseLibraryTokensFromUrl, useHandleLibrary } from "../data/library";

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  cb: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      if (
        event.target instanceof Element &&
        (ref.current.contains(event.target) ||
          !document.body.contains(event.target))
      ) {
        return;
      }

      cb(event);
    };
    document.addEventListener("pointerdown", listener, false);

    return () => {
      document.removeEventListener("pointerdown", listener);
    };
  }, [ref, cb]);
};

const LibraryMenuWrapper = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  return (
    <div ref={ref} className="layer-ui__library">
      {children}
    </div>
  );
});

export const LibraryMenuContent = ({
  onInsertLibraryItems,
  pendingElements,
  onAddToLibrary,
  setAppState,
  libraryReturnUrl,
  library,
  id,
  appState,
  selectedItems,
  onSelectItems,
  device,
  setSelectedItems,
  onRemoveFromLibrary,
  resetLibrary,
  onItemTouchEnd,
  onPageChange,
}: {
  pendingElements: LibraryItem["elements"];
  onInsertLibraryItems: (libraryItems: LibraryItems) => void;
  onAddToLibrary: () => void;
  setAppState: React.Component<any, AppState>["setState"];
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  library: Library;
  id: string;
  appState: AppState;
  selectedItems: LibraryItem["id"][];
  onSelectItems: (id: LibraryItem["id"][]) => void;
  device: Device;
  setSelectedItems?: React.Dispatch<SetStateAction<string[]>>;
  onRemoveFromLibrary?: () => void;
  resetLibrary?: () => void;
  onItemTouchEnd?: (id: string, event: React.TouchEvent) => void;
  onPageChange: (page: number, pageSize: number) => void;
}) => {
  const [libraryItemsData] = useAtom(libraryItemsAtom, jotaiScope);

  const addToLibrary = useCallback(
    async (elements: LibraryItem["elements"], libraryItems: LibraryItems) => {
      trackEvent("element", "addToLibrary", "ui");
      if (elements.some((element) => element.type === "image")) {
        return setAppState({
          errorMessage: "Support for adding images to the library coming soon!",
        });
      }
      const nextItems: LibraryItems = [
        {
          status: "unpublished",
          elements,
          id: randomId(),
          created: Date.now(),
        },
        ...libraryItems,
      ];
      onAddToLibrary();

      library
        .setLibrary(nextItems)
        .catch(() => {
          setAppState({ errorMessage: t("alerts.errorAddingToLibrary") });
        })
        .then(async () => {
          await LocalData.librariesStorage.saveAll(nextItems as LibraryItem[]);
        });
    },
    [onAddToLibrary, library, setAppState],
  );

  if (
    libraryItemsData.status === "loading" &&
    !libraryItemsData.isInitialized
  ) {
    return (
      <LibraryMenuWrapper>
        <div className="layer-ui__library-message">
          <div>
            <Spinner size="2em" />
            <span>{t("labels.libraryLoadingMessage")}</span>
          </div>
        </div>
      </LibraryMenuWrapper>
    );
  }

  const showBtn =
    libraryItemsData.libraryItems.length > 0 || pendingElements.length > 0;

  return (
    <LibraryMenuWrapper>
      <LibraryMenuItems
        isLoading={libraryItemsData.status === "loading"}
        libraryItems={libraryItemsData.libraryItems}
        onAddToLibrary={(elements) =>
          addToLibrary(elements, libraryItemsData.libraryItems)
        }
        onInsertLibraryItems={onInsertLibraryItems}
        pendingElements={pendingElements}
        selectedItems={selectedItems}
        onSelectItems={onSelectItems}
        id={id}
        libraryReturnUrl={libraryReturnUrl}
        theme={appState.theme}
        appState={appState}
        setAppState={setAppState}
        device={device}
        onItemTouchEnd={onItemTouchEnd}
        onPageChange={onPageChange}
      />
      <LibraryMenuBrowseButton
        id={id}
        libraryReturnUrl={libraryReturnUrl}
        theme={appState.theme}
        appState={appState}
        setAppState={setAppState}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        library={library}
        resetLibrary={resetLibrary}
        onRemoveFromLibrary={onRemoveFromLibrary}
      />
    </LibraryMenuWrapper>
  );
};

export const LibraryIframe = ({
  appState,
  setAppState,
  loading,
  iframeRef,
  handleLoad,
  url,
}: {
  loading: Boolean;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  iframeRef: React.LegacyRef<HTMLIFrameElement> | undefined;
  handleLoad: React.ReactEventHandler<HTMLIFrameElement> | undefined;
  url: string | undefined;
}) => {
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* {loading && <div style={{ padding: "12px" }}>loading...</div>} */}
      <div style={{ flex: 1 }}>
        <iframe
          ref={iframeRef}
          onLoad={handleLoad}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          src={url}
        />
      </div>
      <div className="side-action">
        <a
          className="action-button"
          onClick={() => {
            setAppState({ openLibraryPanel: "selectComponet" });
          }}
        >
          {t("buttons.returnBack")}
        </a>
      </div>
    </div>
  );
};

export const LibraryMenu: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  onInsertElements: (elements: readonly NonDeletedImagoElement[]) => void;
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  focusContainer: () => void;
  library: Library;
  id: string;
  onItemTouchEnd?: (id: string, event: React.TouchEvent) => void;
  onPageChange: (page: number, pageSize: number) => void;
}> = ({
  appState,
  setAppState,
  onInsertElements,
  libraryReturnUrl,
  focusContainer,
  library,
  id,
  onItemTouchEnd,
  onPageChange,
}) => {
  const referrer =
    libraryReturnUrl || window.location.origin + window.location.pathname;
  const frameurl = `${process.env.REACT_APP_LIBRARY_URL}?target=${
    window.name || "_blank"
  }&referrer=${referrer}&useHash=true&token=${id}&theme=light&sort=new&max=${
    GetLoginedUserLicence()?.library
  }&version=${VERSIONS.imagoLibrary}&time=${Date.now()}`;

  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(frameurl);
  const iframeRef = useRef(null);

  const elements = useImagoElements();
  const device = useDevice();

  const [selectedItems, setSelectedItems] = useState<LibraryItem["id"][]>([]);
  const [libraryItemsData] = useAtom(libraryItemsAtom, jotaiScope);

  const ref = useRef<HTMLDivElement | null>(null);

  const closeLibrary = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({ openSidebar: null });
    setAppState({ openLibraryPanel: null });
  }, [setAppState]);

  useOnClickOutside(
    ref,
    useCallback(
      (event) => {
        // If click on the library icon, do nothing so that LibraryButton
        // can toggle library menu
        if ((event.target as Element).closest(".ToolIcon__library")) {
          return;
        }
        if (!appState.isSidebarDocked || !device.canDeviceFitSidebar) {
          closeLibrary();
        }
      },
      [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar],
    ),
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === KEYS.ESCAPE &&
        (!appState.isSidebarDocked || !device.canDeviceFitSidebar)
      ) {
        closeLibrary();
      }
    };
    document.addEventListener(EVENT.KEYDOWN, handleKeyDown);
    return () => {
      document.removeEventListener(EVENT.KEYDOWN, handleKeyDown);
    };
  }, [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar]);

  const deselectItems = useCallback(() => {
    setAppState({
      selectedElementIds: {},
      selectedGroupIds: {},
    });
  }, [setAppState]);

  const handleMessage = (event: MessageEvent) => {
    if (event.data && typeof event.data === "string") {
      const msgBody = JSON.parse(event.data);
      if (msgBody.param && msgBody.param.url) {
        importLibraryFromURL({ libraryUrl: msgBody.param.url });
        setAppState({ openLibraryPanel: "selectComponet" });
      }
    }
  };
  const removeFromLibrary = useCallback(
    async (libraryItems: LibraryItems) => {
      const nextItems = libraryItems.filter(
        (item) => !selectedItems.includes(item.id),
      );
      library.setLibrary(nextItems).catch(() => {
        setAppState({ errorMessage: t("alerts.errorRemovingFromLibrary") });
      });
      setSelectedItems([]);
      selectedItems.forEach((id) => {
        LocalData.librariesStorage.remove(id);
      });
    },
    [library, setAppState, selectedItems, setSelectedItems],
  );

  const resetLibrary = useCallback(() => {
    library.resetLibrary();
    focusContainer();
  }, [library, focusContainer]);

  const handleLoad = () => {
    setLoading(false);
  };

  const importLibraryFromURL = async ({
    libraryUrl,
  }: {
    libraryUrl: string;
  }) => {
    const libraryPromise = new Promise<Blob>(async (resolve, reject) => {
      try {
        const request = await fetch(decodeURIComponent(libraryUrl));
        const blob = await request.blob();
        resolve(blob);
      } catch (error: any) {
        reject(error);
      }
    });

    try {
      await library.updateLibrary({
        libraryItems: libraryPromise,
        prompt: false,
        merge: false,
        defaultStatus: "published",
        openLibraryMenu: true,
        isImport: true,
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  window.importLibraryCall = (url: string) => {
    importLibraryFromURL({ libraryUrl: url });
    setAppState({ openLibraryPanel: "selectComponet" });
  };

  return appState.openLibraryPanel == null ||
    appState.openLibraryPanel == "selectComponet" ? (
      <>
        <LibraryMenuContent
          pendingElements={getSelectedElements(elements, appState, true)}
          onInsertLibraryItems={(libraryItems) => {
            onInsertElements(
              distributeLibraryItemsOnSquareGrid(libraryItems),
            );
          }}
          onAddToLibrary={deselectItems}
          setAppState={setAppState}
          libraryReturnUrl={libraryReturnUrl}
          library={library}
          id={id}
          appState={appState}
          selectedItems={selectedItems}
          onSelectItems={setSelectedItems}
          device={device}
          setSelectedItems={setSelectedItems}
          resetLibrary={resetLibrary}
          onRemoveFromLibrary={() =>
            removeFromLibrary(libraryItemsData.libraryItems)
          }
          onItemTouchEnd={onItemTouchEnd}
          onPageChange={onPageChange}
        />
      </>
    ) : (
      <LibraryIframe
        appState={appState}
        setAppState={setAppState}
        loading={loading}
        iframeRef={iframeRef}
        handleLoad={handleLoad}
        url={url}
      />
    );
};
