import "./ImageSearch.scss";
import React, {
  useRef,
  useState,
  useEffect,
  MouseEvent
} from "react";
import { TabList } from './sidepanel/SidePanel'
import { AppState } from "../types";
import { EVENT } from "../constants";
import { KEYS } from "../keys";
import { Button, Input, Radio, Spin } from "antd";
import { AiSearchIcon, ChatIcon, GoogleIcon, ImageAiIcon, SwitchLeftIcon, SwitchRightIcon } from "./icons";
import { use } from "chai";
import { t } from "../i18n";
import { AiChat } from "./AiChat";
export const ImageSearch: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
}> = ({
  appState,
  setAppState,
  id,
}) => {
    const [isAiSearching, setIsAiSearching] = useState(false);
    const [isAiSearch, setIsAiSearch] = useState(true);
    const [prompt, setPrompt] = useState("");
    const [aiImages, setAiImages] = useState([])
    const [aiError, setAiError] = useState("")
    const { userInfo } = appState;

    function switchSearchBox(): void {
      if (!isAiSearch) {
        document.getElementById("aisearch")?.setAttribute("style", "height:100%;overflow:'hidden'");
        document.getElementById("aichat")?.setAttribute("style", "height:0;overflow:hidden");
      } else {
        document.getElementById("aisearch")?.setAttribute("style", "height:0;overflow:hidden");
        document.getElementById("aichat")?.setAttribute("style", "height:100%;overflow:'hidden'");
      }
      setIsAiSearch(!isAiSearch);
    }

    async function aiSearch(): Promise<void> {
      if (!appState.userLicence?.aIImage) {
        alert(t("alerts.shouldGrade"));
        return;
      }
      if (!prompt || isAiSearching) return;
      setIsAiSearching(true);
      const response = await fetch(`/api/v1/ai/generateimages?prompt=${prompt}`, {
        headers: {
          Authorization: "Bearer " + userInfo?.authorization
        }
      });
      if (response.status !== 200)
        return;

      const responseJson = (await response.json());
      if (responseJson.error) {
        if (responseJson.error.code == "rate_limit_exceeded") {
          setAiError(t("winTabList.ai_rate_limit_err"));
        } else {
          setAiError(t("winTabList.ai_gen_fail_err"));
        }
        setAiImages([]);
      } else {
        setAiImages(responseJson.data);
        setAiError("");
      }

      setIsAiSearching(false);
    }


    return (
      <>


        <div className="action-switch">
          <Radio.Group
            defaultValue={"aisearch"}
            buttonStyle="outline"
            onChange={(e) => {
              switchSearchBox();
            }}
          >
            <Radio.Button value="aisearch">{t("winTabList.ai_image")}</Radio.Button>
            <Radio.Button value="aichat">{t("winTabList.ai_text")}</Radio.Button>
          </Radio.Group>
        </div>
        <div className="action-content">
          <div id="aisearch" style={{ height: isAiSearch ? "100%" : "0", overflow: 'hidden' }}>
            <div className="ai-images-searchbox">
              <Input placeholder={t("winTabList.gen_img_by_ai")} onChange={(e) => setPrompt(e.target.value)} />
              <Button onClick={aiSearch} icon={AiSearchIcon} style={{ background: "none" }} />
            </div>
            <div className="ai-images-searchresults">
              {isAiSearching ? <Spin /> :
                aiImages.length > 0 ? aiImages.map((img: any, i: number) => {
                  return <img src={"data:image/png;base64," + img.b64_json} key={i} />
                }) : aiError
              }
            </div>
          </div>
          <div id="aichat" style={{ height: isAiSearch ? "0" : "100%", overflow: 'hidden' }}>
            <AiChat appState={appState} setAppState={setAppState} />
          </div>
        </div>
      </>
    );
  }