import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "./googleDrive.css";

import {
  Col,
  Drawer,
  Row,
  Button,
  Input,
  Table,
  Tooltip,
  Flex,
  Pagination,
} from "antd";
import { ALLOWED_IMAGE_MIME_TYPES } from "../../constants";
import { DownloadIcon, SearchIcon } from "../icons";
import { t } from "../../i18n";
const { Search } = Input;

const GoogleDriveFileList = ({
  documents = [],
  onSearch,
  signedInUser,
  onSignOut,
  isLoading,
  onChange,
  deleteFile,
}) => {
  const [searchKey, setSearchKey] = useState("");
  const _down = async (key) => {
    const element = document.createElement("a");
    element.setAttribute("href", key.webContentLink);
    element.setAttribute("download", key.name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const columns = [
    {
      title: t("labels.fileTitle"),
      dataIndex: "name",
      key: "name",
      span: 10,
      render: (_, record) => {
        if (ALLOWED_IMAGE_MIME_TYPES.includes(record.mimeType)) {
          const img = record.thumbnailLink
            ? record.thumbnailLink
            : record.hasThumbnail
            ? `https://drive.google.com/thumbnail?id=${record.id}`
            : record.webContentLink;
          return (
            <span className="googldrive-file-title">
              {record.name}{" "}
              <img src={img} id={record.id} className="googledriveimg" />
            </span>
          );
        }
        return <span className="googldrive-file-title">{record.name}</span>;
      },
    },

    {
      title: t("labels.actions"),
      key: "status",
      dataIndex: "status",
      width: 120,
      render: (_, record) => (
        <span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Tooltip title="">
              <Button
                onClick={() => _down(record)}
                style={{
                  marginRight: 8,
                }}
                icon={DownloadIcon}
                type="text"
              ></Button>
            </Tooltip>
            {/* <Tooltip title="Delete Document">
              <Button
                onClick={() => deleteFile(record.id)}
                style={{
                  marginRight: 8,
                  marginTop: "5px"
                }}
              >
                Delete
              </Button></Tooltip> */}
          </div>
        </span>
      ),
    },
  ];

  const search = (next) => {
    delayedQuery(searchKey, next);
  };

  const delayedQuery = useCallback(
    debounce((q, next) => onSearch(q, next), 500),
    [],
  );

  const key = "";
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Row gutter={0}>
        <Col span={24}>
          <div className="table-card-actions-container">
            <div className="table-search-container">
              <Flex wrap="nowrap" gap="small">
                <Input
                  placeholder=""
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <Button
                  onClick={() => {
                    search();
                  }}
                  icon={SearchIcon}
                  className="white-icon"
                  style={{
                    background: "#5B57D1",
                    width: "2.45rem",
                    height: "2.25rem",
                  }}
                />
              </Flex>
            </div>
          </div>

          <Table
            className="table-striped-rows"
            columns={columns}
            dataSource={documents}
            pagination={{
              itemRender: (page, type, originalElement) => {
                //: 'page' | 'prev' | 'next'
                switch (type) {
                  case "prev":
                    return (
                      <a href="javascript:;" onClick={() => search()}>
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="left"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                        </svg>
                      </a>
                    );
                  case "next":
                    return (
                      <a
                        style={{ marginRight: "5px" }}
                        href="javascript:;"
                        onClick={() => search(true)}
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="right"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                        </svg>
                      </a>
                    );
                  case "page":
                    return null;
                }
              },
            }}
            //loading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GoogleDriveFileList;
