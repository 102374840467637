import React, { useEffect, useState } from "react"
import "./StemSubject.scss"
import { Button, Flex, Input, Card, List } from 'antd';
import Meta from "antd/es/card/Meta"
import { AppState } from "../types";
import { checkLangKey, t } from "../i18n";

const { TextArea } = Input;

export const getSubjectName = (item: any): string => {
  let key = item.nameKey.replace("sim_sub_", "");
  const sub_key = `data_record.subject.${key}`;
  if (checkLangKey(sub_key)) {
    const v = t(sub_key);
    if (v != "") {
      return v;
    }
    return item.name;
  }
  return item.name; 
};

export const StemSubject: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const [subjects, setSubjects] = useState<any[]>();
  const host = "";


  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${host}/stemapi/v1/subjects`
      );
      let stemSubject = (await response.json());
      setSubjects(stemSubject.data);
    })();
  }, []);


  const handleSubjectClick = async (name: string, subjectID: string) => {

    try {
      const request = await fetch(
        `${host}/stemapi/v1/category/:id/subjectName?subjectName=${name}`,
      );

      // const data = await request.json();

      // Save the selected subject in local storage
      localStorage.setItem('selectedSubject', name);
      localStorage.setItem('selectedSubjectID', subjectID);

      setAppState({
        openDialog: "stemSimulation",
        openSidebar: null,
        lastOpenSidebar: "marketPlace"
      });
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };

  return (
    <>
      <div className="stem-content">
        <h2>{t("labels.stem_choose_subject")}</h2>
        <div className="list">
          <List
            grid={{
              gutter: 24, xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 5
            }} // Adjust column number as needed
            dataSource={subjects}
            renderItem={(item) => (
              <Card
                hoverable
                key={item.id}
                style={{
                  margin: "20px",
                  width: "140px",
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",

                }}
                cover={
                  <div className="card-cover" >
                    <img
                      alt="Thumbnail"
                      src={`data:image/png;base64,${item.thumbnail}`}
                      width="100"
                      height="100"
                    />
                  </div>
                }

                onClick={() => {
                  handleSubjectClick(item.name, item._id);
                }}

              >
                <Meta
                  title={
                    <div
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis", // Ensures text doesn't overflow the card
                        fontSize: "16px", // Fixed font size for the title 
                      }}>
                      {getSubjectName(item)}
                    </div>}
                />
              </Card>
            )}
          />

        </div>
      </div>
    </>
  );
};
