import fallbackLangData from "./locales/en.json";
import percentages from "./locales/percentages.json";
import { ENV } from "./constants";

const COMPLETION_THRESHOLD = 85;

export interface Language {
  code: string;
  label: string;
  rtl?: boolean;
}

export let defaultLang = { code: "en", label: "English" };

// const allLanguages: Language[] = [
//   { code: "en", label: "English" },
//   { code: "ar-SA", label: "العربية", rtl: true },
//   { code: "bg-BG", label: "Български" },
//   { code: "ca-ES", label: "Català" },
//   { code: "cs-CZ", label: "Česky" },
//   { code: "de-DE", label: "Deutsch" },
//   { code: "el-GR", label: "Ελληνικά" },
//   { code: "es-ES", label: "Español" },
//   { code: "eu-ES", label: "Euskara" },
//   { code: "fa-IR", label: "فارسی", rtl: true },
//   { code: "fi-FI", label: "Suomi" },
//   { code: "fr-FR", label: "Français" },
//   { code: "gl-ES	", label: "Galego" },
//   { code: "he-IL", label: "עברית", rtl: true },
//   { code: "hi-IN", label: "हिन्दी" },
//   { code: "hu-HU", label: "Magyar" },
//   { code: "id-ID", label: "Bahasa Indonesia" },
//   { code: "it-IT", label: "Italiano" },
//   { code: "ja", label: "日本語" },
//   { code: "kab-KAB", label: "Taqbaylit" },
//   { code: "kk-KZ", label: "Қазақ тілі" },
//   { code: "ko-KR", label: "한국어" },
//   { code: "ku-TR", label: "Kurdî" },
//   { code: "lt-LT", label: "Lietuvių" },
//   { code: "lv-LV", label: "Latviešu" },
//   { code: "my-MM", label: "Burmese" },
//   { code: "nb-NO", label: "Norsk bokmål" },
//   { code: "nl-NL", label: "Nederlands" },
//   { code: "nn-NO", label: "Norsk nynorsk" },
//   { code: "oc-FR", label: "Occitan" },
//   { code: "pa-IN", label: "ਪੰਜਾਬੀ" },
//   { code: "pl-PL", label: "Polski" },
//   { code: "pt-BR", label: "Português Brasileiro" },
//   { code: "pt-PT", label: "Português" },
//   { code: "ro-RO", label: "Română" },
//   { code: "ru-RU", label: "Русский" },
//   { code: "sk-SK", label: "Slovenčina" },
//   { code: "sv-SE", label: "Svenska" },
//   { code: "sl-SI", label: "Slovenščina" },
//   { code: "tr-TR", label: "Türkçe" },
//   { code: "uk-UA", label: "Українська" },
//   { code: "zh-CN", label: "简体中文" },
//   { code: "zh-TW", label: "繁體中文" },
//   { code: "vi-VN", label: "Tiếng Việt" },
//   { code: "mr-IN", label: "मराठी" },
// ];


let allLanguages: Language[] = [
  { code: "en", label: "English" },
  { code: "ar-SA", label: "العربية" },
  { code: "id-ID", label: "Bahasa Indonesia" },
  { code: "ms-MY", label: "Melayu" },
  { code: "nl-NL", label: "Nederlands" },
  { code: "fr-FR", label: "Français" },
  { code: "de-DE", label: "Deutsch" },
  { code: "it-IT", label: "Italiano" },
  { code: "ja-JP", label: "日本語" },
  { code: "ko-KR", label: "한국어" },
  { code: "ru-RU", label: "Русский" },
  { code: "es-ES", label: "Español" },
  { code: "sw-KE", label: "Kiswahili" },
  { code: "sv-SE", label: "Svenska" },
  { code: "de-CH", label: "Schweiz" },
  { code: "th-TH", label: "ภาษาไทย" },
  { code: "tr-TR", label: "Türkçe" },
  { code: "vi-VN", label: "Tiếng Việt" },
  { code: "zh-HK", label: "繁體中文", },
  { code: "zh-CN", label: "简体中文" },
];

let langCode = "";
switch (navigator.language) {
  case "ja":
    langCode = "ja-JP"
    break;
  case "en":
    langCode = "en"
    break;
  case "zh":
  case "zh-TW":
  case "zh-HK":
    langCode = "zh-HK"
    break;
  case "de-DE":
    langCode = "de-DE"
    break;
  case "de-DE":
    langCode = "de-CH"
    break;
  case "fr-FR":
    langCode = "fr-FR"
    break;
  case "ru-RU":
  case "ru":
    langCode = "ru-RU"
    break;
  case "ms-MY":
  case "ms":
    langCode = "ms-MY"
    break;
  case "th-TH":
  case "th":
    langCode = "th-TH"
    break;
  case "nl-NL":
  case "nl":
    langCode = "nl-NL"
    break;
  case "it-IT":
    langCode = "it-IT"
    break;
  case "ko-KR":
  case "ko":
    langCode = "ko-KR"
    break;
  case "es-ES":
    langCode = "es-ES"
    break;
  case "sw-KE":
  case "sw":
    langCode = "sw-KE"
    break;
  case "sv-SE":
  case "sv":
    langCode = "sv-SE"
    break;
  case "tr-TR":
  case "tr":
    langCode = "tr-TR"
    break;
  case "vi-VN":
  case "vi":
    langCode = "vi-VN"
    break;
  default:
    langCode = navigator.language
    break;
}

const index = allLanguages.findIndex(l => l.code === langCode);
if (index !== -1) {
  defaultLang = allLanguages[index];
}


export const languages: Language[] = allLanguages
  .sort((left, right) => (left.label > right.label ? 1 : -1))
  .filter(
    (lang) =>
      (percentages as Record<string, number>)[lang.code] >=
      COMPLETION_THRESHOLD,
  );



let currentLang: Language = defaultLang;
let currentLangData = {};

export const setLanguage = async (lang: Language) => {
  currentLang = lang;
  document.documentElement.dir = currentLang.rtl ? "rtl" : "ltr";
  document.documentElement.lang = currentLang.code;

  currentLangData = await import(
      /* webpackChunkName: "locales/[request]" */ `./locales/${currentLang.code}.json`
  );
};

export const getLanguage = () => currentLang;

const findPartsForData = (data: any, parts: string[]) => {
  for (let index = 0; index < parts.length; ++index) {
    const part = parts[index];
    if (data[part] === undefined) {
      return undefined;
    }
    data = data[part];
  }
  if (typeof data !== "string") {
    return undefined;
  }
  return data;
};

export const t = (
  path: string,
  replacement?: { [key: string]: string | number },
) => {

  if (!checkLangKey(path, replacement)) {
    return ""
  }

  const parts = path.split(".");
  let translation =
    findPartsForData(currentLangData, parts) ||
    findPartsForData(fallbackLangData, parts);
  if (translation === undefined) {
    throw new Error(`Can't find translation for ${path}`);
  }

  if (replacement) {
    for (const key in replacement) {
      translation = translation.replace(`{{${key}}}`, String(replacement[key]));
    }
  }

  return translation;
};


export const checkLangKey = (
  path: string,
  replacement?: { [key: string]: string | number },
) => {

  const parts = path.split(".");
  let translation =
    findPartsForData(currentLangData, parts) ||
    findPartsForData(fallbackLangData, parts);
  if (translation === undefined) {
    return false
  }
  return true
};