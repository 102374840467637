import "./ImageGallery.scss";
import { useRef, useState, useEffect } from "react";
import QRCode from "qrcode.react";
import socketIOClient from "socket.io-client";
import { TabList } from "./sidepanel/SidePanel";
import { AppState, BinaryFileData, BinaryFiles } from "../types";
import { t } from "../i18n";
import {
  capitalizeString,
  isTransparent,
  updateActiveTool,
  setCursorForShape,
} from "../utils";
import { ImagoElement, PointerType } from "../element/types";
import { Flex, List, Card, Radio, Button } from "antd";
import { trackEvent } from "../analytics";
import { LocalData } from "../imago-app/data/LocalData";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  SVGStringToFile,
  createFile,
  generateIdFromFile,
  getDataURL,
  getMimeType,
  isSupportedImageFile,
} from "../data/blob";
import { API_URL, BOARD_MODE, MIME_TYPES } from "../constants";
import { normalizeSVG } from "../element/image";
import { IconClose, IconCloseWin, LibMenuIcon } from "./icons";
import { plazaAPIAtom } from "../imago-app/Plaza";
import { Popover } from "./Popover";
import { Island } from "./Island";

import { ReactComponent as PictureSvg } from "../assets/icons/new/picture.svg";
import { getCurrBoardMode, isCollabtionHost } from "../imago-app/data/localStorage";

export const ImageGallery: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  scanUploadImage: (
    data: { insertOnCanvasDirectly: boolean },
    imageFile: File,
  ) => void;
  onImageAction: (data: {
    pointerType: PointerType | null;
    callback: () => void;
  }) => void;
  files: BinaryFiles;
  deleteFiles: (fileIds: string[]) => void;
  addFiles: (files: BinaryFileData[]) => void;
  isCollaborating: boolean;
}> = ({
  appState,
  setAppState,
  id,
  scanUploadImage,
  onImageAction,
  files,
  deleteFiles,
  addFiles,
  isCollaborating,
}) => {
    const [isStartScan, setIsStartScan] = useState<boolean>(true);
    const [plazaAPI] = useAtom(plazaAPIAtom);
    const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
    const { userInfo } = appState;
    const fileList = Object.keys(files)?.map((k) => files[k]);
    const currBoardMode = getCurrBoardMode()
    const isHost = isCollabtionHost()

    //console.log("images",fileList,files)
    const [imageFiles, setImageFiles] = useState<BinaryFileData[]>();
    //console.log("images2",imageFiles)
    const [switchAction, setSwitchAction] = useState<"myFiles" | "scanQr">("myFiles");


    const generateImageFile = async (url: any) => {
      const res = await fetch(url);
      const imageData = await res.blob();
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      const file = new File([imageData], fileName, { type: imageData.type });
      return file;
    };


    const loadImageToCanvas = async () => {
      const imgFile = await generateImageFile(uploadedImageUrl);
      scanUploadImage({ insertOnCanvasDirectly: true }, imgFile);
      setUploadedImageUrl("");
    };

    const cancelLoadImage = () => {
      setUploadedImageUrl(null);
    };
    const onOpen = ({ pointerType }: any) => {
      const value = "image";
      if (appState.activeTool.type !== value) {
        trackEvent("toolbar", value, "ui");
      }
      const nextActiveTool = updateActiveTool(appState, {
        type: "image",
      });

      setAppState({
        activeTool: { ...nextActiveTool },
        multiElement: null,
        selectedElementIds: {},
      });

      onImageAction({
        pointerType,
        callback: () => {
        },
      });
      //   setIsPreview(true);
    };

    const handleImageClose = (file: any) => {
      const surplusFiles = fileList.filter((f) => f.id !== file.id);
      deleteFiles([file.id]);
      setImageFiles(surplusFiles);
      LocalData.fileStorage.clearObsoleteFiles({
        currentFileIds: surplusFiles.map((sf) => sf.id),
        clearAnyway: true,
      });
    };

    const handleRemoveAll = () => {
      deleteFiles(fileList.map((i) => i.id));
      setImageFiles([]);
      LocalData.fileStorage.clearObsoleteFiles({
        currentFileIds: [],
        clearAnyway: true,
      });
    };

    const hasImage = () => {
      return (
        (imageFiles && imageFiles?.length > 0) ||
        (fileList && fileList?.length > 0)
      );
    };

    if (fileList.length !== imageFiles?.length) {
      setImageFiles(fileList);
    }


    useEffect(() => {

      if (!isCollaborating) {
        setIsStartScan(true)
      } else {
        if (isHost) {
          setIsStartScan(true)
        } else {
          if (currBoardMode === BOARD_MODE.moderator || currBoardMode === BOARD_MODE.classRoom) {
            setIsStartScan(false)
          } else {
            setIsStartScan(true)
          }
        }
      }

    }, []);



    return (
      <>
        <div className="action-switch">
          <Radio.Group
            defaultValue={switchAction}
            buttonStyle="outline"
            onChange={(e) => {
              setSwitchAction(e.target.value);
            }}
          >
            <Radio.Button value="myFiles">{t("labels.btn_my_file")}</Radio.Button>
            {isStartScan ? (<Radio.Button value="scanQr">{t("labels.btn_scan_qrcode")}</Radio.Button>) : (<Radio.Button value="scanQr" disabled>{t("labels.btn_scan_qrcode")}</Radio.Button>)}

          </Radio.Group>
        </div>
        <div className="action-content">
          <div
            id="imagegallery"
            className="image-gallery-source-container"
          >
            {switchAction == "myFiles" ?
              <>{hasImage() ? (
                (imageFiles || fileList).map((item) => {
                  return (
                    <div className="gallery-image-container" key={item.id}>
                      <img src={item.dataURL} id={item.id} />
                      {!isCollaborating && (
                        <a onClick={() => handleImageClose(item)} title="close">
                          {IconClose}
                        </a>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="image-gallery-empty">

                  <PictureSvg />
                  <div>{t("labels.img_gallery_info_1")} <span>{t("labels.img_gallery_info_2")}</span></div>
                  <p>{t("labels.img_gallery_info_3")}: PNG, JPG, JPEG, WEBP</p>

                </div>
              )}

              </> : (
                <div
                  className="image-gallery-qrcode-background"
                  onClick={() => {
                  }}
                >
                  <div className="image-gallery-qrcode-container">
                    {isCollaborating ? (<QRCode
                      size={150}
                      value={`${API_URL.host
                        }/scan-upload-image?client=${plazaAPI?.socketId()}&currBoardMode=${currBoardMode}&hostUserId=${userInfo?.id}&isCollabration=true`}
                    />) : (<QRCode
                      size={150}
                      value={`${API_URL.host
                        }/scan-upload-image?client=${plazaAPI?.socketId()}`}
                    />)}

                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="side-action" style={{ gap: "20px" }}>
          <Button type="primary" onClick={onOpen}> {t("buttons.open")}</Button>
          <Button type="primary" onClick={handleRemoveAll}> {t("buttons.removeAll")}</Button>
          {/* <div className="action-button action-button-right">
              <a
                href={undefined}
                onClick={() => {
                  setShowMoreAction(!showMorzeAction);
                }}
              >
                {" "}
                {LibMenuIcon}
              </a>
              {showMorzeAction && (
                <Popover bottom={35}>
                  <Island>
                    <div className="more-action">
                      {!isCollaborating && fileList.length > 0 ? (
                        <div className="action-item">
                          <a href={undefined} onClick={handleRemoveAll}>
                            {t("buttons.removeAll")}
                          </a>
                        </div>
                      ) : null}
                      <div className="action-item">
                        {" "}
                        <a
                          href={undefined}
                          onClick={() => {
                            setIsPreview(false);
                            setShowMoreAction(false);
                            switchBox(false);
                          }}
                        >
                          {t("buttons.scanqr")}
                        </a>
                      </div>
                      <div className="action-item">
                        <a href={undefined} onClick={onOpen}>
                          {t("buttons.open")}
                        </a>
                      </div>
                    </div>
                  </Island>
                </Popover>
              )}
            </div> */}
        </div>
      </>

    );
  };
