import { useEffect } from "react";


export const GoogleImageSearch : React.FC<{}>=({})=>{

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cse.google.com/cse.js?cx=f68fda71e2d3c4f14";
        //script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
          const win = window as any;
        };
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    return <div>
    <div className="gcse-searchbox"></div>
    <div
      className="gcse-searchresults"
      data-defaultToImageSearch="true"
    ></div>
  </div>
}