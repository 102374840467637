import { t } from "../i18n";
import { CloudServiceIcon, CollaborateIcon } from "./icons";

import "./CollabButton.scss";
import MenuItem from "./MenuItem";
import clsx from "clsx";
import { AppState } from "../types";
import { IconCollabration } from "./newIcons";


const CollabButton = ({
  isCollaborating,
  collaboratorCount,
  onClick,
  isInHamburgerMenu = true,
  appState,
  setAppState,
  showLabel = true,
  icon
}: {
  isCollaborating: boolean;
  collaboratorCount: number;
  onClick: () => void;
  isInHamburgerMenu?: boolean;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  showLabel?: boolean;
  icon?:JSX.Element
}) => {
  return (
    <>
      {isInHamburgerMenu ? (
        <MenuItem
          label={t("labels.liveCollaboration")}
          showLabel = {showLabel}
          dataTestId="collab-button"
          icon={IconCollabration}
          onClick={() => {
            setAppState({ openSidebar: appState.lastOpenSidebar ||  "collaboration" });
            onClick();
          }}
          isCollaborating={isCollaborating}
        />
      ) : (
        <button
          className={clsx("collab-button", { active: isCollaborating })}
          type="button"
          onClick={() => {
            setAppState({ openSidebar: "collaboration" });
          }}
          style={{ position: "relative" }}
          title={t("labels.liveCollaboration")}
        >
          {CloudServiceIcon}
          {collaboratorCount > 0 && (
            <div className="CollabButton-collaborators">
              {collaboratorCount}
            </div>
          )}
        </button>
      )}
    </>
  );
};

export default CollabButton;
