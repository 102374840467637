import {
  PointerType,
  ImagoLinearElement,
  NonDeletedImagoElement,
  NonDeleted,
  TextAlign,
  ImagoElement,
  GroupId,
  ImagoBindableElement,
  Arrowhead,
  ChartType,
  FontFamilyValues,
  FileId,
  ImagoImageElement,
  Theme,
} from "./element/types";
import { SHAPES } from "./shapes";
import { Point as RoughPoint } from "roughjs/bin/geometry";
import { LinearElementEditor } from "./element/linearElementEditor";
import { SuggestedBinding } from "./element/binding";
import { ImportedDataState } from "./data/types";
import type App from "./components/App";
import type { ResolvablePromise, throttleRAF } from "./utils";
import { Spreadsheet } from "./charts";
import { Language } from "./i18n";
import { ClipboardData } from "./clipboard";
import { isOverScrollBars } from "./scene";
import { MaybeTransformHandleType } from "./element/transformHandles";
import Library from "./data/library";
import type { FileSystemHandle } from "./data/filesystem";
import type {
  ALLOWED_IMAGE_MIME_TYPES,
  MIME_TYPES,
  TOOLBAR_GROUPS,
} from "./constants";

export type Point = Readonly<RoughPoint>;

export type Collaborator = {
  pointer?: {
    x: number;
    y: number;
  };
  button?: "up" | "down";
  selectedElementIds?: AppState["selectedElementIds"];
  username?: string | null;
  userState?: UserIdleState;
  color?: {
    background: string;
    stroke: string;
  };
  // The url of the collaborator's avatar, defaults to username intials
  // if not present
  avatarUrl?: string;
  // user id. If supplied, we'll filter out duplicates when rendering user avatars.
  id?: string;
  isHost?: boolean
};

export type CollabMember = {
  status?: number;
  userId?: string | null;
  userName?: string | null;
  avatarUrl?: string;
  clientId?: string;
  hostClientId?: string;
  roomId?: string;
  isHost?: boolean;
  drawAuthFlag?: number | 0;
  videoAuthFlag?: number | 0;
  voiceAuthFlag?: number | 0;
};

export type DataURL = string & { _brand: "DataURL" };

export type BinaryFileData = {
  mimeType:
  | typeof ALLOWED_IMAGE_MIME_TYPES[number]
  // future user or unknown file type
  | typeof MIME_TYPES.binary;
  id: FileId;
  dataURL: DataURL;
  /**
   * Epoch timestamp in milliseconds
   */
  created: number;
  /**
   * Indicates when the file was last retrieved from storage to be loaded
   * onto the scene. We use this flag to determine whether to delete unused
   * files from storage.
   *
   * Epoch timestamp in milliseconds.
   */
  lastRetrieved?: number;
};

export type BinaryFileMetadata = Omit<BinaryFileData, "dataURL">;

export type BinaryFiles = Record<ImagoElement["id"], BinaryFileData>;

export type LastActiveToolBeforeEraser =
  | {
    type: typeof SHAPES[number]["value"] | "eraser" | "rubber";
    customType: null;
  }
  | {
    type: "custom";
    customType: string;
  }
  | null;

export type AppState = {
  showWelcomeScreen: boolean;
  isLoading: boolean;
  errorMessage: string | null;
  draggingElement: NonDeletedImagoElement | null;
  draggingElements: { [pointerId: number]: ImagoElement };
  pointers: Array<{ pointerId: number; x: number; y: number }>;
  resizingElement: NonDeletedImagoElement | null;
  multiElement: NonDeleted<ImagoLinearElement> | null;
  selectionElement: NonDeletedImagoElement | null;
  selectionElements: { [pointerId: number]: ImagoElement };
  isBindingEnabled: boolean;
  startBoundElement: NonDeleted<ImagoBindableElement> | null;
  suggestedBindings: SuggestedBinding[];
  // element being edited, but not necessarily added to elements array yet
  // (e.g. text element when typing into the input)
  editingElement: NonDeletedImagoElement | null;
  editingLinearElement: LinearElementEditor | null;
  activeTool:
  | {
    type: typeof SHAPES[number]["value"] | "eraser" | "rubber";
    lastActiveToolBeforeEraser: LastActiveToolBeforeEraser;
    locked: boolean;
    customType: null;
  }
  | {
    type: "custom";
    customType: string;
    lastActiveToolBeforeEraser: LastActiveToolBeforeEraser;
    locked: boolean;
  };
  penMode: boolean;
  penDetected: boolean;
  exportBackground: boolean;
  exportEmbedScene: boolean;
  exportWithDarkMode: boolean;
  exportScale: number;
  currentItemStrokeColor: string;
  currentItemBackgroundColor: string;
  currentItemFillStyle: ImagoElement["fillStyle"];
  currentItemStrokeWidth: number;
  currentItemStrokeStyle: ImagoElement["strokeStyle"];
  currentItemRoughness: number;
  currentItemOpacity: number;
  currentItemFontFamily: FontFamilyValues;
  currentItemFontSize: number;
  currentItemTextAlign: TextAlign;
  currentItemStrokeSharpness: ImagoElement["strokeSharpness"];
  currentItemStartArrowhead: Arrowhead | null;
  currentItemEndArrowhead: Arrowhead | null;
  currentItemLinearStrokeSharpness: ImagoElement["strokeSharpness"];
  viewBackgroundColor: string;
  scrollX: number;
  scrollY: number;
  cursorButton: "up" | "down";
  scrolledOutside: boolean;
  name: string;
  saveName: string;
  isResizing: boolean;
  showModalWin: boolean;
  isShowSetHandWriteLangPanel: boolean;
  defaultHandWriteLang: string;
  isRotating: boolean;
  zoom: Zoom;
  openMenu: "canvas" | "shape" | null;
  openPopup:
  | "canvasColorPicker"
  | "backgroundColorPicker"
  | "strokeColorPicker"
  | "strokeWidthPicker"
  | "bottomStrokeColorPicker"
  | "viewBackgroudStylePicker"
  | "extraActions"
  | "moreActions"
  | "bottomViewBackgroundColorPicker"
  | "multiObjects"
  | "moreFileActions"
  | "handwritingSuggestion"
  | "setSize"
  | keyof typeof TOOLBAR_GROUPS
  | null;
  openSidebar:
  | "library"
  | "customSidebar"
  | "collaboration"
  | "googleDrive"
  | "imageSearch"
  | "imageGallery"
  | "pageList"
  | "fileSaveTo"
  | "shareScreen"
  | "links"
  | "template"
  | "marketPlace"
  | null;
  lastOpenSidebar:
  | "library"
  | "customSidebar"
  | "collaboration"
  | "googleDrive"
  | "imageSearch"
  | "imageGallery"
  | "pageList"
  | "fileSaveTo"
  | "shareScreen"
  | "links"
  | "template"
  | "marketPlace"
  | null;
  openLibraryPanel: "selectComponet" | "library" | null;
  openDialog: "imageExport" | "help" | "fileSaveTo" | "mermaid" | "stem" | "stemSimulation" | "school" | "schoolLevel" | "schoolSubject" | "schoolFile" | "scanDownload" | "setBackground" | "setPenColor" | "collaborators" | null;
  isSidebarDocked: boolean;
  checkJoinCollabMemberWin: boolean;
  lastPointerDownWith: PointerType;
  selectedElementIds: { [id: string]: boolean };
  previousSelectedElementIds: { [id: string]: boolean };
  shouldCacheIgnoreZoom: boolean;
  toast: { message: string; closable?: boolean; duration?: number } | null;
  zenModeEnabled: boolean;
  theme: Theme;
  gridSize: number | null;
  gridStyle: "dot" | "lineDash" | "line" | null;
  viewModeEnabled: boolean;

  /** top-most selected groups (i.e. does not include nested groups) */
  selectedGroupIds: { [groupId: string]: boolean };
  /** group being edited when you drill down to its constituent element
    (e.g. when you double-click on a group's element) */
  editingGroupId: GroupId | null;
  width: number;
  height: number;
  offsetTop: number;
  offsetLeft: number;

  fileHandle: FileSystemHandle | null;
  collaborators: Map<string, Collaborator>;
  collabMembers: Map<string, CollabMember>;
  waitCheckMemberCount: number;
  waitCheckMemberStatusCount: number;
  waitCheckMemberDrawCount: number;
  currCollabHost: CollabMember | null;
  showStats: boolean;
  currentChartType: ChartType;
  pasteDialog:
  | {
    shown: false;
    data: null;
  }
  | {
    shown: true;
    data: Spreadsheet;
  };
  /** imageElement waiting to be placed on canvas */
  pendingImageElementId: ImagoImageElement["id"] | null;
  showHyperlinkPopup: false | "info" | "editor";
  selectedLinearElement: LinearElementEditor | null;
  usingBrush: boolean;
  newBoard: boolean;
  flashCollab: boolean;
  openPersonalBoard: boolean;
  openCollaborPersonalBoard: boolean;
  viewBackgroupStyle?: any;
  isChangePage: boolean;
  isLogined: boolean;
  userInfo: UserInfo | null;
  googleName: string | null;
  googleEmail: string | null;
  screenSharing: {
    pinCode?: string;
    isHost?: boolean;
    status?: "idle" | "confirm" | "sharing";
    host?: {
      userName: string;
      pinCode: string;
    };
    client?: {
      userName: string | null;
      pinCode: string;
    };
  } | null;
  activeDragableIframe: {
    id: "screenSharing" | "flashCollabration" | string
    state: "hover" | "active";
  } | null;
  userLicence: UserLicence | null;
  webEmbed: WebEmbed[];
  showScreenSharing: boolean;
  showFlashCollabration: boolean;
  suggestions: {
    type: "draw"
    data: {
      name: string;
      icons: string[];
    }[]
  } |
  {
    type: "write"
    data: string[]
  } |
  null;
  stickyNotes: StickyNote[];
};

export type WebEmbed = {
  id: string;
  url: string;
  hide: boolean;
  type: "youtube" | "googleDocs" | "googleSlides" | "googleSheet" | "drawio" | "stem" | "school";
  position?: [number, number];
  creatorUserId?: string | null | undefined,
  removed?: boolean;
  zIndex: number
};

export type UserInfo = {
  id: string;
  username: string;
  nickname: string;
  roleEn: "Free" | "Standard" | "Pro" | "Guest";
  authorization: string;
  email: string;
  isFreeTrial: boolean;
  rolePeriodEnd: number;
};
export type HomeState = {
  serverConnection: string;
};

export type NormalizedZoomValue = number & { _brand: "normalizedZoom" };

export type Zoom = Readonly<{
  value: NormalizedZoomValue;
}>;

export type PointerCoords = Readonly<{
  x: number;
  y: number;
}>;

export type Gesture = {
  pointers: Map<number, PointerCoords>;
  lastCenter: { x: number; y: number } | null;
  initialDistance: number | null;
  initialScale: number | null;
};

export declare class GestureEvent extends UIEvent {
  readonly rotation: number;
  readonly scale: number;
}

// libraries
// -----------------------------------------------------------------------------
/** @deprecated legacy: do not use outside of migration paths */
export type LibraryItem_v1 = readonly NonDeleted<ImagoElement>[];
/** @deprecated legacy: do not use outside of migration paths */
type LibraryItems_v1 = readonly LibraryItem_v1[];

/** v2 library item */
export type LibraryItem = {
  id: string;
  status: "published" | "unpublished";
  elements: readonly NonDeleted<ImagoElement>[];
  /** timestamp in epoch (ms) */
  created: number;
  name?: string;
  error?: string;
};
export type LibraryItems = readonly LibraryItem[];
export type LibraryItems_anyVersion = LibraryItems | LibraryItems_v1;

export type LibraryItemsSource =
  | ((
    currentLibraryItems: LibraryItems,
  ) =>
    | Blob
    | LibraryItems_anyVersion
    | Promise<LibraryItems_anyVersion | Blob>)
  | Blob
  | LibraryItems_anyVersion
  | Promise<LibraryItems_anyVersion | Blob>;
// -----------------------------------------------------------------------------

// NOTE ready/readyPromise props are optional for host apps' sake (our own
// implem guarantees existence)
export type ImagoAPIRefValue =
  | ImagoImperativeAPI
  | {
    readyPromise?: ResolvablePromise<ImagoImperativeAPI>;
    ready?: false;
  };

export type ImagoInitialDataState = Merge<
  ImportedDataState,
  {
    libraryItems?:
    | Required<ImportedDataState>["libraryItems"]
    | Promise<Required<ImportedDataState>["libraryItems"]>;
  }
>;

export interface ImagoProps {
  onChange?: (
    elements: readonly ImagoElement[],
    appState: AppState,
    setAppState: React.Component<any, AppState>["setState"],
    files: BinaryFiles,
    deleteFiles: BinaryFiles,
  ) => void;
  initialData?:
  | ImagoInitialDataState
  | null
  | Promise<ImagoInitialDataState | null>;
  imagoRef?: ForwardRef<ImagoAPIRefValue>;
  onCollabButtonClick?: () => void;
  onGoogleDriveClick?: () => void;
  isCollaborating?: boolean;
  onPointerUpdate?: (payload: {
    pointer: { x: number; y: number };
    button: "down" | "up";
    pointersMap: Gesture["pointers"];
  }) => void;
  onPaste?: (
    data: ClipboardData,
    event: ClipboardEvent | null,
  ) => Promise<boolean> | boolean;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  renderFooter?: (isMobile: boolean, appState: AppState) => JSX.Element | null;
  langCode?: Language["code"];
  viewModeEnabled?: boolean;
  zenModeEnabled?: boolean;
  gridModeEnabled?: boolean;
  libraryReturnUrl?: string;
  theme?: Theme;
  name?: string;
  renderCustomStats?: (
    elements: readonly NonDeletedImagoElement[],
    appState: AppState,
  ) => JSX.Element;
  UIOptions?: {
    dockedSidebarBreakpoint?: number;
    canvasActions?: CanvasActions;
  };
  detectScroll?: boolean;
  handleKeyboardGlobally?: boolean;
  onLibraryChange?: (libraryItems: LibraryItems) => void | Promise<any>;
  autoFocus?: boolean;
  generateIdForFile?: (file: File | Blob) => string | Promise<string>;
  onLinkOpen?: (
    element: NonDeletedImagoElement,
    event: CustomEvent<{
      nativeEvent: MouseEvent | React.PointerEvent<HTMLCanvasElement>;
    }>,
  ) => void;
  onPointerDown?: (
    activeTool: AppState["activeTool"],
    pointerDownState: PointerDownState,
  ) => void;
  onScrollChange?: (scrollX: number, scrollY: number) => void;
  /**
   * Render function that renders custom <Sidebar /> component.
   */
  renderSidebar?: () => JSX.Element | null;
  onFilesAdd?: (files: BinaryFileData[]) => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  pageList?: string[];
  currPage?: string;
  onViewBackgroundColorChange?: (color: string) => void
}

export type SceneData = {
  elements?: ImportedDataState["elements"];
  appState?: ImportedDataState["appState"];
  collaborators?: Map<string, Collaborator>;
  commitToHistory?: boolean;
};

export enum UserIdleState {
  ACTIVE = "active",
  AWAY = "away",
  IDLE = "idle",
}

export type ExportOpts = {
  saveFileToDisk?: boolean;
  onExportToBackend?: (
    exportedElements: readonly NonDeletedImagoElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement | null,
  ) => void;
  renderCustomUI?: (
    exportedElements: readonly NonDeletedImagoElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement | null,
  ) => JSX.Element;
};

// NOTE at the moment, if action name coressponds to canvasAction prop, its
// truthiness value will determine whether the action is rendered or not
// (see manager renderAction). We also override canvasAction values in
// imago package index.tsx.
type CanvasActions = {
  changeViewBackgroundColor?: boolean;
  clearCanvas?: boolean;
  export?: false | ExportOpts;
  loadScene?: boolean;
  saveToActiveFile?: boolean;
  toggleTheme?: boolean | null;
  saveAsImage?: boolean;
};

export type AppProps = Merge<
  ImagoProps,
  {
    UIOptions: {
      canvasActions: Required<CanvasActions> & { export: ExportOpts };
      dockedSidebarBreakpoint?: number;
    };
    detectScroll: boolean;
    handleKeyboardGlobally: boolean;
    isCollaborating: boolean;
    children?: React.ReactNode;
  }
>;

/** A subset of App class properties that we need to use elsewhere
 * in the app, eg Manager. Factored out into a separate type to keep DRY. */
export type AppClassProperties = {
  props: AppProps;
  canvas: HTMLCanvasElement | null;
  focusContainer(): void;
  library: Library;
  imageCache: Map<
    FileId,
    {
      image: HTMLImageElement | Promise<HTMLImageElement>;
      mimeType: typeof ALLOWED_IMAGE_MIME_TYPES[number];
    }
  >;
  files: BinaryFiles;
  device: App["device"];
  scene: App["scene"];
};

export type PointerDownState = Readonly<{
  // The first position at which pointerDown happened
  origin: Readonly<{ x: number; y: number }>;
  // Same as "origin" but snapped to the grid, if grid is on
  originInGrid: Readonly<{ x: number; y: number }>;
  // Scrollbar checks
  scrollbars: ReturnType<typeof isOverScrollBars>;
  // The previous pointer position
  lastCoords: { x: number; y: number };
  // map of original elements data
  originalElements: Map<string, NonDeleted<ImagoElement>>;
  resize: {
    // Handle when resizing, might change during the pointer interaction
    handleType: MaybeTransformHandleType;
    // This is determined on the initial pointer down event
    isResizing: boolean;
    // This is determined on the initial pointer down event
    offset: { x: number; y: number };
    // This is determined on the initial pointer down event
    arrowDirection: "origin" | "end";
    // This is a center point of selected elements determined on the initial pointer down event (for rotation only)
    center: { x: number; y: number };
  };
  hit: {
    // The element the pointer is "hitting", is determined on the initial
    // pointer down event
    element: NonDeleted<ImagoElement> | null;
    // The elements the pointer is "hitting", is determined on the initial
    // pointer down event
    allHitElements: NonDeleted<ImagoElement>[];
    // This is determined on the initial pointer down event
    wasAddedToSelection: boolean;
    // Whether selected element(s) were duplicated, might change during the
    // pointer interaction
    hasBeenDuplicated: boolean;
    hasHitCommonBoundingBoxOfSelectedElements: boolean;
  };
  withCmdOrCtrl: boolean;
  drag: {
    // Might change during the pointer interaction
    hasOccurred: boolean;
    // Might change during the pointer interaction
    offset: { x: number; y: number } | null;
  };
  // We need to have these in the state so that we can unsubscribe them
  eventListeners: {
    // It's defined on the initial pointer down event
    onMove: null | ReturnType<typeof throttleRAF>;
    // It's defined on the initial pointer down event
    onUp: null | ((event: PointerEvent) => void);
    // It's defined on the initial pointer down event
    onKeyDown: null | ((event: KeyboardEvent) => void);
    // It's defined on the initial pointer down event
    onKeyUp: null | ((event: KeyboardEvent) => void);
  };
  boxSelection: {
    hasOccurred: boolean;
  };
  elementIdsToErase: {
    [key: ImagoElement["id"]]: {
      opacity: ImagoElement["opacity"];
      erase: boolean;
    };
  };
}>;

export type ImagoImperativeAPI = {
  updateScene: InstanceType<typeof App>["updateScene"];
  delEmbedLink: InstanceType<typeof App>["delEmbedLink"];
  updateEmbedLink: InstanceType<typeof App>["updateEmbedLink"];
  batchAddEmbedLink: InstanceType<typeof App>["batchAddEmbedLink"];
  updateLibrary: InstanceType<typeof Library>["updateLibrary"];
  resetScene: InstanceType<typeof App>["resetScene"];
  getSceneElementsIncludingDeleted: InstanceType<
    typeof App
  >["getSceneElementsIncludingDeleted"];
  history: {
    clear: InstanceType<typeof App>["resetHistory"];
  };
  scrollToContent: InstanceType<typeof App>["scrollToContent"];
  getSceneElements: InstanceType<typeof App>["getSceneElements"];
  getAppState: () => InstanceType<typeof App>["state"];
  getFiles: () => InstanceType<typeof App>["files"];
  refresh: InstanceType<typeof App>["refresh"];
  setToast: InstanceType<typeof App>["setToast"];
  addFiles: (data: BinaryFileData[]) => void;
  readyPromise: ResolvablePromise<ImagoImperativeAPI>;
  ready: true;
  id: string;
  setActiveTool: InstanceType<typeof App>["setActiveTool"];
  setCursor: InstanceType<typeof App>["setCursor"];
  resetCursor: InstanceType<typeof App>["resetCursor"];
  toggleMenu: InstanceType<typeof App>["toggleMenu"];
  escalateGoogleMeet: InstanceType<typeof App>["escalateGoogleMeet"];
};

export type Device = Readonly<{
  isSmScreen: boolean;
  isMobile: boolean;
  isTouchScreen: boolean;
  canDeviceFitSidebar: boolean;
}>;

export type UserLicence = Readonly<{
  aIImage: boolean;
  aIText: boolean;
  audioVideoWhen: string;
  basicTools: boolean;
  cloudStorage: number;
  collaborationBoard: boolean;
  exportToImageAndPDF: boolean;
  exportToSvgGoogleDriveGoogleClassroom: boolean;
  library: number;
  magicDraw: boolean;
  numberOfEditedPages: number;
  participantsOfRealTimeCollaborationPerSession: number;
  quicklyForwardToGoogleMeet: boolean;
  screenShare: boolean;
  templates: number;
  workWithGoogleDrive: boolean;
  workWithImage: boolean;
}>;

export type Page = {
  id: string;
  name: string;
  backgroundColor: string;
  gridColor: string;
};

export type StickyNote = {
  id: string;
  key: string;
  content: string;
  status: "fold" | "expand";
  background: string;
  fontWeigth: "bolder" | "normal";
  fontStyle: "italic" | "normal";
  textDecoration: "underline" | "line-through" | "none";
  position: [number, number] | null
} 