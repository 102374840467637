import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../appState";
import { LIBRARY_LIST_LIMIT } from "../../constants";
import { ImportedDataState } from "../../data/types";
import { clearElementsForLocalStorage } from "../../element";
import { ImagoElement } from "../../element/types";
import { AppState } from "../../types";
import { STORAGE_KEYS } from "../app_constants";
import { LocalData } from "./LocalData";
import { updateBrowserStateVersion } from "./tabSync";

export const importFromIndexDB = async () => {
  let savedElements = null;
  let savedState = null;

  try {
    savedElements = await LocalData.dataStateStorage.get(
      STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
    );
    savedState = await LocalData.dataStateStorage.get(
      STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
    );
  } catch (error: any) {
    console.error(error);
  }

  let elements: ImagoElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(savedElements);
    } catch (error: any) {
      console.error(error);
      // Do nothing because elements array is already empty
    }
  }

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...getDefaultAppState(),
        ...clearAppStateForLocalStorage(savedState as Partial<AppState>),
      };
    } catch (error: any) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }
  return { elements, appState };
};

export const getLibraryItemsFromStorage = async () => {
  try {
    const libraryItems: ImportedDataState["libraryItems"] =
      await LocalData.librariesStorage.getList(0, LIBRARY_LIST_LIMIT);
    // const libraryItems: ImportedDataState["libraryItems"] = JSON.parse(
    //   localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY) as string,
    // );
    return libraryItems || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getElements = async () => {
  let savedElements = null;
  try {
    savedElements = await LocalData.dataStateStorage.get(
      STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
    ); // localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
  } catch (error: any) {
    console.error(error);
  }
  let elements: ImagoElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(savedElements); // clearElementsForLocalStorage(JSON.parse(savedElements));
    } catch (error: any) {
      console.error(error);
    }
  }
  return elements;
};

export const updateStateToIndexDB = async (appState: AppState) => {
  await LocalData.dataStateStorage.save(
    STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
    clearAppStateForLocalStorage(appState),
  );
  updateBrowserStateVersion(STORAGE_KEYS.VERSION_DATA_STATE);
};

export const getElementsStorageSize = async () => {
  try {
    const elements = await LocalData.dataStateStorage.get(
      STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
    );
    //const elements = localStorage.getItem(getCurrPageFromStorage());
    const elementsSize = JSON.stringify(elements).length || 0;
    return elementsSize;
  } catch (error: any) {
    console.error(error);
    return 0;
  }
};

export const getTotalStorageSize = async () => {
  try {
    const appState = await LocalData.dataStateStorage.get(
      STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
    );
    const collab = await LocalData.dataStateStorage.get(
      STORAGE_KEYS.LOCAL_STORAGE_COLLAB,
    );
    const library = await LocalData.librariesStorage.getAll();
    const page = await LocalData.pagesStorage.getAll();

    const appStateSize = JSON.stringify(appState)?.length || 0;
    const collabSize = JSON.stringify(collab)?.length || 0;
    const librarySize = JSON.stringify(library)?.length || 0;
    const pageSize = JSON.stringify(page)?.length || 0;

    return (
      appStateSize +
      collabSize +
      librarySize +
      pageSize +
      (await getElementsStorageSize())
    );
  } catch (error: any) {
    console.error(error);
    return 0;
  }
};
