import React, { useEffect, useRef, useState } from "react";
import { AppState, Device, Point, PointerDownState, StickyNote } from "../types";
import "./PersonalBoardPanel.scss";

import { t } from "../i18n";
import { CloseIcon } from "./icons";

import { getPersonalDataFromLocalStorage, savePersonalDataToLocalStorage } from "../imago-app/data/localStorage";
import { FreeDrawType, ImagoElement, NonDeletedImagoElement, PointerType } from "../element/types";

import { setCursor, setCursorForShape, tupleToCoors, viewportCoordsToSceneCoords } from "../utils";


import { atom, useAtom } from "jotai";
import { jotaiStore } from "../jotai";
import { CheckCollabMemberInfo, personalBoardUpdateFlagAtom, viewIngCollaborMemberAtom } from "../imago-app/collab/Collab";
import { LocalData } from "../imago-app/data/LocalData";




export const CollaborPersonalBoardPanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  defaultPosition: [number, number];
}> = ({
  appState,
  setAppState,
  id,
  defaultPosition,
}) => {

    let canvasRef = useRef<HTMLCanvasElement>(null)

    let ctxRef = useRef<any>(null)
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isSyncBoardSize, setIsSyncBoardSize] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [currCollabor, setCurrCollabor] = useState<CheckCollabMemberInfo | null>(null);
    const appStateRef = useRef(appState);
    const boardRef = useRef<HTMLDivElement | null>(null);
    const personElemetsRef = useRef<ImagoElement[]>();
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;



    document.documentElement.style.setProperty('--win-width', winWidth.toString());
    document.documentElement.style.setProperty('--win-height', winHeight.toString());

    const [viewIngCollaborMember] = useAtom(viewIngCollaborMemberAtom);
    const [personalBoardUpdateFlag] = useAtom(personalBoardUpdateFlagAtom);


    const syncPersonBoardSize = (syncWidth: number, syncHeight: number) => {
      if (canvasRef.current) {
        let scaleX = canvasRef.current?.width / syncWidth;
        scaleX = Math.round(scaleX * 100) / 100;

        let scaleY = canvasRef.current?.height / syncHeight;
        scaleY = Math.round(scaleY * 100) / 100;
        ctxRef.current.scale(scaleX, scaleY);
      }
    }


    const renderData = async () => {

      if (canvasRef.current && ctxRef.current && viewIngCollaborMember) {

        ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        const localElements = await LocalData.personalBoardStorage.get(viewIngCollaborMember?.memberId)


        if (viewIngCollaborMember) {
          const boardSize = await LocalData.personalBoardStorage.getBoardSize(viewIngCollaborMember?.memberId)

          if (boardSize) {
            ctxRef.current.setTransform(1, 0, 0, 1, 0, 0);
            syncPersonBoardSize(boardSize[0], boardSize[1])
          }
        }



        localElements.forEach((element, k) => {
          resetPath();

          ctxRef.current.strokeStyle = element.strokeColor;
          ctxRef.current.lineWidth = element.strokeWidth;
          ctxRef.current.lineCap = "round";
          if (element.type === "freedraw") {
            element?.points.map((point) => {
              const x = element.x + point[0];
              const y = element.y + point[1]
              ctxRef.current.lineTo(x, y);
              ctxRef.current.stroke();
              resetPath();
              ctxRef.current.moveTo(x, y);
            })
          }

        })



      }

    }

    const resetPath = () => {
      ctxRef.current && ctxRef.current.beginPath();
    }



    useEffect(() => {
      renderData()
    }, [personalBoardUpdateFlag]);

    const handleWinMouseDown = (e: any) => {
      setIsDragging(true);
      const { clientX, clientY } = e;

      if (boardRef.current) {
        const { left, top } = boardRef.current.getBoundingClientRect();

        setPosition({
          x: clientX - left,
          y: clientY - top,
        });
      }

    };


    const handleWinMouseMove = (e: any) => {
      if (!isDragging) return;
      const { clientX, clientY } = e;
      const newLeft = clientX - position.x;
      const newTop = clientY - position.y;
      if (boardRef.current) {
        boardRef.current.style.transform = `translate(${newLeft}px, ${newTop}px)`;
      }

    };

    const handleWinMouseUp = () => {
      setIsDragging(false);
    };

    useEffect(() => {
      appStateRef.current = appState
      setCursorForShape(canvasRef.current, appStateRef.current);
    }, [appState]);

    useEffect(() => {
      initCanvas()

    }, []);

    useEffect(() => {
      setCurrCollabor(viewIngCollaborMember)
    }, [viewIngCollaborMember]);

    const closeWin = (e: any) => {
      e.stopPropagation();
      setAppState({
        openCollaborPersonalBoard: false
      });
    };


    const resizeCanvas = () => {
      if (canvasRef.current) {
        canvasRef.current.width = canvasRef.current.offsetWidth
        canvasRef.current.height = canvasRef.current.offsetHeight
        renderData()
      }
    }



    const initCanvas = async () => {
      if (canvasRef.current) {
        canvasRef.current.width = canvasRef.current.offsetWidth
        canvasRef.current.height = canvasRef.current.offsetHeight
        ctxRef.current = canvasRef.current.getContext("2d");
        personElemetsRef.current = await getPersonalDataFromLocalStorage()
        renderData()
        window.addEventListener("resize", resizeCanvas);

        return () => {
          if (canvasRef.current) {
            window.removeEventListener("resize", resizeCanvas);

          }

        };

      }

    }

    return (
      <div
        className="personal-board"
        ref={boardRef}
        onTouchMove={(event) => {
          handleWinMouseMove(event.touches[0])
        }}
        onMouseMove={handleWinMouseMove}
        onTouchEnd={handleWinMouseUp}
        onMouseUp={handleWinMouseUp}
        onMouseLeave={handleWinMouseUp}
      >
        <div className="header" onTouchStart={(event) => {
          handleWinMouseDown(event.touches[0])
        }} onMouseDown={handleWinMouseDown}>
          <div className="title">
            {currCollabor?.memberName}'s {t(`toolBar.personalBoard`)}
          </div>
          <div className="close">
            <a href={undefined} onClick={(e) => closeWin(e)}>
              {CloseIcon}
            </a>
          </div>
        </div>
        <div
          className="content"
        >
          <canvas style={{ width: "100%", height: "100%" }} ref={canvasRef} id="drawingCanvas"></canvas>

        </div>

      </div>
    );
  };
