import React, { useState, useEffect } from "react";
import "./Resizable.scss";
import { throttleRAF } from "../utils";
export const Direction = {
  Top: "top",
  TopLeft: "topLeft",
  TopRight: "topRight",
  Right: "right",
  Bottom: "bottom",
  BottomLeft: "bottomLeft",
  BottomRight: "bottomRight",
  Left: "left",
};
const Resizer = ({
  onResize,
}: {
  onResize: (direction: string, movementX: number, movementY: number) => void;
}) => {
  const [direction, setDirection] = useState("");
  const [PointerDown, setPointerDown] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handlePointerMove = throttleRAF((e: any) => {
      if (!direction) {
        return;
      }
      // var movementX=0,movementY=0;
      // movementX = e.pageX - offsetX;
      // movementY = e.pageY - offsetY;

      // setOffsetX(e.pageX);
      // setOffsetY(e.pageY);
      const { movementX, movementY } = e;
      const ratio = window.devicePixelRatio;

      onResize(direction, movementX / ratio, movementY / ratio);
      e.stopPropagation();
      e.preventDefault();
    });

    if (PointerDown) {
      window.addEventListener("pointermove", handlePointerMove);
    }

    return () => {
      window.removeEventListener("pointermove", handlePointerMove);
    };
  }, [PointerDown, direction, onResize, offsetX, offsetY]);

  useEffect(() => {
    const handlePointerUp = () => {
      setPointerDown(false);
    };

    window.addEventListener("pointerup", handlePointerUp);
    return () => {
      window.removeEventListener("pointerup", handlePointerUp);
    };
  }, []);

  const handlePointerDown = (event: any, direction: string) => {
    // setOffsetX(event.pageX);
    // setOffsetY(event.pageY);
    setDirection(direction);
    setPointerDown(true);
  };

  return (
    <>
      <div
        className="resize-top-left"
        onPointerDown={(e: any) => {
          handlePointerDown(e, Direction.TopLeft);
        }}
      ></div>

      <div
        className="resize-top"
        onPointerDown={(e: any) => {
          handlePointerDown(e, Direction.Top);
        }}
      ></div>

      <div
        className="resize-top-right"
        onPointerDown={(e: any) => handlePointerDown(e, Direction.TopRight)}
      ></div>

      <div
        className="resize-right"
        onPointerDown={(e: any) => handlePointerDown(e, Direction.Right)}
      ></div>

      <div
        className="resize-right-bottom"
        onPointerDown={(e: any) => handlePointerDown(e, Direction.BottomRight)}
      ></div>

      <div
        className="resize-bottom"
        onPointerDown={(e: any) => handlePointerDown(e, Direction.Bottom)}
      ></div>

      <div
        className="resize-bottom-left"
        onPointerDown={(e: any) => handlePointerDown(e, Direction.BottomLeft)}
      ></div>

      <div
        className="resize-left"
        onPointerDown={(e: any) => handlePointerDown(e, Direction.Left)}
      ></div>
    </>
  );
};

export default Resizer;
