import { QRCode, Spin } from "antd"
import { useEffect, useState } from "react"
import { LoadIcon } from "./icons";
import { API_URL, MIME_TYPES } from "../constants";
import { nanoid } from "nanoid";
import { AppState, BinaryFileMetadata } from "../types";
import { LocalData } from "../imago-app/data/LocalData";
import { exportToBlob } from "../packages/utils";
import { clearElementsForExport } from "../element";
import { isInitializedImageElement } from "../element/typeChecks";
import { FileId } from "../element/types";
import { getDataURL } from "../data/blob";
import { compressData } from "../data/encode";
import { FILE_ENCRYPTION_KEY } from "../imago-app/app_constants";


export const ScanDownloadFile:React.FC<{appState:AppState}> = ({appState})=>{
    const [qrocdeValue,setQRCodeValue] = useState<string>("");
    const {userInfo} = appState;
    const fileId = nanoid();
    useEffect(()=>{
        LocalData.pagesStorage.getAll().then(async data=>{
            const images = data && Object.keys(data).map(async (k) => {
                let files = {}
                const fileIds = clearElementsForExport(data[k])?.reduce((acc, element) => {
                    if (isInitializedImageElement(element)) {
                      return acc.concat(element.fileId);
                    }
                    return acc;
                  }, [] as FileId[]) || [];

                  if (fileIds.length) {
                    const { loadedFiles, erroredFiles } = await LocalData.fileStorage.getFiles(fileIds);
                    if (loadedFiles.length) {
                      const filesMap = loadedFiles.reduce((acc, fileData) => {
                        acc.set(fileData.id, fileData);
                        return acc;
                      }, new Map());
                      files = { ...files, ...Object.fromEntries(filesMap) };
                    }
                  }
                  const blob =  await exportToBlob({
                    appState,
                    mimeType:MIME_TYPES.png,
                    elements:data[k],
                    files
                 })
                 return await getDataURL(blob);
            })
            if(!images)return;
            const buffer = new TextEncoder().encode((await Promise.all(images) )?.join("|"));
     
            const encodedFile = await compressData<BinaryFileMetadata>(buffer, {
                encryptionKey:FILE_ENCRYPTION_KEY,
                metadata: {
                  id:fileId as FileId,
                  mimeType: MIME_TYPES.binary,
                  created: Date.now(),
                  lastRetrieved: Date.now(),
                },
            });

            await fetch(`${API_URL.saveDownFile}/${fileId}`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${userInfo?.authorization}`,
                },
                body:encodedFile
            });
            console.log(`${API_URL.host}/downFile?id=${fileId}`)
            setQRCodeValue(`${API_URL.host}/downFile?id=${fileId}`)
        })
          
    },[])
    
    return !qrocdeValue?<Spin />:<QRCode value={qrocdeValue}/>

}
