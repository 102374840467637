import React from "react";
import "./Background.scss"
import { Dialog } from "./Dialog";
import { ColorPicker } from "antd";
import clsx from "clsx";
import { AppState } from "../types";
import { Sketch } from '@uiw/react-color';

export const PenColorDialog: React.FC<{
  defaultBackgroundColor: string;
  onClose?: () => void;
  setAppState: React.Component<any, AppState>["setState"];
  onPenColorChange?: (color: string) => void;
}> = ({ defaultBackgroundColor, onClose, setAppState, onPenColorChange }) => {
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  const colors = [
    "#000000",
    "#FFFFFF",
    "#223A5E",
    "#EDF1FF",
    "#6B5876",
    "#EFE1CE",
    "#FFD3F8",
    "#EBF7E3",
  ];
  const handelChange = (color: string) => {

    setAppState({ currentItemStrokeColor: color });
    onPenColorChange?.(color)
  }
  return <Dialog title="Pen Colour" onCloseRequest={handleClose} width={300}>
    <div className="set-background">

      {
        colors.map(c => {
          return <div className={clsx("color-point", { "color-point-white": c == "#FFFFFF" })}>
            <button onClick={() => { handelChange(c) }} style={{ background: c }}></button>
          </div>
        })
      }
    </div>
    <div className="custom-color">
      <span>Colour Picker</span>
      <Sketch onChange={(value) => {
        handelChange(value.hex)
      }} style={{ width: "100%" }} />
      {/* <ColorPicker onChangeComplete={(value)=>{
        handelChange("#"+value.toHex())
      }} defaultValue={defaultBackgroundColor} style={{width:"100%"}} showText={(color) => <span>Custom Colour ({color.toHexString()})</span>} /> */}
    </div>
  </Dialog>
}