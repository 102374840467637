import clsx from "clsx";
import "./Menu.scss";

interface MenuProps {
  icon: JSX.Element;
  onClick: () => void;
  label: string;
  dataTestId: string;
  shortcut?: string;
  isCollaborating?: boolean;
  buttonClass?:string;
  showLabel?:boolean;
}
const MenuItem = ({
  icon,
  onClick,
  label,
  dataTestId,
  shortcut,
  isCollaborating,
  buttonClass = "",
  showLabel = true
}: MenuProps) => {
  return (
    <button
      className={clsx("menu-item",buttonClass, { "active-collab": isCollaborating })}
      key={dataTestId}
      aria-label={label}
      onClick={onClick}
      data-testid={dataTestId}
      title={label}
      type="button"
    >
      <div className="menu-item__icon">{icon}</div>
      {showLabel && <div className="menu-item__text">{label}</div>}
      {shortcut && <div className="menu-item__shortcut">{shortcut}</div>}
    </button>
  );
};

export default MenuItem;
