import React from "react";
import { ActionManager } from "../actions/manager";
import { SHAPES } from "../shapes";
import { AppState } from "../types";
import {
  BottomColorPickerAction,
  BottomExtraActions,
  BottomMoreActions,
  GroupShapesSwitcher,
  FileSaveGroupActions,
} from "./Actions";
import { ToolButton, ToolFlashCollab } from "./ToolButton";
import { TemplateIcon, createStringIcon } from "./icons";
import { isGoogleMeetAddonAtom } from "./App";
import { useAtom } from "jotai";
import { TemplateSelector } from "./TemplateSelector";
import CollabButton from "./CollabButton";
import { IconApplyAuth, IconCollabration } from "./newIcons";
import { isCollabtionHost } from "../imago-app/data/localStorage";
import { collabAPIAtom } from "../imago-app/collab/Collab";
import { t } from "../i18n";

type ToolbarProps = {
  appState: AppState;
  actionManager: ActionManager;
  setAppState: React.Component<any, AppState>["setState"];
  onCollabButtonClick?: () => void;
  canvas: HTMLCanvasElement | null;
  isCollaborating: boolean;
  onImageAction: (data: {
    insertOnCanvasDirectly: boolean;
    callback: () => void;
  }) => void;
  activeRoomId?: string;
  onFlashCollabClick?: () => void;
  isFlashCollabing?: boolean;
  toolbarSetting: any[];
  loadLocalLibToCanvas?: (type: any) => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  isMobile?: boolean;
  addFileToCanvas: (file: File) => Promise<void>;
};
export const ToolbarApply: React.FC<ToolbarProps> = ({
  appState,
  canvas,
  setAppState,
  onImageAction,
  isCollaborating,
  onCollabButtonClick,
  activeRoomId,
  isFlashCollabing,
  onFlashCollabClick,
  toolbarSetting,
  actionManager,
  loadLocalLibToCanvas,
  operaPage,
  isMobile,
  addFileToCanvas,
}) => {
  const [isGoogleMeetAddon, _] = useAtom(isGoogleMeetAddonAtom);
  const [collabAPI] = useAtom(collabAPIAtom);

  return (
    <>
      {toolbarSetting.map((group, index) => {
        return (
          <React.Fragment key={index}>
            {group.map((items: any, groupi: number) => {
              if (
                items.every(
                  (item: any) =>
                    [
                      "page-list",
                      "library",
                      "image",
                      "googleDrive",
                      "googleSearch",
                    ].indexOf(item.name) > -1,
                )
              ) {
                return (
                  <BottomMoreActions
                    key={"BottomMoreActions"}
                    renderAction={actionManager.renderAction}
                    appState={appState}
                    setAppState={setAppState}
                    data={{
                      isCollaborating,
                      onClick: onCollabButtonClick,
                    }}
                    actions={items.map((item: any) => {
                      return {
                        ...item,
                        visible: item.default,
                        icon: item.icon && createStringIcon(item.icon),
                      };
                    })}
                  />
                );
              } else if (
                items.every(
                  (item: any) =>
                    ["gusture", "zoomin", "zoomout", "undo", "redo"].indexOf(
                      item.name,
                    ) > -1,
                )
              ) {
                return (
                  <BottomExtraActions
                    key={"BottomExtraActions"}
                    renderAction={actionManager.renderAction}
                    appState={appState}
                    setAppState={setAppState}
                    actions={items.map((item: any) => {
                      return {
                        ...item,
                        visible: item.default,
                        icon: item.icon && createStringIcon(item.icon),
                      };
                    })}
                    canvas={canvas}
                  />
                );
              } else if (isCollaborating && items.every(
                (item: any) =>
                  [
                    "audio-call"
                  ].indexOf(item.name) > -1,
              )) {
                const item = items[0];
                return <div style={{ position: "relative" }}>
                  {appState.waitCheckMemberCount > 0 && isCollabtionHost() && (<div style={{ position: "absolute", height: "20px", width: "20px", top: "-5px", right: "-5px", background: "red", borderRadius: "10px", zIndex: "100", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", textAlign: "center" }}>{appState.waitCheckMemberCount}</div>)}
                  <ToolButton
                    type="button"
                    aria-label="collaborators"
                    onClick={() => {
                      setAppState({ openDialog: "collaborators" });
                    }
                    }


                    key={item.name}
                    icon={IconCollabration}
                  />
                </div>
              }
              return items.map((item: any, i: number) => {
                if (item.name === "stroke") {
                  return (
                    <ToolButton
                      type="button"
                      aria-label="apply-draw"
                      onClick={() => {
                        if (confirm(t("labels.request_join_confirm.apply_draw_confirm_tip"))) {
                          appState.currCollabHost && collabAPI?.applyForCollabAuth("draw", 0)
                          alert(t("labels.request_join_confirm.apply_draw_success_tip"))
                        }
                      }
                      }
                      key="apply-draw"
                      icon={IconApplyAuth}
                    />
                  );
                }
              });
            })}{" "}
            {!isMobile && toolbarSetting.length - 1 > index ? (
              <div className="App-toolbar__divider"></div>
            ) : null}{" "}
          </React.Fragment>
        );
      })}
    </>
  );
};