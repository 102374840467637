import React, { useEffect, useRef, useState } from "react";
import { AppState, Device, Point, PointerDownState, StickyNote } from "../types";
import "./PersonalBoardPanel.scss";
import { IconColors, IconTrash, IconZoomIn, IconZoomOut } from "./newIcons";
import { nanoid } from "nanoid";
import { t } from "../i18n";
import { CloseIcon } from "./icons";
import { point } from "../ga";
import { distance2d, getGridPoint } from "../math";
import { API_URL, CURSOR_TYPE, LINE_CONFIRM_THRESHOLD } from "../constants";
import { getPersonalDataFromLocalStorage, savePersonalDataToLocalStorage } from "../imago-app/data/localStorage";
import { FreeDrawType, ImagoElement, NonDeletedImagoElement, PointerType } from "../element/types";
import { deepCopyElement, newFreeDrawElement } from "../element/newElement";
import { mutateElement } from "../element/mutateElement";
import { dragSelectedElements, getCommonBounds, getCursorForResizingElement, getElementWithTransformHandleType, getNonDeletedElements, getResizeArrowDirection, getResizeOffsetXY, getTransformHandleTypeFromCoords, hitTest, isHittingElementBoundingBoxWithoutHittingElement } from "../element";
import { setCursor, setCursorForShape, tupleToCoors, viewportCoordsToSceneCoords } from "../utils";
import { getSelectedElements, isOverScrollBars } from "../scene";
import { KEYS } from "../keys";
import History from "../history";
import { isLinearElement } from "../element/typeChecks";
import { LinearElementEditor } from "../element/linearElementEditor";
import { editGroupForSelectedElement, isElementInGroup, selectGroupsForSelectedElements } from "../groups";
import { isPointHittingLinkIcon } from "../element/Hyperlink";
import { atom } from "jotai";
import { jotaiStore } from "../jotai";
import { SendPeronalBoardMemberParam } from "../imago-app/collab/Collab";
import { LocalData } from "../imago-app/data/LocalData";
import { getCurrentUri } from "@azure/msal-browser/dist/utils/BrowserUtils";




export const PersonalBoardPanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  syncPersonalBoardElements: (elements: readonly ImagoElement[], memberParam: SendPeronalBoardMemberParam, width: number, height: number) => void;
  id: string;
  defaultPosition: [number, number];
}> = ({
  appState,
  setAppState,
  syncPersonalBoardElements,
  id,
  defaultPosition,
}) => {

    const ref = useRef<HTMLDivElement | null>(null);
    let canvasRef = useRef<HTMLCanvasElement>(null)
    const pointerDownStateRef = useRef<PointerDownState>();
    let ctx: any = null
    let isDrawing = false
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const canDragRef = useRef<boolean>(false);
    const appStateRef = useRef(appState);
    const boardRef = useRef<HTMLDivElement | null>(null);
    let currDrawingElement: ImagoElement | null = null;
    const personElemetsRef = useRef<ImagoElement[]>();
    let history: History = new History()

    let width = 0;
    let heiht = 0;

    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

    document.documentElement.style.setProperty('--win-width', winWidth.toString());
    document.documentElement.style.setProperty('--win-height', winHeight.toString());


    let memmberInfo: SendPeronalBoardMemberParam = {
      hostClientId: "",
      hostMemberId: "",
      roomId: "",
      memberId: ""
    };

    if (appState.currCollabHost) {
      if (appState.currCollabHost.clientId) {
        memmberInfo.hostClientId = appState.currCollabHost.clientId
      }
      if (appState.currCollabHost.userId) {
        memmberInfo.hostMemberId = appState.currCollabHost.userId
      }
      if (appState.currCollabHost.roomId) {
        memmberInfo.roomId = appState.currCollabHost.roomId
      }

    }

    if (appState.userInfo) {
      if (appState.userInfo.id) {
        memmberInfo.memberId = appState.userInfo.id
      }
    }

    const newElement = (x: number, y: number) => {
      if (appStateRef.current.activeTool.type === "freedraw") {
        const params = {
          type: appStateRef.current.activeTool.type,
          x: x,
          y: y,
          strokeColor: appStateRef.current.currentItemStrokeColor,
          backgroundColor: appStateRef.current.currentItemBackgroundColor,
          fillStyle: appStateRef.current.currentItemFillStyle,
          strokeWidth: appStateRef.current.currentItemStrokeWidth,
          strokeStyle: appStateRef.current.currentItemStrokeStyle,
          roughness: appStateRef.current.currentItemRoughness,
          opacity: appStateRef.current.currentItemOpacity,
          strokeSharpness: appStateRef.current.currentItemLinearStrokeSharpness,
          simulatePressure: false, // event.pressure === 0.5,
          locked: false,
        };
        currDrawingElement = newFreeDrawElement(params)
        mutateElement(currDrawingElement, {
          points: [...currDrawingElement.points, [0, 0]],
        });
        drawFree(x, y);
      }

    }

    const renderData = async () => {
      if (personElemetsRef.current && canvasRef.current) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        personElemetsRef.current.forEach((element, k) => {
          resetPath();
          ctx.strokeStyle = element.strokeColor;
          ctx.lineWidth = element.strokeWidth;
          ctx.lineCap = "round";
          if (element.type === "freedraw") {
            element?.points.map((point) => {
              const x = element.x + point[0];
              const y = element.y + point[1]
              ctx.lineTo(x, y);
              ctx.stroke();
              resetPath();
              ctx.moveTo(x, y);
            })
          }

        })

      }

    }

    const resetPath = () => {
      ctx && ctx.beginPath();
    }


    const isHittingCommonBoundingBoxOfSelectedElements = (
      point: Readonly<{ x: number; y: number }>,
      selectedElements: readonly ImagoElement[],
    ): boolean => {
      if (selectedElements.length < 2) {
        return false;
      }

      // How many pixels off the shape boundary we still consider a hit
      const threshold = 10 / appStateRef.current.zoom.value;
      const [x1, y1, x2, y2] = getCommonBounds(selectedElements);
      return (
        point.x > x1 - threshold &&
        point.x < x2 + threshold &&
        point.y > y1 - threshold &&
        point.y < y2 + threshold
      );
    }

    const getElementLinkAtPosition = (
      scenePointer: Readonly<{ x: number; y: number }>,
      hitElement: NonDeletedImagoElement | null,
    ): ImagoElement | undefined => {
      // Reversing so we traverse the elements in decreasing order
      // of z-index
      let elements: readonly NonDeletedImagoElement[] = []
      if (personElemetsRef.current) {
        elements = getNonDeletedElements(personElemetsRef.current)
      }
      let hitElementIndex = Infinity;

      return elements.find((element, index) => {
        if (hitElement && element.id === hitElement.id) {
          hitElementIndex = index;
        }
        return (
          element.link &&
          index <= hitElementIndex &&
          isPointHittingLinkIcon(
            element,
            appStateRef.current,
            [scenePointer.x, scenePointer.y],
            false,
          )
        );
      });
    };


    const isASelectedElement = (hitElement: ImagoElement | null): boolean => {
      return hitElement != null && appStateRef.current.selectedElementIds[hitElement.id];
    }

    const clearSelection = (hitElement: ImagoElement | null): void => {
      setAppState((prevState) => ({
        selectedElementIds: {},
        selectedGroupIds: {},
        // Continue editing the same group if the user selected a different
        // element from it
        editingGroupId:
          prevState.editingGroupId &&
            hitElement != null &&
            isElementInGroup(hitElement, prevState.editingGroupId)
            ? prevState.editingGroupId
            : null,
      }));
      setAppState({
        selectedElementIds: {},
        previousSelectedElementIds: appStateRef.current.selectedElementIds,
      });
    }


    const initialPointerDownState = (
      event: React.PointerEvent<HTMLCanvasElement> | MouseEvent | TouchEvent,
    ): PointerDownState => {

      let touchPoint = { x: 0, y: 0 }
      if ('touches' in event) {
        touchPoint = getOffSetPoint(event.touches[0].clientX, event.touches[0].clientY)
      } else {
        touchPoint = getOffSetPoint(event.clientX, event.clientY)
      }

      const origin = { x: touchPoint.x, y: touchPoint.y }

      let selectNonDeletedElements: readonly NonDeletedImagoElement[] = []
      if (personElemetsRef.current) {
        selectNonDeletedElements = getNonDeletedElements(personElemetsRef.current)
      }


      const selectedElements = getSelectedElements(
        selectNonDeletedElements,
        appStateRef.current,
      );
      const [minX, minY, maxX, maxY] = getCommonBounds(selectedElements);

      return {
        origin,
        withCmdOrCtrl: event[KEYS.CTRL_OR_CMD],
        originInGrid: tupleToCoors(
          getGridPoint(origin.x, origin.y, appStateRef.current.gridSize),
        ),
        scrollbars: isOverScrollBars(
          { horizontal: null, vertical: null },
          touchPoint.x - appStateRef.current.offsetLeft,
          touchPoint.y - appStateRef.current.offsetTop,
        ),
        // we need to duplicate because we'll be updating this state
        lastCoords: { ...origin },
        originalElements: selectNonDeletedElements
          .reduce((acc, element) => {
            acc.set(element.id, deepCopyElement(element));
            return acc;
          }, new Map() as PointerDownState["originalElements"]),
        resize: {
          handleType: false,
          isResizing: false,
          offset: { x: 0, y: 0 },
          arrowDirection: "origin",
          center: { x: (maxX + minX) / 2, y: (maxY + minY) / 2 },
        },
        hit: {
          element: null,
          allHitElements: [],
          wasAddedToSelection: false,
          hasBeenDuplicated: false,
          hasHitCommonBoundingBoxOfSelectedElements:
            isHittingCommonBoundingBoxOfSelectedElements(
              origin,
              selectedElements,
            ),
        },
        drag: {
          hasOccurred: false,
          offset: null,
        },
        eventListeners: {
          onMove: null,
          onUp: null,
          onKeyUp: null,
          onKeyDown: null,
        },
        boxSelection: {
          hasOccurred: false,
        },
        elementIdsToErase: {},
      };

    }

    const handleSelectionOnPointerDown = (
      event: React.PointerEvent<HTMLCanvasElement> | MouseEvent | TouchEvent,
      pointerDownState: PointerDownState,
    ): boolean => {

      let pointerType: PointerType
      if (event instanceof MouseEvent) {
        pointerType = "mouse"
      } else {
        pointerType = "touch"
      }

      if (appStateRef.current.activeTool.type === "selection") {
        setAppState({ openMenu: null });


        let elements: readonly NonDeletedImagoElement[] = []
        if (personElemetsRef.current) {
          elements = getNonDeletedElements(personElemetsRef.current)
        }
        const selectedElements = getSelectedElements(elements, appStateRef.current);
        if (selectedElements.length === 1 && !appStateRef.current.editingLinearElement) {
          const elementWithTransformHandleType =
            getElementWithTransformHandleType(
              elements,
              appStateRef.current,
              pointerDownState.origin.x,
              pointerDownState.origin.y,
              appStateRef.current.zoom,
              pointerType,
            );
          if (elementWithTransformHandleType != null) {
            setAppState({
              resizingElement: elementWithTransformHandleType.element,
            });
            pointerDownState.resize.handleType =
              elementWithTransformHandleType.transformHandleType;
          }
        } else if (selectedElements.length > 1) {
          pointerDownState.resize.handleType = getTransformHandleTypeFromCoords(
            getCommonBounds(selectedElements),
            pointerDownState.origin.x,
            pointerDownState.origin.y,
            appStateRef.current.zoom,
            pointerType,
          );
        }
        if (pointerDownState.resize.handleType) {
          setCursor(
            canvasRef.current,
            getCursorForResizingElement({
              transformHandleType: pointerDownState.resize.handleType,
            }),
          );
          pointerDownState.resize.isResizing = true;
          pointerDownState.resize.offset = tupleToCoors(
            getResizeOffsetXY(
              pointerDownState.resize.handleType,
              selectedElements,
              pointerDownState.origin.x,
              pointerDownState.origin.y,
            ),
          );
          if (
            selectedElements.length === 1 &&
            isLinearElement(selectedElements[0]) &&
            selectedElements[0].points.length === 2
          ) {
            pointerDownState.resize.arrowDirection = getResizeArrowDirection(
              pointerDownState.resize.handleType,
              selectedElements[0],
            );
          }
        } else {
          if (appStateRef.current.selectedLinearElement) {
            const linearElementEditor =
              appStateRef.current.editingLinearElement || appStateRef.current.selectedLinearElement;
            const ret = LinearElementEditor.handlePointerDown(
              event,
              appStateRef.current,
              history,
              pointerDownState.origin,
              linearElementEditor,
            );
            if (ret.hitElement) {
              pointerDownState.hit.element = ret.hitElement;
            }
            if (ret.linearElementEditor) {
              setAppState({ selectedLinearElement: ret.linearElementEditor });

              if (appStateRef.current.editingLinearElement) {
                setAppState({ editingLinearElement: ret.linearElementEditor });
              }
            }
            if (ret.didAddPoint && !ret.isMidPoint) {
              return true;
            }
          }
          // hitElement may already be set above, so check first
          pointerDownState.hit.element =
            pointerDownState.hit.element ??
            getElementAtPosition(
              pointerDownState.origin.x,
              pointerDownState.origin.y,
            );



          if (pointerDownState.hit.element) {
            // Early return if pointer is hitting link icon
            const hitLinkElement = getElementLinkAtPosition(
              {
                x: pointerDownState.origin.x,
                y: pointerDownState.origin.y,
              },
              pointerDownState.hit.element,
            );
            if (hitLinkElement) {
              return false;
            }
          }

          // For overlapped elements one position may hit
          // multiple elements
          pointerDownState.hit.allHitElements = getElementsAtPosition(
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          );

          const hitElement = pointerDownState.hit.element;
          const someHitElementIsSelected =
            pointerDownState.hit.allHitElements.some((element) =>
              isASelectedElement(element),
            );
          if (
            (hitElement === null || !someHitElementIsSelected) &&
            !event.shiftKey &&
            !pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements
          ) {
            clearSelection(hitElement);
          }

          if (appStateRef.current.editingLinearElement) {
            setAppState({
              selectedElementIds: {
                [appStateRef.current.editingLinearElement.elementId]: true,
              },
            });
            // If we click on something
          } else if (hitElement != null) {
            // on CMD/CTRL, drill down to hit element regardless of groups etc.
            if (event[KEYS.CTRL_OR_CMD]) {
              if (!appStateRef.current.selectedElementIds[hitElement.id]) {
                pointerDownState.hit.wasAddedToSelection = true;
              }
              setAppState((prevState) => ({
                ...editGroupForSelectedElement(prevState, hitElement),
                previousSelectedElementIds: appStateRef.current.selectedElementIds,
              }));
              // mark as not completely handled so as to allow dragging etc.
              return false;
            }

            // deselect if item is selected
            // if shift is not clicked, this will always return true
            // otherwise, it will trigger selection based on current
            // state of the box
            if (!appStateRef.current.selectedElementIds[hitElement.id]) {
              // if we are currently editing a group, exiting editing mode and deselect the group.
              if (
                appStateRef.current.editingGroupId &&
                !isElementInGroup(hitElement, appStateRef.current.editingGroupId)
              ) {
                setAppState({
                  selectedElementIds: {},
                  selectedGroupIds: {},
                  editingGroupId: null,
                });
              }

              // Add hit element to selection. At this point if we're not holding
              // SHIFT the previously selected element(s) were deselected above
              // (make sure you use setState updater to use latest state)
              if (
                !someHitElementIsSelected &&
                !pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements
              ) {
                setAppState((prevState) => {
                  return selectGroupsForSelectedElements(
                    {
                      ...prevState,
                      selectedElementIds: {
                        ...prevState.selectedElementIds,
                        [hitElement.id]: true,
                      },
                      showHyperlinkPopup: hitElement.link ? "info" : false,
                    },
                    elements,
                  );
                });
                pointerDownState.hit.wasAddedToSelection = true;
              }
            }
          }

          setAppState({
            previousSelectedElementIds: appStateRef.current.selectedElementIds,
          });
        }
      }
      return false;
    }

    const handleCanvasDown = (event: React.PointerEvent<HTMLCanvasElement> | MouseEvent | TouchEvent) => {
      event.preventDefault()
      isDrawing = true
      resetPath();
      let touchPoint = { x: 0, y: 0 }
      if ('touches' in event) {
        touchPoint = getOffSetPoint(event.touches[0].clientX, event.touches[0].clientY)
      } else {
        touchPoint = getOffSetPoint(event.clientX, event.clientY)
      }

      const hitElement = getElementAtPosition(
        touchPoint.x,
        touchPoint.y,
      );

      if (appStateRef.current.activeTool.type === "selection") {

        if (hitElement) {
          canDragRef.current = true
        }
      } else if (appStateRef.current.activeTool.type == "freedraw") {
        newElement(touchPoint.x, touchPoint.y);
      } else if (appStateRef.current.activeTool.type == "eraser") {
        if (hitElement) {
          mutateElement(hitElement, {
            isDeleted: true,
          });

          const currentElements = personElemetsRef.current;
          if (currentElements) {
            const updatedElements = currentElements.filter(element => !(hitElement.id === element.id));
            personElemetsRef.current = updatedElements;
          }
          saveData();
          renderData()
          if (memmberInfo.hostClientId != "") {
            const syncElement: ImagoElement[] = [hitElement]
            syncPersonalBoardElements(syncElement, memmberInfo, width, heiht)
          }
        }
      }

      const pointerDownState = initialPointerDownState(event);

      pointerDownStateRef.current = pointerDownState

      if (handleSelectionOnPointerDown(event, pointerDownStateRef.current)) {
        return;
      }


    };


    const handleCanvasMove = (event: MouseEvent | TouchEvent) => {
      event.preventDefault()
      let touchPoint = {
        x: 0,
        y: 0
      }
      if (event instanceof MouseEvent) {
        touchPoint = getOffSetPoint(event.clientX, event.clientY)
      } else {
        touchPoint = getOffSetPoint(event.touches[0].clientX, event.touches[0].clientY)
      }

      if (appStateRef.current.activeTool.type === "selection") {
        const hitElement = getElementAtPosition(
          touchPoint.x,
          touchPoint.y,
        );
        if (hitElement) {
          currDrawingElement = hitElement
          setCursor(canvasRef.current, CURSOR_TYPE.MOVE);
        } else {
          currDrawingElement = null
          setCursor(canvasRef.current, CURSOR_TYPE.AUTO);
        }

        if (canDragRef.current) {
          if (pointerDownStateRef.current) {

            let selectNonDeletedElements: readonly NonDeletedImagoElement[] = []
            if (personElemetsRef.current) {
              selectNonDeletedElements = getNonDeletedElements(personElemetsRef.current)
            }

            const selectedElements = getSelectedElements(
              selectNonDeletedElements,
              appStateRef.current,
            );

            if (selectedElements.length > 0) {

              const offsetX = touchPoint.x - pointerDownStateRef.current.lastCoords.x
              const offsetY = touchPoint.y - pointerDownStateRef.current.lastCoords.y

              const currentElements = personElemetsRef.current;

              if (currentElements) {
                selectedElements.forEach(element => {
                  const index = currentElements.findIndex(elem => elem.id === element.id);
                  if (index !== -1) {
                    currentElements[index] = {
                      ...currentElements[index],
                      x: element.x + offsetX,
                      y: element.y + offsetY
                    };
                  }
                });
                personElemetsRef.current = [...currentElements];

              };

              pointerDownStateRef.current.lastCoords.x = touchPoint.x;
              pointerDownStateRef.current.lastCoords.y = touchPoint.y;

              renderData()
            }


          }
        }




      } else if (appStateRef.current.activeTool.type === "freedraw") {
        drawFree(touchPoint.x, touchPoint.y)
      }


    };

    const getElementsAtPosition1 = (
      elements: readonly ImagoElement[],
      isAtPositionFn: (element: ImagoElement) => boolean,
    ) => {
      // The parameter elements comes ordered from lower z-index to higher.
      // We want to preserve that order on the returned array.
      return elements.filter(
        (element) => !element.isDeleted && isAtPositionFn(element),
      );
    };


    const getElementsAtPosition = (
      x: number,
      y: number,
      includeBoundTextElement: boolean = false,
      includeLockedElements: boolean = false,
    ): ImagoElement[] => {
      if (personElemetsRef.current) {
        const elementsAtPosition = getElementsAtPosition1(personElemetsRef.current, (element) =>
          hitTest(element, appStateRef.current, x, y),
        );
        return elementsAtPosition;
      }

      return [];
    }

    const getElementAtPosition = (
      x: number,
      y: number,
      opts?: {
        /** if true, returns the first selected element (with highest z-index)
          of all hit elements */
        preferSelected?: boolean;
        includeBoundTextElement?: boolean;
        includeLockedElements?: boolean;
      },
    ): ImagoElement | null => {
      const allHitElements = getElementsAtPosition(
        x,
        y,
        opts?.includeBoundTextElement,
        opts?.includeLockedElements,
      );
      if (allHitElements.length > 1) {
        if (opts?.preferSelected) {
          for (let index = allHitElements.length - 1; index > -1; index--) {
            if (appStateRef.current.selectedElementIds[allHitElements[index].id]) {
              return allHitElements[index];
            }
          }
        }
        const elementWithHighestZIndex =
          allHitElements[allHitElements.length - 1];
        // If we're hitting element with highest z-index only on its bounding box
        // while also hitting other element figure, the latter should be considered.
        return isHittingElementBoundingBoxWithoutHittingElement(
          elementWithHighestZIndex,
          appStateRef.current,
          x,
          y,
        )
          ? allHitElements[allHitElements.length - 2]
          : elementWithHighestZIndex;
      }
      if (allHitElements.length === 1) {
        return allHitElements[0];
      }
      return null;
    }


    const saveData = async () => {
      // personElemetsRef.current && savePersonalDataToLocalStorage(personElemetsRef.current)

      personElemetsRef.current && LocalData.personalBoardStorage.save(memmberInfo.memberId, personElemetsRef.current);

    }

    const handleCanvasUp = (event: React.PointerEvent<HTMLCanvasElement> | MouseEvent | TouchEvent) => {
      let touchPoint = {
        x: 0,
        y: 0
      }
      const hitElement = getElementAtPosition(
        touchPoint.x,
        touchPoint.y,
      );
      if (appStateRef.current.activeTool.type === "selection") {

        if (hitElement) {
          setCursor(canvasRef.current, CURSOR_TYPE.MOVE);
        } else {
          setCursor(canvasRef.current, CURSOR_TYPE.AUTO);
        }
        if (canDragRef.current) {
          canDragRef.current = false
          saveData();

          if (memmberInfo.hostClientId != "") {
            if (currDrawingElement) {

              const syncElement: ImagoElement[] = [currDrawingElement]
              syncPersonalBoardElements(syncElement, memmberInfo, width, heiht)
            }
          }
        }


      } else if (appStateRef.current.activeTool.type === "freedraw") {
        isDrawing = false
        resetPath();
        if (canvasRef.current) {
          if (currDrawingElement) {
            personElemetsRef.current?.push(currDrawingElement)
            saveData();
            if (memmberInfo.hostClientId != "") {
              const syncElement: ImagoElement[] = [currDrawingElement]
              console.log(width)
              console.log(heiht)
              syncPersonalBoardElements(syncElement, memmberInfo, width, heiht)
            }
          }
          currDrawingElement = null
        }
      }




    }

    const drawFree = (x: number, y: number) => {
      if (!isDrawing) return;
      if (currDrawingElement?.type === "freedraw") {
        if (canvasRef.current && ctx) {
          ctx.strokeStyle = appStateRef.current.currentItemStrokeColor;
          ctx.lineWidth = appStateRef.current.currentItemStrokeWidth;
          ctx.lineCap = "round";
          ctx.lineTo(x, y);
          ctx.stroke();
          resetPath();
          ctx.moveTo(x, y);
        }

        if (currDrawingElement.points.length > 0) {

          const len = currDrawingElement.points.length;
          const lastPoint = currDrawingElement?.points[len - 1];
          const dis = distance2d(x, y, lastPoint[0] + currDrawingElement.x, lastPoint[1] + currDrawingElement.y)
          if (dis >= LINE_CONFIRM_THRESHOLD) {
            mutateElement(currDrawingElement, {
              points: [...currDrawingElement.points, [x - currDrawingElement.x, y - currDrawingElement.y]],
            });
          }
        }
      }




    }

    const handleWinMouseDown = (e: any) => {
      setIsDragging(true);
      const { clientX, clientY } = e;

      if (boardRef.current) {
        const { left, top } = boardRef.current.getBoundingClientRect();

        setPosition({
          x: clientX - left,
          y: clientY - top,
        });
      }

    };


    const handleWinMouseMove = (e: any) => {
      if (!isDragging) return;
      const { clientX, clientY } = e;
      const newLeft = clientX - position.x;
      const newTop = clientY - position.y;
      if (boardRef.current) {
        boardRef.current.style.transform = `translate(${newLeft}px, ${newTop}px)`;
      }

    };

    const handleWinMouseUp = () => {
      setIsDragging(false);
    };

    useEffect(() => {
      appStateRef.current = appState
      setCursorForShape(canvasRef.current, appStateRef.current);
    }, [appState]);

    useEffect(() => {

      const t = setInterval(async () => {

        if (appState.currCollabHost && appState.userInfo) {
          await fetch(`${API_URL.host}${API_URL.savePersonDataPrefix}`, {
            method: "POST",
            body: JSON.stringify({
              roomId: appState.currCollabHost.roomId,
              elements: personElemetsRef.current,
            }),
            headers: {
              Authorization: `Bearer ${appState.userInfo.authorization}`,
            },
          });
        }



      }, 5 * 1000);

      initCanvas()

    }, []);

    const closeWin = (e: any) => {
      e.stopPropagation();
      setAppState({
        openPersonalBoard: false
      });
    };


    const resizeCanvas = () => {
      if (canvasRef.current) {
        canvasRef.current.width = canvasRef.current.offsetWidth
        canvasRef.current.height = canvasRef.current.offsetHeight
        renderData()
      }
    }

    const getOffSetPoint = (clientX: number, clientY: number) => {
      if (canvasRef.current) {
        const { left, top } = canvasRef.current.getBoundingClientRect();
        return {
          x: clientX - left,
          y: clientY - top
        }
      }
      return {
        x: clientX,
        y: clientY
      }

    }

    const initCanvas = async () => {
      if (canvasRef.current) {
        canvasRef.current.width = canvasRef.current.offsetWidth
        canvasRef.current.height = canvasRef.current.offsetHeight
        ctx = canvasRef.current.getContext("2d");
        // personElemetsRef.current = await getPersonalDataFromLocalStorage()

        personElemetsRef.current = await LocalData.personalBoardStorage.get(memmberInfo.memberId)
        renderData()
        window.addEventListener("resize", resizeCanvas);
        canvasRef.current.addEventListener("mousedown", handleCanvasDown);
        canvasRef.current.addEventListener("mousemove", handleCanvasMove);
        canvasRef.current.addEventListener("mouseup", handleCanvasUp);
        canvasRef.current.addEventListener("mouseleave", handleCanvasUp);


        canvasRef.current.addEventListener("touchstart", handleCanvasDown);
        canvasRef.current.addEventListener("touchmove", handleCanvasMove);
        canvasRef.current.addEventListener("touchend", handleCanvasUp);


        width = canvasRef.current?.width;
        heiht = canvasRef.current.height;

        return () => {
          if (canvasRef.current) {
            window.removeEventListener("resize", resizeCanvas);
            canvasRef.current.removeEventListener("mousedown", handleCanvasDown);
            canvasRef.current.removeEventListener("mousemove", handleCanvasMove);
            canvasRef.current.removeEventListener("mouseup", handleCanvasUp);
            canvasRef.current.removeEventListener("mouseleave", handleCanvasUp);

            canvasRef.current.removeEventListener("touchstart", handleCanvasDown);
            canvasRef.current.removeEventListener("touchmove", handleCanvasMove);
            canvasRef.current.removeEventListener("touchend", handleCanvasUp);

          }

        };

      }

    }

    return (
      <div
        className="personal-board"
        ref={boardRef}
        onTouchMove={(event) => {
          handleWinMouseMove(event.touches[0])
        }}
        onMouseMove={handleWinMouseMove}
        onTouchEnd={handleWinMouseUp}
        onMouseUp={handleWinMouseUp}
        onMouseLeave={handleWinMouseUp}
      >
        <div className="header" onTouchStart={(event) => {
          handleWinMouseDown(event.touches[0])
        }} onMouseDown={handleWinMouseDown}>
          <div className="title">
            {t(`toolBar.personalBoard`)}
          </div>
          <div className="close">
            <a href={undefined} onClick={(e) => closeWin(e)}>
              {CloseIcon}
            </a>
          </div>
        </div>
        <div
          className="content"
        >
          <canvas style={{ width: "100%", height: "100%" }} ref={canvasRef} id="drawingCanvas"></canvas>

        </div>

      </div>
    );
  };
