import React, { useEffect, useState } from "react";
import { serializeLibraryAsJSON } from "../data/json";
import { ImagoElement, NonDeleted } from "../element/types";
import { t } from "../i18n";
import {
  AppState,
  Device,
  ImagoProps,
  LibraryItem,
  LibraryItems,
} from "../types";
import { arrayToMap, chunk } from "../utils";
import { LibraryUnit } from "./LibraryUnit";
import Stack from "./Stack";

import "./LibraryMenuItems.scss";
import { LIBRARY_LIST_LIMIT, MIME_TYPES } from "../constants";
import Spinner from "./Spinner";
import LibraryMenuBrowseButton from "./LibraryMenuBrowseButton";
import clsx from "clsx";
import { atom } from "jotai";
import { jotaiStore } from "../jotai";
import { Pagination } from "antd";
import { LocalData } from "../imago-app/data/LocalData";

export const libraryDragTransforDataAtom = atom<string | null>(null);

let CELLS_PER_ROW = 7;

const LibraryMenuItems = ({
  isLoading,
  libraryItems,
  onAddToLibrary,
  onInsertLibraryItems,
  pendingElements,
  selectedItems,
  onSelectItems,
  theme,
  id,
  libraryReturnUrl,
  appState,
  setAppState,
  device,
  onItemTouchEnd,
  onPageChange,
}: {
  isLoading: boolean;
  libraryItems: LibraryItems;
  pendingElements: LibraryItem["elements"];
  onInsertLibraryItems: (libraryItems: LibraryItems) => void;
  onAddToLibrary: (elements: LibraryItem["elements"]) => void;
  selectedItems: LibraryItem["id"][];
  onSelectItems: (id: LibraryItem["id"][]) => void;
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  theme: AppState["theme"];
  id: string;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  device: Device;
  onItemTouchEnd?: (id: string, event: React.TouchEvent) => void;
  onPageChange: (page: number, pageSize: number) => void;
}) => {
  const [lastSelectedItem, setLastSelectedItem] = useState<
    LibraryItem["id"] | null
  >(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    LocalData.librariesStorage.total().then((data) => {
      setTotal(data);
    });
  }, [libraryItems]);

  // useEffect(()=>{
  //   handlePageChange(1);
  // },[1,total])

  const handlePageChange = (page: number) => {
    onPageChange(page, LIBRARY_LIST_LIMIT);
    setCurrentPage(page);
  };
  const onItemSelectToggle = (
    id: LibraryItem["id"],
    event: React.MouseEvent,
  ) => {
    const shouldSelect = !selectedItems.includes(id);

    const orderedItems = [...unpublishedItems, ...publishedItems];

    if (shouldSelect) {
      if (event.shiftKey && lastSelectedItem) {
        const rangeStart = orderedItems.findIndex(
          (item) => item.id === lastSelectedItem,
        );
        const rangeEnd = orderedItems.findIndex((item) => item.id === id);

        if (rangeStart === -1 || rangeEnd === -1) {
          onSelectItems([...selectedItems, id]);
          return;
        }

        const selectedItemsMap = arrayToMap(selectedItems);
        const nextSelectedIds = orderedItems.reduce(
          (acc: LibraryItem["id"][], item, idx) => {
            if (
              (idx >= rangeStart && idx <= rangeEnd) ||
              selectedItemsMap.has(item.id)
            ) {
              acc.push(item.id);
            }
            return acc;
          },
          [],
        );

        onSelectItems(nextSelectedIds);
      } else {
        onSelectItems([...selectedItems, id]);
      }
      setLastSelectedItem(id);
    } else {
      setLastSelectedItem(null);
      onSelectItems(selectedItems.filter((_id) => _id !== id));
    }
  };

  const getInsertedElements = (id: string) => {
    let targetElements;
    if (selectedItems.includes(id)) {
      targetElements = libraryItems.filter((item) =>
        selectedItems.includes(item.id),
      );
    } else {
      targetElements = libraryItems.filter((item) => item.id === id);
    }
    return targetElements;
  };

  const createLibraryItemCompo = (params: {
    item:
      | LibraryItem
      | /* pending library item */ {
          id: null;
          elements: readonly NonDeleted<ImagoElement>[];
        }
      | null;
    onClick?: () => void;
    key: string;
    onTouchEnd?: (id: string, event: React.TouchEvent) => void;
  }) => {
    return (
      <Stack.Col key={params.key} style={{ flexFlow: "1" }}>
        <LibraryUnit
          elements={params.item?.elements}
          isPending={!params.item?.id && !!params.item?.elements}
          onClick={params.onClick || (() => {})}
          id={params.item?.id || null}
          selected={!!params.item?.id && selectedItems.includes(params.item.id)}
          onToggle={onItemSelectToggle}
          onDrag={(id, event) => {
            event.dataTransfer.setData(
              MIME_TYPES.imagolib,
              serializeLibraryAsJSON(getInsertedElements(id)),
            );
          }}
          onTouchStart={(id, event) => {
            jotaiStore.set(
              libraryDragTransforDataAtom,
              serializeLibraryAsJSON(getInsertedElements(id)),
            );
          }}
          onTouchEnd={params.onTouchEnd}
        />
      </Stack.Col>
    );
  };

  const renderLibrarySection = (
    items: (
      | LibraryItem
      | /* pending library item */ {
          id: null;
          elements: readonly NonDeleted<ImagoElement>[];
        }
    )[],
    device: Device,
    onTouchEnd?: (id: string, event: React.TouchEvent) => void,
  ) => {
    const _items = items.map((item) => {
      if (item.id) {
        return createLibraryItemCompo({
          item,
          onClick: () => onInsertLibraryItems(getInsertedElements(item.id)),
          key: item.id,
          onTouchEnd,
        });
      }
      return createLibraryItemCompo({
        key: "__pending__item__",
        item,
        onClick: () => onAddToLibrary(pendingElements),
      });
    });

     if (device.isMobile) CELLS_PER_ROW = 4

    // ensure we render all empty cells if no items are present
    let rows = chunk(_items, CELLS_PER_ROW);
    if (!rows.length) {
      rows = [[]];
    }

    return rows.map((rowItems, index, rows) => {
      if (index === rows.length - 1) {
        // pad row with empty cells
        rowItems = rowItems.concat(
          new Array(CELLS_PER_ROW - rowItems.length)
            .fill(null)
            .map((_, index) => {
              return createLibraryItemCompo({
                key: `empty_${index}`,
                item: null,
              });
            }),
        );
      }
      return (
        <Stack.Row
          align="center"
          key={index}
          className={clsx("library-menu-items-container__row",{"library-menu-items-container__row-mobile":device.isMobile})}
        >
          {rowItems}
        </Stack.Row>
      );
    });
  };

  const unpublishedItems = libraryItems.filter(
    (item) => item.status !== "published",
  );
  const publishedItems = libraryItems.filter(
    (item) => item.status === "published",
  );

  const showBtn =
    !libraryItems.length &&
    !unpublishedItems.length &&
    !publishedItems.length &&
    !pendingElements.length;
  return (
    <div
      className="library-menu-items-container"
      style={{ justifyContent: "flex-start" }}
    >
      <Stack.Col
        className="library-menu-items-container__items"
        align="start"
        gap={1}
        style={{
          flex: 1,
          marginBottom: 0,
        }}
      >
        <>
          {!pendingElements.length &&
          !unpublishedItems.length &&
          !publishedItems.length ? (
            <div className="library-menu-items__no-items">
              <div className="library-menu-items__no-items__hint">
                {t("library.noItems")}
              </div>
              <div
                className="library-menu-items__no-items__hint"
                style={{ marginTop: "1rem" }}
              >
                <p>{t("library.hint_emptyLibrary_title")}</p>
                <p style={{ paddingLeft: "2rem" }}>
                  {t("library.hint_emptyLibrary_content_1")}
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  {t("library.hint_emptyLibrary_content_2")}
                </p>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <React.Fragment>
                {(pendingElements.length > 0 || unpublishedItems.length > 0) &&
                  renderLibrarySection(
                    [
                      // append pending library item
                      ...(pendingElements.length
                        ? [{ id: null, elements: pendingElements }]
                        : []),
                      ...unpublishedItems,
                    ],
                    device,
                    onItemTouchEnd,
                  )}
              </React.Fragment>
              <React.Fragment>
                {publishedItems.length > 0 &&
                  renderLibrarySection(publishedItems, device, onItemTouchEnd)}
              </React.Fragment>
            </React.Fragment>
          )}
        </>
      </Stack.Col>
      <Stack.Col align="end" style={{ paddingTop: "5px" }}>
        <Pagination
          current={currentPage}
          total={total}
          onChange={handlePageChange}
          showSizeChanger={false}
          pageSize={LIBRARY_LIST_LIMIT}
          hideOnSinglePage={true}
        />
      </Stack.Col>
    </div>
  );
};

export default LibraryMenuItems;
