import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { canvasToBlob } from "../data/blob";
import { isInitializedImageElement } from "../element/typeChecks";
import { FileId, ImagoElement, NonDeletedImagoElement } from "../element/types";
import { personalBoardUpdateFlagAtom } from "../imago-app/collab/Collab";
import { LocalData } from "../imago-app/data/LocalData";
import { exportToCanvas, exportToCanvasPage, exportToPersonalCanvas } from "../scene/export";
import { AppState, BinaryFileData, BinaryFiles } from "../types";
import "./CollaboratorPersonBoard.scss";


export const CollaboratorPersonBoard = ({
  memberId,
  appState

}: {
  appState: AppState;
  memberId: string;
}) => {

  const previewRef = useRef<HTMLDivElement>(null);
  const { exportBackground, viewBackgroundColor } = appState;

  const [personalBoardUpdateFlag] = useAtom(personalBoardUpdateFlagAtom);

  const renderData = async () => {
    const exportedElements = await LocalData.personalBoardStorage.get(memberId)

    let files = {};
    const getFileData = async () => {

      exportToPersonalCanvas(exportedElements, appState, files, {
        exportBackground,
        viewBackgroundColor,
        exportPadding: 10,
      })
        .then((canvas) => {
          const previewNode = previewRef.current;
          if (!previewNode) {
            return;
          }
          return canvasToBlob(canvas).then(() => {
            if (!previewNode.replaceChildren) {
              previewNode.innerHTML = "";
              previewNode.appendChild(canvas);
            } else {
              previewNode.replaceChildren(canvas);
            }
          });
        })
        .catch((error) => {
          // console.error(error);
        });



    };

    getFileData()

  }

  useEffect(() => {
    if (personalBoardUpdateFlag.startsWith(memberId)) {
      renderData()
    }

  }, [
    personalBoardUpdateFlag,
  ]);

  useEffect(() => {
    renderData()
  }, [
    appState,
    memberId,
    exportBackground,
    viewBackgroundColor,
  ]);

  return (
    <div className="board_preview" ref={previewRef}>
    </div>
  );
};

