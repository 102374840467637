import { ToolButton } from "../components/ToolButton";
import { AiIcon, MagicPenIcon, MagicTextIcon, TextIcon } from "../components/icons";
import { getNonDeletedElements } from "../element";
import { getCommonBoundingBox } from "../element/bounds";
import { ImagoFreeDrawElement, NonDeletedImagoElement } from "../element/types";
import { KEYS } from "../keys";
import { randomInteger } from "../random";
import { getSelectedElements, isSomeElementSelected } from "../scene";
import { isSomeFreeDrawElementSelected } from "../scene/selection";
import { viewportCoordsToSceneCoords } from "../utils";
import { register } from "./register";
import { AppState } from "../types";
import { Handwriting } from "../imago-app/Handwriting";


export const magicDraw = async ({ handwriting, elements, appState, setAppState, app, handelAutoImagicDrawInsertImage, handelAutoTextDrawInsertText }: { handwriting: Handwriting, elements: NonDeletedImagoElement[], appState: AppState, setAppState: React.Component<any, AppState>["setState"], app: String, handelAutoImagicDrawInsertImage?: (url: string, elements: NonDeletedImagoElement[]) => void, handelAutoTextDrawInsertText?: (url: string, elements: NonDeletedImagoElement[]) => void }) => {


  const selectedElements = elements.filter(e => e.type === "freedraw" || e.type === "magicpen" || e.type === "magictext");



  const box = getCommonBoundingBox(selectedElements);
  // const elements = selectedElements.filter(e=>e.type=="freedraw");//.map(e=>(e as ImagoFreeDrawElement).points);
  let pressedAt = 0;
  const shapes = selectedElements.map((s, n) => {
    appState.selectedElementIds[s.id] = true
    const ele = (s as ImagoFreeDrawElement);
    const { x, y } = viewportCoordsToSceneCoords({ clientX: ele.x, clientY: ele.y }, appState);
    var xPoints: number[] = [];
    var yPoints: number[] = [];
    var times: number[] = [];
    ele.points.map((p, i) => {

      // const {x:px,y:py} = viewportCoordsToSceneCoords({clientX:p[0],clientY:p[1]},appState);

      xPoints.push(x + p[0]);
      yPoints.push(y + p[1]);
      pressedAt += Math.ceil(Math.random() * 30);
      times.push(pressedAt)
    })
    return [xPoints, yPoints, times];
  }
  );


  if (app == "draw") {

    const suggestions = await handwriting.drawSuggestions(shapes, {
      canvasWidth: appState.width,
      canvasHeight: appState.height
    })

    if (suggestions) {
      if (suggestions.length > 0) {



        setAppState({ openPopup: "handwritingSuggestion", suggestions: { type: "draw", data: suggestions } })

        // for (let i = 0; i < suggestions.length; i++) {
        //   if (suggestions[i].icons) {
        //     const icons = suggestions[i].icons;
        //     if (icons) {
        //       for (let j = 0; j < icons.length; j++) {
        //         const url = icons[j];
        //         handelAutoImagicDrawInsertImage && handelAutoImagicDrawInsertImage(url, selectedElements)
        //         return
        //       }
        //     }

        //   }

        // }

      }
    }




  } else {
    const suggestions = await handwriting.writSuggestions(shapes, appState.defaultHandWriteLang, {
      canvasWidth: appState.width,
      canvasHeight: appState.height
    })

    if (suggestions) {
      if (suggestions.length > 0) {
        handelAutoTextDrawInsertText && handelAutoTextDrawInsertText(suggestions[0], selectedElements)
      }
    }

  }

}


export const actionHandwriting = register({
  name: "handwriting",
  trackEvent: { category: "element" },
  perform: (elements, appState, formValue) => {


    return {
      appState: {
        ...appState,
        suggestions: formValue.suggestions,
        openPopup: "handwritingSuggestion"
      },
      elements,
      commitToHistory: true,
    };


  },
  // contextItemLabel: "labels.autoDraw",
  // keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.Y,
  PanelComponent: ({ elements, appState, updateData, data }) => {
    const onItemClick = async (app: string) => {

      const selectedElements = getSelectedElements(
        getNonDeletedElements(elements),
        appState,
      ).filter(e => e.type === "freedraw" || e.type === "magicpen" || e.type === "magictext");
      const box = getCommonBoundingBox(selectedElements);
      // const elements = selectedElements.filter(e=>e.type=="freedraw");//.map(e=>(e as ImagoFreeDrawElement).points);
      let pressedAt = 0;
      const shapes = selectedElements.map((s, n) => {

        const ele = (s as ImagoFreeDrawElement);
        const { x, y } = viewportCoordsToSceneCoords({ clientX: ele.x, clientY: ele.y }, appState);

        var xPoints: number[] = [];
        var yPoints: number[] = [];
        var times: number[] = [];
        ele.points.map((p, i) => {

          // const {x:px,y:py} = viewportCoordsToSceneCoords({clientX:p[0],clientY:p[1]},appState);

          xPoints.push(x + p[0]);
          yPoints.push(y + p[1]);
          pressedAt += Math.ceil(Math.random() * 30);
          times.push(pressedAt)
        })
        return [xPoints, yPoints, times];
      }
      );


      if (app == "draw") {
        const suggestions = await data?.handwriting.drawSuggestions(shapes, {
          canvasWidth: appState.width,
          canvasHeight: appState.height
        })
        updateData({ suggestions: { type: "draw", data: suggestions } });
      } else {
        const suggestions = await data?.handwriting.writSuggestions(shapes, appState.defaultHandWriteLang, {
          canvasWidth: appState.width,
          canvasHeight: appState.height
        })

        // appState.isShowSetHandWriteLangPanel = true
        await data?.handelAutoTextDrawInsertText(suggestions[0], selectedElements)

        // updateData({ suggestions: { type: "write", data: suggestions } });
      }

    }
    return <>
      <ToolButton
        type="button"
        icon={MagicPenIcon}
        hidden={false}
        onClick={async () => {
          await onItemClick("draw");
        }}
        title={`Auto Draw`}
        aria-label={`Auto Draw`}
      />
      <ToolButton
        type="button"
        icon={MagicTextIcon}
        hidden={false}
        onClick={async () => {
          await onItemClick("write");
        }}
        title={`Handwriting`}
        aria-label={`Handwriting`}
      />
    </>
  }

  ,
});