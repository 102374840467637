import React from "react";
import { AppState, Device, ImagoProps } from "../types";
import { ActionManager } from "../actions/manager";
import { t } from "../i18n";
import Stack from "./Stack";
import { showSelectedShapeActions } from "../element";
import { NonDeletedImagoElement } from "../element/types";
import { FixedSideContainer } from "./FixedSideContainer";
import { Island } from "./Island";
import { HintViewer } from "./HintViewer";
import { calculateScrollCenter } from "../scene";
import { SelectedShapeActions, ShapesSwitcher, ZoomActions } from "./Actions";
import { Section } from "./Section";
import CollabButton from "./CollabButton";
import GoogleDriveButton from "./GoogleDriveButton";
import { SCROLLBAR_WIDTH, SCROLLBAR_MARGIN } from "../scene/scrollbars";
import { LockButton } from "./LockButton";
import { UserList } from "./UserList";
import { LibraryButton } from "./LibraryButton";
import { PenModeButton } from "./PenModeButton";
import { Stats } from "./Stats";
import { actionToggleStats } from "../actions";
import { Separator } from "./MenuUtils";
import WelcomeScreen from "./WelcomeScreen";
import MenuItem from "./MenuItem";
import {
  ExportImageIcon,
  CollabLogo,
  CanvasBackgroundIcon,
  QrScanIcon,
  createStringIcon,
  LoadIcon,
} from "./icons";
import {
  BottomMoreActions,
  BottomColorPickerAction,
  GroupShapesSwitcher,
  BottomExtraActions,
} from "./Actions";

import { SHAPES } from "../shapes";
import { ToolFlashCollab } from "./ToolButton";
import { Toolbar } from "./Toolbar";
import { loadFromJSONPage } from "../data/json";
import { getShortcutFromShortcutName } from "../actions/shortcuts";
import { Profile } from "./Profile";
import { Pagination } from "./Pagination";
import { Zoom } from "./Zoom";
import { WebEmbeds } from "./WebEmbed";
import { PageManager } from "../imago-app/data/PageManager";
import { AppFooter } from "./AppFooter";
import { ToolbarApply } from "./ToolbarApply";

type MobileMenuProps = {
  appState: AppState;
  actionManager: ActionManager;
  toolBarMode: number;
  renderJSONExportDialog: () => React.ReactNode;
  renderImageExportDialog: () => React.ReactNode;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedImagoElement[];
  onCollabButtonClick?: () => void;
  onGoogleDriveClick?: () => void;
  onLockToggle: () => void;
  onPenModeToggle: () => void;
  canvas: HTMLCanvasElement | null;
  isCollaborating: boolean;
  renderCustomFooter?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  onImageAction: (data: {
    insertOnCanvasDirectly: boolean;
    callback: () => void;
  }) => void;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  renderCustomStats?: ImagoProps["renderCustomStats"];
  renderSidebars: () => JSX.Element | null;
  device: Device;
  renderWelcomeScreen?: boolean;
  activeRoomId?: string;
  onFlashCollabClick?: () => void;
  isFlashCollabing?: boolean;
  toolbarSetting: any[];
  loadLocalLibToCanvas?: (type: any) => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  onLogoutDelete: () => void;
  onLogoutSave: () => void;
  addFileToCanvas: (file: File) => Promise<void>;
  onViewBackgroundColorChange?: (color: string) => void
};

export const MobileMenu = ({
  appState,
  elements,
  actionManager,
  toolBarMode,
  renderJSONExportDialog,
  renderImageExportDialog,
  setAppState,
  onCollabButtonClick,
  onGoogleDriveClick,
  onLockToggle,
  onPenModeToggle,
  canvas,
  isCollaborating,
  renderCustomFooter,
  onImageAction,
  renderTopRightUI,
  renderCustomStats,
  renderSidebars,
  device,
  renderWelcomeScreen,
  activeRoomId,
  onFlashCollabClick,
  isFlashCollabing,
  toolbarSetting,
  loadLocalLibToCanvas,
  operaPage,
  onLogoutDelete,
  onLogoutSave,
  addFileToCanvas,
  onViewBackgroundColorChange
}: MobileMenuProps) => {
  renderTopRightUI = (isMobile: boolean, appState: AppState) => {
    return (
      <div className="top-bar-logo">
        <div id="brushIcon" className="brush-icon">
          <img src="/paint-brush-big.svg" width={40} height={40} />
        </div>
        <div id="rubber" className="brush-icon">
          <img src="/eraser-big.svg" width={130} height={130} />
        </div>
      </div>
    );
  };

  const renderToolbar = () => {
    return (
      <FixedSideContainer side="top" className="App-top-bar">
        {renderWelcomeScreen && !appState.isLoading && (
          <WelcomeScreen
            appState={appState}
            operaPage={operaPage}
            setAppState={setAppState}
            actionManager={actionManager}
          />
        )}
        {/* <div className="top-bar-profile">
          <Profile
            onDelete={onLogoutDelete}
            onSave={onLogoutSave}
            appState={appState}
            setAppState={setAppState}
            isMobile={true}
            operaPage={operaPage}
            actionManager={actionManager}
          />
        </div> */}
        <Section heading="shapes" className="top-bar-action">
          {(heading: React.ReactNode) => (
            <Stack.Row style={{ zIndex: 1000 }}>
              <Stack.Col gap={4} align="center">
                <Island>
                  <Stack.Row
                    align="start"
                    className="left-section"
                    style={{ gridTemplateColumns: "50px,50px" }}
                  >

                    <Stack.Col>
                      <WebEmbeds
                        showLeftDivider={false}
                        appState={appState}
                        setAppState={setAppState}
                      />
                    </Stack.Col>
                    <Stack.Col>
                      <Pagination
                        appState={appState}
                        actionManager={actionManager}
                        setAppState={setAppState}
                      />
                    </Stack.Col>
                    <div className="App-toolbar__divider"></div>
                    <Stack.Col>
                      <ZoomActions
                        renderAction={actionManager.renderAction}
                        zoom={appState.zoom}
                      />
                    </Stack.Col>

                  </Stack.Row>
                </Island>
                <Stack.Row gap={1} className="App-toolbar-container">
                  <Island
                    padding={1}
                    className="App-toolbar App-toolbar--mobile"
                  >
                    {heading}
                    <Stack.Row gap={1} className="mobile-toolbar">
                      {/* <PenModeButton
                      checked={appState.penMode}
                      onChange={onPenModeToggle}
                      title={t("toolBar.penMode")}
                      isMobile
                      penDetected={appState.penDetected}
                    />
                    <LockButton
                      checked={appState.activeTool.locked}
                      onChange={onLockToggle}
                      title={t("toolBar.lock")}
                      isMobile
                    />
                    <div className="App-toolbar__divider"></div> */}
                      {/* <ShapesSwitcher
                      appState={appState}
                      canvas={canvas}
                      activeTool={appState.activeTool}
                      activeRoomId={activeRoomId}
                      isFlashCollabing={isFlashCollabing}
                      onFlashCollabClick={onFlashCollabClick}
                      setAppState={setAppState}
                      onImageAction={({ pointerType }) => {
                        onImageAction({
                          insertOnCanvasDirectly: pointerType !== "mouse",
                        });
                      }}
                    /> */}


                      {toolBarMode === 1 ? (<Toolbar
                        actionManager={actionManager}
                        appState={appState}
                        setAppState={setAppState}
                        toolbarSetting={toolbarSetting}
                        canvas={canvas}
                        isCollaborating={isCollaborating}
                        isFlashCollabing={isFlashCollabing}
                        activeRoomId={activeRoomId}
                        onImageAction={onImageAction}
                        onCollabButtonClick={onCollabButtonClick}
                        onFlashCollabClick={onFlashCollabClick}
                        loadLocalLibToCanvas={loadLocalLibToCanvas}
                        isMobile={true}
                        addFileToCanvas={addFileToCanvas}
                      />) : (<ToolbarApply
                        actionManager={actionManager}
                        appState={appState}
                        setAppState={setAppState}
                        toolbarSetting={toolbarSetting}
                        canvas={canvas}
                        isCollaborating={isCollaborating}
                        isFlashCollabing={isFlashCollabing}
                        activeRoomId={activeRoomId}
                        onImageAction={onImageAction}
                        onCollabButtonClick={onCollabButtonClick}
                        onFlashCollabClick={onFlashCollabClick}
                        loadLocalLibToCanvas={loadLocalLibToCanvas}
                        isMobile={true}
                        addFileToCanvas={addFileToCanvas}
                      />)}


                    </Stack.Row>
                  </Island>
                  {renderTopRightUI && renderTopRightUI(true, appState)}
                  {/* <div className="mobile-misc-tools-container">
                  <PenModeButton
                    checked={appState.penMode}
                    onChange={onPenModeToggle}
                    title={t("toolBar.penMode")}
                    isMobile
                    penDetected={appState.penDetected}
                  // penDetected={true}
                  />

                </div> */}
                </Stack.Row>

              </Stack.Col>
            </Stack.Row>
          )}
        </Section>

        {/* <HintViewer
          appState={appState}
          elements={elements}
          isMobile={true}
          device={device}
        /> */}
      </FixedSideContainer>
    );
  };

  const renderAppToolbar = () => {
    if (appState.viewModeEnabled) {
      return (
        <div className="App-toolbar-content">
          {actionManager.renderAction("toggleCanvasMenu")}
        </div>
      );
    }

    return (
      <div className="App-toolbar-content">
        {actionManager.renderAction("toggleCanvasMenu")}
        {actionManager.renderAction("toggleEditMenu")}
        {actionManager.renderAction("undo")}
        {actionManager.renderAction("redo")}
        {actionManager.renderAction(
          appState.multiElement ? "finalize" : "duplicateSelection",
        )}
        {actionManager.renderAction("deleteSelectedElements")}
      </div>
    );
  };

  const renderCanvasActions = () => {
    if (appState.viewModeEnabled) {
      return (
        <>
          {renderJSONExportDialog()}
          <MenuItem
            label={t("buttons.exportImage")}
            icon={ExportImageIcon}
            dataTestId="image-export-button"
            onClick={() => setAppState({ openDialog: "imageExport" })}
          />
          {renderImageExportDialog()}
        </>
      );
    }
    return (
      <>
        {!appState.viewModeEnabled && (
          <MenuItem
            label={t("buttons.load")}
            icon={LoadIcon}
            onClick={async () => {
              await loadFromJSONPage(appState, operaPage);
            }}
            dataTestId="load-button"
            shortcut={getShortcutFromShortcutName("loadScene")}
          />
        )}
        <MenuItem
          label={t("buttons.scan")}
          icon={QrScanIcon}
          dataTestId="image-export-button"
          onClick={() => {
            if (window.ReactNativeWebView) {
              setAppState({ openMenu: null });
              const message = JSON.stringify({
                options: { event: "scan_qr_code" },
              });
              window.ReactNativeWebView.postMessage(message);
            }
          }}
        />
        {renderJSONExportDialog()}
        {renderImageExportDialog()}
        <MenuItem
          label={t("buttons.exportImage")}
          icon={ExportImageIcon}
          dataTestId="image-export-button"
          onClick={() => setAppState({ openDialog: "imageExport" })}
        />
        {onCollabButtonClick && (
          <CollabButton
            isCollaborating={isCollaborating}
            collaboratorCount={appState.collaborators.size}
            onClick={onCollabButtonClick}
            appState={appState}
            setAppState={setAppState}
          />
        )}
        {onGoogleDriveClick && (
          <GoogleDriveButton
            onClick={onGoogleDriveClick}
            appState={appState}
            setAppState={setAppState}
          />
        )}
        {!appState.viewModeEnabled && actionManager.renderAction("clearCanvas")}
        <Separator />
        {!appState.viewModeEnabled && (
          <MenuItem
            label={t("labels.canvasBackground")}
            icon={CanvasBackgroundIcon}
            dataTestId=""
            onClick={() => setAppState({})}
          />
        )}
        {!appState.viewModeEnabled && (
          <div style={{ marginBottom: ".5rem" }}>
            <div style={{ padding: "0 0.625rem" }}>
              {actionManager.renderAction("changeViewBackgroundColor")}
            </div>
          </div>
        )}
        {actionManager.renderAction("toggleTheme")}
      </>
    );
  };
  return (
    <>
      {renderSidebars()}
      {!appState.viewModeEnabled && renderToolbar()}
      {!appState.openMenu && appState.showStats && (
        <Stats
          appState={appState}
          setAppState={setAppState}
          elements={elements}
          onClose={() => {
            actionManager.executeAction(actionToggleStats);
          }}
          renderCustomStats={renderCustomStats}
        />
      )}
      <div
        className="App-bottom-bar"
        style={{
          //marginBottom: SCROLLBAR_WIDTH + SCROLLBAR_MARGIN * 2,
        }}
      >
        <Island padding={0}>
          {appState.openMenu === "canvas" ? (
            <Section className="App-mobile-menu" heading="canvasActions">
              <div className="panelColumn">
                <Stack.Col gap={2}>
                  {renderCanvasActions()}
                  {renderCustomFooter?.(true, appState)}
                  {appState.collaborators.size > 0 && (
                    <fieldset>
                      <legend>{t("labels.collaborators")}</legend>
                      <UserList
                        mobile
                        collaborators={appState.collaborators}
                        actionManager={actionManager}
                      />
                    </fieldset>
                  )}
                </Stack.Col>
              </div>
            </Section>
          ) : appState.openMenu === "shape" &&
            !appState.viewModeEnabled &&
            showSelectedShapeActions(appState, elements) ? (
            <Section className="App-mobile-menu" heading="selectedShapeActions">
              <SelectedShapeActions
                appState={appState}
                elements={elements}
                renderAction={actionManager.renderAction}
              />
            </Section>
          ) : null}
          <footer className="App-toolbar">
            <AppFooter
              appState={appState}
              setAppState={setAppState}
              isCollaborating={isCollaborating}
              actionManager={actionManager}
              onLogoutDelete={onLogoutDelete}
              onLogoutSave={onLogoutSave}
              operaPage={operaPage}
              onViewBackgroundColorChange={onViewBackgroundColorChange}
            />
            {/* {renderAppToolbar()} */}
            {/* {appState.scrolledOutside &&
              !appState.openMenu &&
              appState.openSidebar !== "library" && (
                <button
                  className="scroll-back-to-content"
                  onClick={() => {
                    setAppState({
                      ...calculateScrollCenter(elements, appState, canvas),
                    });
                  }}
                >
                  {t("buttons.scrollBackToContent")}
                </button>
              )} */}
          </footer>
        </Island>
      </div>
    </>
  );
};
