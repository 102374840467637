import React, { useState } from "react";
import { transformElements } from "../element";
import { getSelectedElements } from "../scene";
import { AppState, PointerDownState } from "../types";
import Scene from "../scene/Scene";
import { NonDeletedImagoElement } from "../element/types";
import { SetMultipleElementsSize } from "../element/resizeElements";
import { Button, Flex, InputNumber } from "antd";
import { getCommonBoundingBox } from "../element/bounds";


export const SetSize : React.FC<{appState:AppState
    ,elements:readonly NonDeletedImagoElement[]
}>=({appState,
    elements
})=>{
    const [height,setHeight] = useState(300);

    const selectedElements = getSelectedElements(elements,appState);
    const bounds = getCommonBoundingBox(selectedElements);
    const hanleOk = ()=>{
 
        SetMultipleElementsSize(height,
            selectedElements)
    }
    return <Flex gap={10} align={"center"}>
        Height:
        <InputNumber min={1} max={20000} defaultValue={Math.floor(bounds.height) } onChange={(v)=>{setHeight(v!)}} controls={false} style={{ width: '100%' }} 
         onKeyDown={(e)=>{
            if(e.key == "Enter"){
                hanleOk();
            }
            
        }}/>
        <Button type="primary" onClick={hanleOk}>Set</Button>
    </Flex>
}