import "./Participants.scss";
import { Dialog } from "./Dialog";
import { AppState, Collaborator } from "../types";
import { Modal } from "./Modal";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { Avatar, Card, Input, List } from "antd";
import { IconApplyAuth, IconApplyDrawFlag, IconBatchAccept, IconBatchReject, IconDash, IconDial, IconGrid, IconHost, IconList, IconNoDraw, IconNoneColorPlus, IconPlus, IconRemoveMember, IconSearch01, IconTrash, IconYesDraw } from "./newIcons";
import { CloseIcon, FlashCollabIcon, IconCloseWhite, ProfileIcon } from "./icons";
import { useDevice } from "./App";
import { ActionManager } from "../actions/manager";
import { Tooltip } from "./Tooltip";
import React, { useEffect, useState } from "react";
import { GetLoginedUser } from "../utils";
import { CheckCollabMemberInfo, personalBoardUpdateFlagAtom, viewIngCollaborMemberAtom } from "../imago-app/collab/Collab";
import { getCurrBoardMode, getPersonalDataFromLocalStorage, isCollabtionHost } from "../imago-app/data/localStorage";
import clsx from "clsx";
import { BOARD_MODE } from "../constants";
import { t } from "../i18n";
import { CollaboratorPersonBoard } from "./CollaboratorPersonBoard";
import { jotaiStore } from "../jotai";


export const Participants: React.FC<{
  appState: AppState, setAppState: React.Component<any, AppState>["setState"]; isFlashCollabing: boolean;
  onCallClick: () => void;
  showBatchCheckWin: (authType: string) => void;
  showCheckPermissionWin: (authType: string, checkMemberInfo: CheckCollabMemberInfo) => void;
  cancelMemberPermission: (authType: string, flag: number, checkMemberInfo: CheckCollabMemberInfo) => void;
  actionManager: ActionManager;
  mobile: boolean;
}> = ({
  appState,
  setAppState,
  isFlashCollabing,
  onCallClick,
  showBatchCheckWin,
  showCheckPermissionWin,
  cancelMemberPermission,
  actionManager,
  mobile
}) => {

    const device = useDevice();
    const currBoardMode = getCurrBoardMode()
    const user = GetLoginedUser();
    const isHostOper = isCollabtionHost();
    const [showGridView, setShowGridView] = useState<boolean>(false);
    const [userList, setUserList] = useState<JSX.Element[]>([]);
    const [userListGridView, setUserListGridView] = useState<JSX.Element[]>([]);


    const showUserList = async (keyword: string | null) => {
      if (appState.collabMembers.size > 0) {
        const list: JSX.Element[] = []
        const gridViewList: JSX.Element[] = []



        appState.collabMembers.forEach(async (collabMember, clientId) => {

          const avatarJSX = actionManager.renderAction("goToCollaborator", [
            clientId,
            collabMember,
          ]);

          const checkCollabMemberInfo = {
            memberId: collabMember.userId,
            memberName: collabMember.userName,
            memberClientId: clientId,
            roomId: collabMember.roomId
          } as CheckCollabMemberInfo

          let needAdd = false
          if (keyword && keyword.trim() != "") {
            if (collabMember.userName?.includes(keyword)) {
              needAdd = true
            }
          } else {
            needAdd = true
          }

          if (needAdd) {

            const item1 = <div
              className={clsx("member-list-item", { "un-checked-member": currBoardMode != BOARD_MODE.warRoom && collabMember.status == 0 && !collabMember.isHost })}
              onClick={() => {
                if (isHostOper && currBoardMode != BOARD_MODE.warRoom && !collabMember.isHost && collabMember.status == 0) {
                  showCheckPermissionWin("status", checkCollabMemberInfo)
                }

              }} key={clientId}> {avatarJSX}<div className="user-name">{collabMember.userName}</div>
              <div className="actions">
                {collabMember.isHost ? (<>{IconHost}</>) :
                  (
                    <>
                      {currBoardMode !== BOARD_MODE.warRoom &&
                        (
                          <>{!collabMember.isHost && collabMember.drawAuthFlag != 1 ? (<div
                            className={clsx("btn", { "btn-host": isHostOper })}
                            onClick={() => {
                              if (isHostOper && !collabMember.isHost && collabMember.drawAuthFlag !== 1) {
                                showCheckPermissionWin("draw", checkCollabMemberInfo)
                              }
                            }}>
                            {collabMember.drawAuthFlag === 0 ? IconApplyDrawFlag : IconNoDraw}</div>) :
                            (<>{(isHostOper && !collabMember.isHost) ?
                              (<div style={{ cursor: "pointer" }} onClick={() => {
                                if (confirm(`Are you sure you want to cancel ${collabMember.userName}'s drawing permission`)) {
                                  cancelMemberPermission("draw", 2, checkCollabMemberInfo)
                                }
                              }}>{IconYesDraw}</div>) :
                              (<div>{IconYesDraw}</div>)}
                            </>)}
                          </>
                        )
                      }
                      {(isHostOper && !collabMember.isHost) &&
                        (<div style={{ cursor: "pointer" }} onClick={() => {
                          if (confirm(`Are you sure you want to remove ${collabMember.userName} from the meeting`)) {
                            cancelMemberPermission("status", 2, checkCollabMemberInfo)
                          }
                        }} className="remove-member">{IconRemoveMember}</div>)
                      }

                    </>
                  )}

              </div>
            </div>

            if (collabMember.isHost) {
              list.unshift(item1)
            } else {
              list.push(item1)
            }


            if (collabMember && collabMember.userId && !collabMember.isHost) {

              gridViewList.push(<div className="member-grid-item"><div onClick={() => {
                setAppState({ openCollaborPersonalBoard: true })
                console.log(checkCollabMemberInfo)
                jotaiStore.set(viewIngCollaborMemberAtom, checkCollabMemberInfo);
              }} className="item-canvas">
                <CollaboratorPersonBoard
                  memberId={collabMember.userId}
                  appState={appState}
                />
              </div><div className="item-user-name"><div>{collabMember.userName}</div> </div></div>)
            }
          }

        });
        setUserList(list)
        setUserListGridView(gridViewList)

      }
    }


    useEffect(() => {
      showUserList(null)

      // initCanvas()   

    }, [appState.collabMembers]);


    useEffect(() => {
      if (appState.waitCheckMemberCount > 0) {
        setShowGridView(false)
      }



    }, [appState.waitCheckMemberCount]);




    return <Popover onCloseRequest={() => { }} left={10} top={200}>
      <div className="participants">
        <div className="participants-header">
          <h4>Participants</h4>
          <a href={undefined} onClick={() => { setAppState({ openDialog: null }) }}>{CloseIcon}</a>
        </div>
        <div className="participants-content">
          <div className="search">
            {IconSearch01}
            <Input
              onChange={(event) => {
                showUserList(event.target.value)
              }}
              placeholder={t("labels.select_board_mode_win.search_participants")}
            />
          </div>
          <div className="list-title">
            <div className="title-count">{
              showGridView ? (<>Personal board list({appState.collabMembers.size - 1})</>) : (<>In this meeting({appState.collabMembers.size})</>)
            }</div>
            {(isHostOper && currBoardMode == BOARD_MODE.classRoom) && (<div className="icons">
              {/* <div className="batch-group">
                <div className="allow">{IconBatchAccept}</div>
                <div className="reject">{IconRemoveMember}</div>
              </div> */}
              <div onClick={() => setShowGridView(v => !v)} className="btn-toggle-view">
                {showGridView ? IconList : IconGrid}
              </div>

            </div>)}
          </div>
          {showGridView ? <div className="member-grid">{userListGridView}</div> : <div className="member-list">{userList}</div>}
        </div>

        <div className="participants-footer">

          {isHostOper && (<>
            {
              currBoardMode != BOARD_MODE.warRoom && (<>

                <div onClick={() => {
                  if (appState.waitCheckMemberStatusCount > 0) {
                    showBatchCheckWin("status")
                  }
                }} className={clsx("batch-btn footer-button", { "normal": appState.waitCheckMemberStatusCount > 0, "disable": appState.waitCheckMemberStatusCount == 0 },)}>
                  {appState.waitCheckMemberStatusCount > 0 && (<div className="small-bubble">{appState.waitCheckMemberStatusCount}</div>)}
                  <div>{IconHost}</div>
                  <div>Allow User</div>
                </div>
              </>)
            }

            <div onClick={() => {
              if (appState.waitCheckMemberDrawCount > 0) {
                showBatchCheckWin("draw")
              }
            }} className={clsx("batch-btn footer-button", { "normal": appState.waitCheckMemberDrawCount > 0, "disable": appState.waitCheckMemberDrawCount == 0 },)}>
              {appState.waitCheckMemberDrawCount > 0 && (<div className="small-bubble">{appState.waitCheckMemberDrawCount}</div>)}
              <div>{IconApplyDrawFlag}</div>
              <div>Allow Draw</div>
            </div>
          </>)}


          {isFlashCollabing ? <div className="footer-button video-call normal" style={{ background: "#CC3B33" }} onClick={onCallClick}>
            {IconDial}
            <span>End Call</span>
          </div> :
            <div className="footer-button video-call normal" style={{ background: "green" }} onClick={onCallClick}>
              {IconDial}
              <span>Start Call</span>
            </div>}

        </div>
      </div>
    </Popover>
  }