import "./UserModalApp.scss";
import React, { useState, useLayoutEffect, useRef } from "react";
import { Modal } from "./Modal";
import {
  clearAllStorageData,
  setItemWithExpiration,
} from "../imago-app/data/localStorage";
import { BlindIcon, CloseIcon, CrossIcon, EyeIcon, GoogleIcon } from "./icons";
import "./JoinCollabCheckComfirm.scss"
import { t } from "../i18n";
import { useDevice } from "./App";
import { CheckCollabMemberInfo } from "../imago-app/collab/Collab";

export const JoinCollabCheckComfirm: React.FC<{
  closeCheckJoinCollab: () => void;
  checkMemberPermission: (flag: number) => void;
  winTips: string;
  checkAuthType: string;
  isBatchCheck?: boolean;
  memberInfo?: CheckCollabMemberInfo | undefined;
}> = ({ checkMemberPermission, closeCheckJoinCollab, checkAuthType, memberInfo, isBatchCheck, winTips }) => {

  const device = useDevice();

  return (
    <>
      <Modal
        labelledBy=""
        maxWidth={device.isMobile ? 264 : 450}
        onCloseRequest={() => {
          closeCheckJoinCollab()
        }}
      >

        <div className="join-collab-check-confirm">
          <div className="close-win"><a href={undefined} onClick={() => { closeCheckJoinCollab() }}>{CloseIcon}</a></div>
          <div className="win-content">
            <div className="info">
              <div className="name">{checkAuthType == "status" ? (t("labels.request_join_confirm.batch_check_join_title")) : (t("labels.request_join_confirm.batch_check_apply_draw_title"))}</div>
              <div className="desc">{winTips}</div>
            </div>
            <div className="button-group">
              <div onClick={() => {
                checkMemberPermission(2)
              }} className="button button-reject">Reject</div>
              <div onClick={() => {
                checkMemberPermission(1)
              }} className="button button-allow">Allow</div>
            </div>
          </div>

        </div>

      </Modal>
    </>
  );
};
