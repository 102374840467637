import { useAtom } from "jotai";
import { actionLoadScene, actionShortcuts } from "../actions";
import { ActionManager } from "../actions/manager";
import { getShortcutFromShortcutName } from "../actions/shortcuts";
import { COOKIES } from "../constants";
import { collabDialogShownAtom } from "../imago-app/collab/Collab";
import { t } from "../i18n";
import { AppState } from "../types";
import {
  ExcalLogo,
  HelpIcon,
  LoadIcon,
  PlusPromoIcon,
  CollaborateIcon,
} from "./icons";
import "./WelcomeScreen.scss";
import { loadFromJSONPage } from "../data/json";

const isImagoPlusSignedUser = document.cookie.includes(
  COOKIES.AUTH_STATE_COOKIE,
);

const WelcomeScreenItem = ({
  label,
  shortcut,
  onClick,
  icon,
  link,
}: {
  label: string;
  shortcut: string | null;
  onClick?: () => void;
  icon: JSX.Element;
  link?: string;
}) => {
  if (link) {
    return (
      <a
        className="WelcomeScreen-item"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <div className="WelcomeScreen-item__label">
          {icon}
          {label}
        </div>
      </a>
    );
  }

  return (
    <button className="WelcomeScreen-item" type="button" onClick={onClick}>
      <div className="WelcomeScreen-item__label">
        {icon}
        {label}
      </div>
      {shortcut && (
        <div className="WelcomeScreen-item__shortcut">{shortcut}</div>
      )}
    </button>
  );
};

const WelcomeScreen = ({
  appState,
  actionManager,
  setAppState,
  operaPage
}: {
  appState: AppState;
  actionManager: ActionManager;
  setAppState: React.Component<any, AppState>["setState"];
  operaPage?: ({ page, actionName }: { actionName?: string, page: string }) => void

}) => {
  const [, setCollabDialogShown] = useAtom(collabDialogShownAtom);

  let subheadingJSX;

  if (isImagoPlusSignedUser) {
    subheadingJSX = t("welcomeScreen.switchToPlusApp")
      .split(/(Imago\+)/)
      .map((bit, idx) => {
        if (bit === "Imago+") {
          return (
            <a
              style={{ pointerEvents: "all" }}
              href={`${process.env.REACT_APP_PLUS_APP}?utm_source=imago&utm_medium=app&utm_content=welcomeScreenSignedInUser`}
              key={idx}
            >
              Imago+
            </a>
          );
        }
        return bit;
      });
  } else {
    subheadingJSX = t("welcomeScreen.data");
  }

  return (<></>
    // <div className="WelcomeScreen-container">
    //   <div className="WelcomeScreen-logo virgil WelcomeScreen-decor">
    //     {ExcalLogo}ollab
    //   </div>
    //   <div className="virgil WelcomeScreen-decor WelcomeScreen-decor--subheading">
    //     {subheadingJSX}
    //   </div>     
    // </div>
  );
};

export default WelcomeScreen;
