import React from "react";
import { Popover, Slider } from "antd";
import { getLanguage } from "../i18n";
import "./Picker.scss";
import clsx from "clsx";
import { FontSizeIcon } from "./icons";


 

export function RangePicker({
  value,
  label,
  min,
  max,
  step,
  onChange,
  name,
  icon
}: {
  label: string;
  value: number | null;
  min:number;
  max:number;
  step:number;
  onChange: (value: string) => void;
  name:string;
  icon:JSX.Element
}) {
  const [isActive, setActive] = React.useState(false);
  const rPickerButton = React.useRef<HTMLButtonElement>(null);
  const isRTL = getLanguage().rtl;
 
  const content = <div>
  <div className="vertical-range">                    
      <input
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={(event) => {onChange(event.target.value)}}
          value={value??1}
      />
      {/* <Slider vertical range step={step} defaultValue={[min, max]} onChange={(v)=>onChange(v)} /> */}
       
  </div>
</div>;

  return (
    <Popover content={content} trigger="click" placement="bottom" arrow={false} onOpenChange={setActive} getPopupContainer={(triggerNode)=>triggerNode}>
      <button
        name={name}
        className={isActive ? "active" : ""}
        aria-label={label}
        onClick={() => {setActive(!isActive)}}
        ref={rPickerButton}
      >
        {icon}
      </button>
      <React.Suspense fallback="">
        {/* {isActive ? (
          <>
            <Popover
              onCloseRequest={(event) =>
              {
                event.target !== rPickerButton.current && setActive(false)
              } 
              }
            >
              <div
                className={`picker`}
                role="dialog"
                aria-modal="true"
                aria-label={label}
              >
                <div className="vertical-range">                    
                    <input
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        onChange={(event) => {onChange(event.target.value)}}
                        value={value??1}
                    />
                     
                </div>
              </div>
            </Popover>
            <div className="picker-triangle" />
          </>
        ) : null} */}
      </React.Suspense>
    </Popover>
  );
}
